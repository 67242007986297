import { $t } from '@/config/i18n'
import { useAuthUserStore } from '@/stores/auth-user'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { captureMessage } from '@sentry/vue'
import date from 'date-and-time'

class DateTime {
  /**
   *
   * @param {string|Date} entry
   * @returns {string} Format will be yyyy-mm-dd
   */
  getDate(entry) {
    if (!entry || entry == 'Invalid Date') {
      return (
        new Date(Date.now()).getFullYear() +
        '-' +
        (new Date(Date.now()).getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        new Date(Date.now()).getDate().toString().padStart(2, '0')
      )
    }
    return (
      new Date(Date.parse(entry)).getFullYear() +
      '-' +
      (new Date(Date.parse(entry)).getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      new Date(Date.parse(entry)).getDate().toString().padStart(2, '0')
    )
  }

  /**
   *
   * @param {string|Date} entry
   * @param {number} addHours
   * @param {number} addMinutes
   * @returns {string} Format will be yyyy-mm-dd
   */
  getTime(entry, addHours = 0, addMinutes = 0, showSeconds = false) {
    if (!entry || entry == 'Invalid Date') {
      return (
        date.addHours(new Date(Date.now()), addHours).getHours().toString().padStart(2, '0') +
        ':' +
        date.addMinutes(new Date(Date.now()), addMinutes).getMinutes().toString().padStart(2, '0')
      )
    }
    return (
      new Date(Date.parse(entry)).getHours().toString().padStart(2, '0') +
      ':' +
      new Date(Date.parse(entry)).getMinutes().toString().padStart(2, '0') +
      (new Date(Date.parse(entry)).getSeconds() != 0 || showSeconds
        ? ':' + new Date(Date.parse(entry)).getSeconds().toString().padStart(2, '0')
        : '')
    )
  }

  today() {
    let time = new Date(Date.now())
    return new Date(time.getFullYear(), time.getMonth(), time.getDate(), 0, 0, 0, 0)
  }

  removeMillisecondsFromISO(date) {
    let time = null
    if (typeof date == 'string') {
      time = new Date(Date.parse(date))
    } else if (date instanceof Date) {
      time = date
    } else {
      throw new Error('The date variable need to be a valid Datetime string or a date object.')
    }

    return new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    ).toISOString()
  }

  /**
   * Will return the date and time with the new given date. The time will not change
   * @param {string|Date} entry
   * @param {string} value
   * @returns
   */
  setDate(entry, value) {
    const data = value.split('-')
    let year = data[0]
    let month = data[1] - 1
    let day = data[2]
    let hour = null
    let minute = null
    let seconds = null
    if (entry === null || entry === 'Invalid Date') {
      hour = new Date(Date.now()).getHours()
      minute = new Date(Date.now()).getMinutes()
      seconds = new Date(Date.now()).getSeconds()
    } else {
      hour = new Date(Date.parse(entry)).getHours().toString()
      minute = new Date(Date.parse(entry)).getMinutes().toString()
      seconds = new Date(Date.parse(entry)).getSeconds().toString()
    }
    return new Date(year, month, day, hour, minute, seconds).toISOString()
  }

  /**
   * Will return the date and time with the new given time. The date will not change
   * @param {string|Date} entry
   * @param {string} value
   * @returns
   */
  setTime(entry, value) {
    let year = null
    let month = null
    let day = null

    if (!entry || entry == 'Invalid Date') {
      year = new Date(Date.now()).getFullYear().toString().padStart(4, '0')
      month = new Date(Date.now()).getMonth().toString().padStart(2, '0')
      day = new Date(Date.now()).getDate().toString().padStart(2, '0')
    } else {
      year = new Date(entry).getFullYear().toString().padStart(4, '0')
      month = new Date(entry).getMonth().toString().padStart(2, '0')
      day = new Date(entry).getDate().toString().padStart(2, '0')
    }
    // console.log(value)
    if (typeof value != 'string' && value) {
      value = value.toString()
    }

    let data = null
    if (value == null) {
      value = '00:00:00'
      return
    }
    if (value.match(/[:.,]/)) {
      data = value.split(/[:.,]/)
    } else {
      switch (value.length) {
        case 1:
          data = [value]
          break
        case 2:
          if (value.match(/([0-1][0-9]|2[0-3])/)) {
            data = [value]
          } else {
            data = [value.substring(0, 1), value.substring(1, 2)]
          }
          break
        case 3:
          // if(value.match(/([0-9][0-9][0-9])/)) {
          data = [value.substring(0, 1), value.substring(1, 3)]
          // }
          break
        case 4:
          data = [value.substring(0, 2), value.substring(2, 4)]
          break
        case 5:
          data = [value.substring(0, 2), value.substring(2, 4), value.substring(4, 6)]
          break
        case 6:
          data = [value.substring(0, 2), value.substring(2, 4), value.substring(4, 6)]
          break
        default:
          data = [value.substring(0, 2), value.substring(2, 4), value.substring(4, 6)]
          break
      }
    }

    // console.log(data)

    let hours = data[0] ?? '0'
    let minutes = data[1] ?? '0'
    let seconds = data[2] ?? '0'

    if (hours == '') {
      hours = '0'
    }
    hours = hours.substring(0, 2)
    if (!isNaN(parseInt(hours))) {
      hours = parseInt(hours)
      hours = hours < 0 ? hours * -1 : hours
      hours = hours > 23 || hours < 0 ? hours % 24 : hours
    }

    if (minutes == '') {
      minutes = '0'
    }
    minutes = minutes.substring(0, 2)

    if (!isNaN(parseInt(minutes))) {
      minutes = parseInt(minutes)
      minutes = minutes < 0 ? minutes * -1 : minutes

      minutes = minutes > 59 || minutes < 0 ? minutes % 60 : minutes
    }

    if (seconds == '') {
      seconds = '0'
    }
    seconds = seconds.substring(0, 2)
    if (!isNaN(parseInt(seconds))) {
      seconds = parseInt(seconds)
      seconds = seconds < 0 ? seconds * -1 : seconds
      seconds = seconds > 59 || seconds < 0 ? seconds % 60 : seconds
    }

    return new Date(year, month, day, hours, minutes, seconds).toISOString()
  }

  validateTimeInput(value) {
    if (!value) {
      return true
    }
    return value.match(
      /^(([0-9]|[0-1][0-9]|2[0-3])((:[0-9]|:[0-5][0-9]|[0-5][0-9]|[0-9])((:[0-9]|:[0-5][0-9]|[0-5][0-9]|[0-9])){0,1}){0,1})$/
    )
    // if(value.length < 3) {
    //   return value.match(/^([0-9]|[0-1][0-9]|2[0-3])$/)
    // }
    // else if(value.length <= 4) {
    //   return value.match(/^([0-9](:[0-5][0-9]|[0-5][0-9])|([0-1][0-9]|2[0-3])(:[0-9]|[0-5][0-9]))$/)
    // }
  }

  /**
   *
   * @param {Array} timeEntries
   * @param {Number|string|Date} start
   * @param {Number|string|Date} end
   * @returns
   */
  getInterval(timeEntries = [], start, end) {
    start = this.iso(start)
    end = this.iso(end)
    return timeEntries.filter(
      (item) =>
        (item.started_at &&
          item.ended_at &&
          Date.parse(start) < Date.parse(item.started_at) &&
          Date.parse(end) > Date.parse(item.started_at)) ||
        (Date.parse(start) < Date.parse(item.ended_at) &&
          Date.parse(end) > Date.parse(item.ended_at)) ||
        (Date.parse(start) > Date.parse(item.started_at) &&
          Date.parse(end) < Date.parse(item.ended_at))
    )
  }

  calcDay(day, givenTimeEntries = [], activeTimeEntry = null) {
    let tStart = day
    tStart = new Date(day.getFullYear(), day.getMonth(), day.getDate())
    let tEnd = new Date(tStart.getFullYear(), tStart.getMonth(), tStart.getDate() + 1, 0, 0, 0, 0)
    let timeEntries = this.getInterval(givenTimeEntries, tStart, tEnd)
    if (activeTimeEntry) {
      let active = useTimeEntryStore().getActiveTimeEntry
      if (active && active?.started_at) {
        if (
          (Date.parse(tStart) < Date.parse(active.started_at) &&
            Date.parse(tEnd) > Date.parse(active.started_at)) ||
          (Date.parse(tStart) < Date.now() && Date.parse(tEnd) > Date.now()) ||
          (Date.parse(tStart) > Date.parse(active.started_at) && Date.parse(tEnd) < Date.now())
        ) {
          timeEntries.push(active)
        }
      }
    }
    let timer = 0
    timeEntries.forEach((item) => {
      if (item == null) {
        captureMessage('Null object found. ' + tStart + ' ' + tEnd)
        return
      }
      let start = Date.parse(item.started_at)
      let end = item.ended_at ? Date.parse(item.ended_at) : Date.now()
      if (start < Date.parse(tStart)) {
        start = Date.parse(tStart)
      }
      if (end > Date.parse(tEnd)) {
        end = Date.parse(tEnd)
      }
      timer = timer + (end - start)
    })
    if (timer == 0) {
      return '00h 00m'
    }
    return this.convertDateNumberToString(timer, { seconds: false })
  }

  calcAll(timeEntries = []) {
    let timer = 0
    timeEntries.forEach((item) => {
      if (item == null) {
        return
      }
      let start = Date.parse(item.started_at)
      let end = item.ended_at ? Date.parse(item.ended_at) : Date.now()
      timer = timer + (end - start)
    })

    if (timer == 0) {
      return '00h 00m'
    }
    return this.convertDateNumberToString(timer, { seconds: true, days: true })
  }

  calcWeek(day, givenTimeEntries = [], activeTimeEntry = null) {
    let tStart = new Date(day.getFullYear(), day.getMonth(), day.getDate())
    tStart = date.addDays(tStart, 1 - tStart.getDay())
    let tEnd = date.addDays(tStart, 6)
    tEnd = new Date(tEnd.getFullYear(), tEnd.getMonth(), tEnd.getDate(), 23, 59, 59, 999)
    let timeEntries = this.getInterval(givenTimeEntries, tStart, tEnd)

    if (activeTimeEntry) {
      let active = useTimeEntryStore().getActiveTimeEntry
      if (active && active?.started_at) {
        if (
          (Date.parse(tStart) < Date.parse(active.started_at) &&
            Date.parse(tEnd) > Date.parse(active.started_at)) ||
          (Date.parse(tStart) < Date.now() && Date.parse(tEnd) > Date.now()) ||
          (Date.parse(tStart) > Date.parse(active.started_at) && Date.parse(tEnd) < Date.now())
        ) {
          timeEntries.push(active)
        }
      }
    }

    let timer = 0
    timeEntries.forEach((item) => {
      if (item == null) {
        captureMessage('Null object found. ' + tStart + ' ' + tEnd)
        return
      }
      let start = Date.parse(item.started_at)
      let end = item.ended_at ? Date.parse(item.ended_at) : Date.now()

      if (start < Date.parse(tStart)) {
        start = Date.parse(tStart)
      }
      if (end > Date.parse(tEnd)) {
        end = Date.parse(tEnd)
      }
      timer = timer + (end - start)
    })
    if (timer == 0) {
      return '00h 00m'
    }
    let minutes = Math.floor((timer / (60 * 1000)) % 60)
      .toString()
      .padStart(2, '0')
    let hours = Math.floor(timer / (60 * 60 * 1000))
      .toString()
      .padStart(2, '0')
    return `${hours}h ${minutes}m`
  }

  calcInterval(startDate, type = 'week') {
    let start = startDate
    let end =
      type == 'day'
        ? date.addMilliseconds(date.addDays(start, 1), -1)
        : date.addMilliseconds(date.addDays(start, 7), -1)

    let diff = type == 'day' ? 1 : 7
    let previous_start = date.addDays(start, -diff)
    let previous_end = date.addDays(end, -diff)

    let follow_start = date.addDays(start, diff)
    let follow_end = date.addDays(end, diff)
    return {
      start,
      end,
      previous_start,
      previous_end,
      follow_start,
      follow_end
    }
  }

  showDiff(start, end) {
    let diff = Date.parse(end) - Date.parse(start)

    return this.convertDateNumberToString(diff)
  }

  readableAbsoluteDiff(start, end) {
    let diffNumber = null
    if (typeof start == 'number' && typeof end == 'number') {
      diffNumber = end - start
    } else {
      diffNumber = Date.parse(end) - Date.parse(start)
    }
    let diff = this.convertDateNumberToObject(diffNumber)

    let strings = []
    if (diff.days != 0) {
      strings.push(diff.days + ' ' + $t('datetime.readable.days', diff.days))
    }
    if (diff.hours != 0) {
      strings.push(diff.hours + ' ' + $t('datetime.readable.hours', diff.hours))
    }
    if (diff.minutes != 0) {
      strings.push(diff.minutes + ' ' + $t('datetime.readable.minutes', diff.minutes))
    }
    if (diff.seconds != 0) {
      strings.push(diff.seconds + ' ' + $t('datetime.readable.seconds', diff.seconds))
    }

    let last_part = []
    last_part.unshift(strings.pop())
    last_part.unshift(strings.pop())

    return [
      strings.join(', '),
      last_part
        .filter((item) => (item ? true : false))
        .join(' ' + $t('datetime.readable.join_and') + ' ')
    ]
      .filter((item) => (item ? true : false))
      .join(', ')
  }

  convertDateNumberToObject(dateNumber) {
    let obj = {
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0
    }
    if (dateNumber == null) {
      return obj
    }
    obj.seconds = Math.floor((dateNumber / 1000) % 60)
    obj.minutes = Math.floor((dateNumber / (60 * 1000)) % 60)
    obj.hours = Math.floor((dateNumber / (60 * 60 * 1000)) % 24)
    obj.days = Math.floor((dateNumber / (24 * 60 * 60 * 1000)) % 365)
    return obj
  }

  convertDateNumberToString(
    dateNumber,
    without = { seconds: false, days: false },
    trimSeconds = true
  ) {
    if (without.seconds == undefined) {
      without.seconds = false
    }
    if (without.days == undefined) {
      without.days = false
    }
    let seconds = Math.floor((dateNumber / 1000) % 60)
      .toString()
      .padStart(2, '0')
    let minutes = Math.floor((dateNumber / (60 * 1000)) % 60)
      .toString()
      .padStart(2, '0')
    let hours = without.days
      ? Math.floor(dateNumber / (60 * 60 * 1000))
          .toString()
          .padStart(2, '0')
      : Math.floor((dateNumber / (60 * 60 * 1000)) % 24)
          .toString()
          .padStart(2, '0')
    let days = without.days
      ? '00'
      : Math.floor(dateNumber / (24 * 60 * 60 * 1000))
          .toString()
          .padStart(2, '0')
    let output = ''
    if (days != '00') {
      output = `${days}d `
    }
    if (days != '00' || hours != '00') {
      output = output + `${hours}h `
    }
    output = output + `${minutes}m`
    if (!without.seconds) {
      if ((seconds != '00' && trimSeconds) || (seconds == '00' && !trimSeconds)) {
        output = output + (trimSeconds ? ` ${seconds}s` : '')
      }
    }
    return output
  }

  /**
   *
   * @param {string|number|Date} input
   */
  date(input) {
    let date = null
    if (typeof input == 'string') {
      date = new Date(Date.parse(input))
    } else if (typeof input == 'number') {
      date = new Date(input)
    } else if (input instanceof Date) {
      date = input
    }
    return date
  }

  /**
   *
   * @param {string|number|Date} input
   */
  iso(input) {
    return this.date(input).toISOString()
  }

  getStartOfWeek(input, startIsMonday = true) {
    let date = this.date(input)
    return new Date(date.setDate(date.getDate() - date.getDay() + (startIsMonday ? 1 : 0)))
  }

  getWeekDays(startIsMonday = true) {
    let startOfWeek = this.getStartOfWeek(Date.now(), startIsMonday)
    let weekDays = []
    const lang = useAuthUserStore().user.language ?? 'de'
    for (let i = 0; i < 7; i++) {
      weekDays.push(startOfWeek.toLocaleString(lang, { weekday: 'long' }))
      startOfWeek = new Date(startOfWeek.setDate(startOfWeek.getDate() + 1))
    }
    return weekDays
  }
}

export default new DateTime()
