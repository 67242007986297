<template>
  <!-- Calendar -->
  <div class="w-full site-background">
    <loading-spinner-vue v-model="isLoading" :fullPage="true"></loading-spinner-vue>
    <div class="flex flex-col px-6 pb-16" @keypress="zoomKey">
      <!-- <div class="grid grid-cols-3 mb-4 items-stretch flex-wrap"> -->
      <div class="flex justify-between mb-4 items-center flex-wrap">
        <div class="flex gap-3 items-center">
          <div class="isolate inline-flex">
            <BaseButton
              type="button"
              class="button-white relative !rounded-r-none !pl-3 !pr-2"
              @click="$refs.vuecal.previous()"
            >
              <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" class="text-lg" />
            </BaseButton>
            <BaseButton
              class="button-white !rounded-r-none !rounded-l-none -ml-px"
              @click="$refs.vuecal.switchView(activeView, new Date())"
            >
              {{ $t('today') }}
            </BaseButton>
            <BaseButton
              type="button"
              class="button-white relative -ml-px !rounded-l-none !pl-2 !pr-3"
              @click="$refs.vuecal.next()"
            >
              <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" class="text-lg" />
            </BaseButton>
          </div>
        </div>

        <div class="text-3xl text-center">
          <!-- <div class="text-3xl text-mossgray text-center w-full flex justify-center"> -->
          <span v-if="activeView === 'month'">{{ selectedDate.format('MMMM YYYY') }}</span>
          <div class="flex items-center" v-else-if="activeView === 'week'">
            <b>
              {{
                selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                  month: 'long',
                  year: 'numeric'
                })
              }}</b
            >
            <span class="bg-mossgray text-white rounded-l-full pl-4 pr-3 py-1 text-base ml-3">
              {{ $t('calendar.short.calender_week') }} {{ selectedDate.getWeek() }}
            </span>
            <span
              class="bg-lime text-grotesk rounded-r-full pl-3 pr-4 py-1 text-base font-normal flex items-center"
            >
              <span>{{ calcWeek(selectedDate) }}</span>
            </span>
          </div>
          <span class="flex items-center" v-else-if="activeView === 'day'"
            ><b>
              {{
                selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                  weekday: 'short'
                })
              }},
              {{
                selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })
              }}</b
            >
            <span class="bg-mossgray text-white rounded-l-full pl-4 pr-3 py-1 text-base ml-3">
              {{ $t('calendar.short.calender_week') }} {{ selectedDate.getWeek() }}
            </span>
            <span
              class="bg-lime text-grotesk rounded-r-full pl-3 pr-4 py-1 text-base font-normal flex items-center"
            >
              <span>{{ calcDay(selectedDate) }}</span>
            </span>
          </span>
          <div class="text-lg font-semibold">
            <!-- <span v-if="activeView == 'week'"> {{ calcWeek(selectedDate) }} </span> -->
            <!-- <span v-if="activeView == 'day'"> {{ calcDay(selectedDate) }} </span> -->
          </div>
        </div>

        <div class="flex gap-3 items-center">
          <!-- <div class="flex gap-3 items-center justify-end"> -->
          <div v-if="timeEntryStore.conflicts.length != 0">
            <button
              class="button text-white bg-red-600 border border-red-300 rounded-full"
              @click="showConflictModal = true"
              v-tippy="$t('tracking.button.conflict.description')"
            >
              <font-awesome-icon :icon="['fa-kit', 'tl-calendar-error']" size="lg" />
            </button>
          </div>

          <div class="isolate inline-flex">
            <BaseButton
              type="button"
              class="button-white relative !rounded-r-none !pl-4 !pr-3"
              @click="zoomIn()"
              v-tippy="$t('tracking.zoom_in')"
            >
              <font-awesome-icon :icon="['fa-kit', 'tl-zoom-in']" class="text-lg" />
            </BaseButton>
            <BaseButton
              type="button"
              class="button-white relative -ml-px !rounded-l-none font-bold !pl-3 !pr-4"
              @click="zoomOut()"
              v-tippy="$t('tracking.zoom_out')"
            >
              <font-awesome-icon :icon="['fa-kit', 'tl-zoom-out']" class="text-lg" />
            </BaseButton>
          </div>

          <div class="isolate inline-flex">
            <BaseButton
              class="button-white !rounded-r-none !pr-3.5 relative font-semibold disabled:!bg-mossgray disabled:text-white"
              :disabled="activeView == 'day'"
              v-tippy="$t('tracking.open_day_view')"
              @click="activeView = 'day'"
            >
              <font-awesome-icon :icon="['fa-kit', 'tl-calendar-daily']" class="text-base" />
            </BaseButton>
            <BaseButton
              class="button-white !rounded-l-none relative -ml-px !pl-3.5 font-semibold disabled:!bg-mossgray disabled:text-white"
              :disabled="activeView == 'week'"
              v-tippy="$t('tracking.open_week_view')"
              @click="activeView = 'week'"
            >
              <font-awesome-icon :icon="['fa-kit', 'tl-calendar-weekly']" class="text-lg" />
            </BaseButton>
          </div>
        </div>
      </div>

      <div class="grow overflow-y-auto">
        <vue-cal
          class="vuecal--blue-theme bg-white"
          ref="vuecal"
          v-model:active-view="activeView"
          v-model:selected-date="selectedDate"
          :events="events"
          :time-from="
            ((authUserStore.user?.settings?.showTimeInterval == undefined ||
            authUserStore.user?.settings?.showTimeInterval == null
              ? null
              : authUserStore.user?.settings?.showTimeInterval) ?? [6, 20])[0] * 60
          "
          :time-to="
            ((authUserStore.user?.settings?.showTimeInterval == undefined ||
            authUserStore.user?.settings?.showTimeInterval == null
              ? null
              : authUserStore.user?.settings?.showTimeInterval) ?? [6, 20])[1] * 60
          "
          :disable-views="['years', 'year', 'month']"
          :time-step="60"
          :time-cell-height="cellHeight"
          watchRealTime
          hide-title-bar
          hide-view-selector
          :locale="authUserStore?.user?.language ?? 'de'"
          :hide-weekdays="authUserStore.user?.settings?.hideWeekDays ?? []"
          @view-change="getEvents"
          @event-change="changeEvent"
          :dblclickToNavigate="false"
          :clickToNavigate="false"
          :onEventDblclick="dbClick"
          :cellContextmenu="true"
          @cell-dblclick="dbClickCell"
          @event-resizing="eventResize"
          @event-drag="dragStarted"
          :editable-events="{
            title: false,
            drag: true,
            resize: true,
            delete: false,
            create: false
          }"
        >
          <template v-slot:weekday-heading="{ heading }">
            <div class="font-grotesk">
              <span v-if="heading.date" class="font-grotesk text-3xl block font-bold">{{
                heading.date.format('D')
              }}</span>
              <span>{{ heading.label.toUpperCase() }}</span>
              <div class="font-grotesk text-sm">{{ calcDay(heading.date) }}</div>
            </div>
          </template>
          <template v-slot:no-event="{}">
            <span class="">{{ $t('no_entries') }}</span>
          </template>

          <template v-slot:event="{ event, view }">
            <!-- <v-icon>{{ event.icon }}</v-icon> -->
            <div
              @drag="dragStarted"
              class="flex flex-row h-full group relative"
              :class="{
                grow: view == 'week',
                'entry-sync-error':
                  event.timeEntry?.push_state >= 5 ||
                  (event.timeEntry?.tl?.error_code != undefined &&
                    event.timeEntry?.tl?.error_code != null)
              }"
              @keyup="keypressOnEvent"
              @contextmenu="
                (e) => {
                  contextMenu(event, e)
                }
              "
            >
              <div
                class="min-w-4 max-w-4 grow-0"
                :style="{ backgroundColor: event.color ?? '#333333a6' }"
              ></div>
              <div class="grow px-2 py-1 text-left mask-text">
                <div
                  class="vuecal__event-title flex justify-between"
                  style="font-size: 13px; line-height: 1.3"
                  v-show="!isResizing"
                >
                  <div class="flex flex-col font-semibold">
                    <!-- Project: -->
                    <span v-if="event.timeEntry?.project_id">
                      {{ projectsStore.getId(event.timeEntry?.project_id)?.name }}
                    </span>

                    <!-- Client: -->
                    <span v-if="event.timeEntry?.client_id">
                      {{ clientsStore.getId(event.timeEntry?.client_id)?.name }}
                    </span>
                    <span v-else>{{ $t('tracking.no_client_selected') }}</span>
                  </div>

                  <div class="ml-auto">
                    <div
                      v-if="event.isActive"
                      class="text-red-500 pt-1.5 overflow-visible cursor-pointer"
                      @click="editActiveTimeEntryEvent"
                    >
                      <font-awesome-icon
                        :icon="['fad', 'stopwatch']"
                        size="xl"
                        fade
                        style="--fa-animation-duration: 3s; --fa-fade-opacity: 0.3"
                      />
                    </div>
                    <span
                      class="text-sm font-bold text-red-500 overflow-visible"
                      v-if="
                        timelinkService.isTempId(event.id) &&
                        this.timeEntryModalModel?.id !== event.id
                      "
                    >
                      <!-- {{ $t('tracking.warning') }}! -->
                      <font-awesome-icon
                        :icon="['fad', 'exclamation']"
                        class="text-red-500"
                        v-tippy="$t('errors.no_update_or_create_due_to_connection')"
                      />
                    </span>
                    <font-awesome-icon
                      :icon="['fad', 'cloud-arrow-up']"
                      v-tippy="$t('tracking.entry_will_be_transferred')"
                      v-show="
                        !timelinkService.isTempId(event.id) &&
                        event.timeEntry?.tl.isDirty &&
                        event.timeEntry?.tl?.isUpdating &&
                        (event.timeEntry?.tl?.error_code == undefined ||
                          event.timeEntry?.tl?.error_code == null)
                      "
                    />
                    <font-awesome-icon
                      :icon="['fad', 'circle-exclamation']"
                      class="text-red-700"
                      v-tippy="$t('errors.no_provider_sync')"
                      v-show="
                        !timelinkService.isTempId(event.id) &&
                        !event.timeEntry?.tl.isDirty &&
                        event.timeEntry?.push_state >= 5
                      "
                    />
                    <font-awesome-icon
                      :icon="['fad', 'signal-slash']"
                      v-tippy="$t('errors.no_update_retry')"
                      class="text-red-700"
                      v-show="event.timeEntry?.tl?.error_code == 1"
                    />
                  </div>
                </div>
                <span class="vuecal__event-time" style="font-size: 13px; line-height: 1.3">
                  <!-- Using Vue Cal injected Date prototypes -->
                  <div class="flex flex-row justify-between">
                    <span
                      >{{ datetime.getTime(event.start, 0, 0, true) }} -
                      <span v-if="!event.isActive">{{
                        datetime.getTime(event.end, 0, 0, true)
                      }}</span
                      ><span v-else>{{ $t('calendar.now') }}</span></span
                    >
                    <span v-if="!event.isActive" class="font-bold">{{
                      this.showDiff(event.timeEntry.started_at, event.timeEntry.ended_at)
                    }}</span>
                  </div>

                  <div
                    v-if="event.timeEntry?.service_id"
                    v-show="!isResizing"
                    class="flex flex-col"
                  >
                    <!-- Service: -->
                    <span>{{ servicesStore.getId(event.timeEntry?.service_id)?.name }}</span>
                  </div>

                  <!-- Description: -->
                  <div
                    v-if="event.timeEntry?.description"
                    v-show="!isResizing"
                    class="w-full py-1 border-t border-mossgray-200 mt-1 pt-1"
                  >
                    <span v-text="event.timeEntry?.description"></span>
                  </div>
                </span>
              </div>
            </div>
          </template>
        </vue-cal>
      </div>
      <!-- ENDE VUECAL -->

      <TimeEntryList :timeEntries="selectedTimeEntries"> </TimeEntryList>
    </div>

    <div
      class="!bg-white absolute z-30 border border-mossgray-200 shadow-sm rounded-md shadow-mossgray-400"
      v-show="activeContextMenu"
      id="contextMenu"
      ref="contextMenuDiv"
    >
      <div class="flex flex-col divide-y text-left justify-start items-start w-56">
        <button
          class="py-2 px-4 hover:bg-gray-200 basis-full w-full text-left hover:shadow-inner hover:font-semibold"
          type="button"
          @click="editEvent"
        >
          <font-awesome-icon :icon="['fa-kit', 'tl-calendar-edit']" class="pr-2" />
          <span v-if="timeEntryStore.activeTimeEntry != this.selectedContextEvent?.id">{{
            $t('tracking.edit_entry')
          }}</span
          ><span v-else>{{ $t('tracking.edit_active_entry') }}</span>
        </button>
        <button
          class="py-2 px-4 hover:bg-gray-200 basis-full w-full text-left hover:shadow-inner hover:font-semibold"
          type="button"
          @click="contextMenuDeleteItem"
        >
          <font-awesome-icon :icon="['fa-kit', 'tl-trash']" class="pr-2 text-red-500" />
          <span v-if="timeEntryStore.activeTimeEntry != this.selectedContextEvent?.id">{{
            $t('tracking.delete_entry')
          }}</span
          ><span v-else>{{ $t('tracking.delete_active_entry') }}</span>
        </button>
      </div>
    </div>
    <BaseModal v-model="showDeleteModal" :loading="isLoadingDelete" @close-modal="closeDeleteModal">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.timeEntry') }}
        </span>
      </div>

      <template #footer>
        <div class="w-full flex justify-end gap-4">
          <BaseButton
            class="button-gray"
            type="button"
            @click="closeDeleteModal"
            ref="closeDeleteModalButton"
          >
            {{ $t('cancel') }}</BaseButton
          >
          <BaseButton class="button-red" @click="deleteItem" ref="deleteModalButton">{{
            $t('delete')
          }}</BaseButton>
        </div>
      </template>
    </BaseModal>
    <TimeEntryConflictModal
      v-model="showConflictModal"
      @switch-calendar="switchTo"
    ></TimeEntryConflictModal>
    <TimeEntryModalComponent
      v-model="showTimeEntryModal"
      v-model:item="timeEntryModalModel"
      :is-active-time-entry="timeEntryModalIsActiveTimeEntry"
    />
  </div>
  <SquirclePath></SquirclePath>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { useTimeEntryStore } from '@/stores/timeEntry'
import date from 'date-and-time'
import { useAuthUserStore } from '@/stores/auth-user'
import { useClientsStore } from '@/stores/clients'
import { useAppStore } from '@/stores/app'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { onClickOutside } from '@vueuse/core'
import LoadingSpinnerVue from '@/components/LoadingSpinner.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import datetime from '@/lib/datetime'
import TimeEntryConflictModal from '@/components/modals/TimeEntryConflictModal.vue'
import { captureException } from '@sentry/vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'
import TimeEntryList from '@/components/general/TimeEntryList.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'

export default {
  name: 'TrackingComponent',
  components: {
    VueCal,
    LoadingSpinnerVue,
    BaseButton,
    BaseModal,
    SquirclePath,
    TimeEntryConflictModal,
    TimeEntryModalComponent,
    TimeEntryList
  },
  setup() {
    const timeEntryStore = useTimeEntryStore()
    const appStore = useAppStore()
    const clientsStore = useClientsStore()
    const projectsStore = useProjectsStore()
    const servicesStore = useServicesStore()
    const authUserStore = useAuthUserStore()
    const timelinkService = timelinkStoresService
    const dt = datetime
    return {
      timeEntryStore: timeEntryStore,
      appStore,
      clientsStore,
      projectsStore,
      servicesStore,
      authUserStore,
      timelinkService,
      datetime: dt
    }
  },
  data: () => ({
    events: [],
    tmp: [],
    activeView: 'week',
    selectedDate: new Date(),
    selectedEndDate: null,
    cellHeight: 50,
    selectedClient: null,
    clients: [],
    newTimeEntry: null,
    activeContextMenu: false,
    contextHandler: null,
    selectedContextEvent: null,
    isLoading: false,
    dbClickRegistered: false,
    isResizing: false,
    resizeTimeout: null,
    images: {},
    selectedDeleteId: null,
    showDeleteModal: false,
    isLoadingDelete: false,
    activeTimeEntry: null,
    activeTimeEntryEvent: null,
    isDev: false,
    dragActive: false,
    wasDeactivated: false,
    showConflictModal: false,
    showTimeEntryModal: false,
    timeEntryModalId: null,
    timeEntryModalModel: null,
    timeEntryModalIsActiveTimeEntry: false
  }),

  beforeUnmount() {
    this.contextHandler()
    timelinkStoresService.setOrRenewTimeout(
      'timeEntries',
      'clearOld',
      () => {
        useTimeEntryStore().clearOld()
      },
      5000
    )
  },

  mounted() {
    this.isDev = import.meta.env.DEV
    timelinkStoresService.removeTimeout('timeEntries', 'clearOld')
    if (this.events.length == 0) {
      this.isLoading = true
    }
    this.contextHandler = onClickOutside(this.$refs.contextMenuDiv, this.closeContextMenu)
    this.timeEntryStore.$subscribe((mutation, state) => {
      if (
        Object.hasOwn(mutation, 'payload') &&
        Object.hasOwn(mutation.payload, 'activeTimeEntry')
      ) {
        this.activeTimeEntry = mutation.payload.activeTimeEntry
      } else {
        this.activeTimeEntry = this.timeEntryStore.activeTimeEntry
      }
      if (
        state.createdIds.length == 0 &&
        state.changedIds.length == 0 &&
        state.newIds.length == 0 &&
        state.deletedIds.length == 0
      ) {
        return
      }
      useAppStore().addIdWorker('timeEntries')
      if (state.createdIds.length > 0) {
        // this.isLoading = true
        state.createdIds.forEach((createdItem) => {
          this.timelinkService.removeWatcher('tracking', createdItem.old)
          this.events = this.events.filter((item) => item.id != createdItem.old)
          this.addOrUpdate(useTimeEntryStore().getId(createdItem.new))
        })
      }
      if (state.changedIds.length > 0) {
        // this.isLoading = true

        state.changedIds.forEach((item) => {
          this.addOrUpdate(useTimeEntryStore().getId(item))
        })
      }
      if (state.newIds.length > 0) {
        // this.isLoading = true

        state.newIds.forEach((item) => {
          this.addOrUpdate(useTimeEntryStore().getId(item))
        })
      }
      if (state.deletedIds.length > 0) {
        // this.isLoading = true

        state.deletedIds.forEach((id) => {
          this.timelinkService.removeWatcher('tracking', id)
          this.events = this.events.filter((item) => item.id != id)
        })
      }
      // this.stopLoading()
      useAppStore().removeIdWorker('timeEntries', this.timeEntryStore)
    })
    this.$echo
      .private('user.' + useAuthUserStore().userId)
      .listen('TimeEntryDeleted', (payload) => {
        this.events = this.events.filter((item) => item.id != payload.timeEntryId)
      })
      .listen('.timeEntry.deleted', (payload) => {
        this.events = this.events.filter((item) => item.id != payload.timeEntryId)
      })
    timelinkStoresService.setOrRenewTimeout(
      'tracking',
      'initStart',
      () => {
        this.initStart()
      },
      1000
    )
    this.clients = useClientsStore().clients
    this.activeTimeEntry = this.timeEntryStore.activeTimeEntry
  },

  activated() {
    if (this.wasDeactivated) {
      timelinkStoresService.removeTimeout('timeEntries', 'clearOld')
      this.contextHandler = onClickOutside(this.$refs.contextMenuDiv, this.closeContextMenu)
      this.fetchEvents({ view: this.activeView, startDate: this.selectedDate }, false)
    }
    this.wasDeactivated = false
    const divEvents = document.querySelectorAll('.vuecal__event')
    divEvents.forEach((item) => {
      item.removeEventListener('dragstart', this.dragStarted)
      item.addEventListener('dragstart', this.dragStarted)
      item.removeEventListener('dragend', this.dragEnded)
      item.addEventListener('dragend', this.dragEnded)
    })
  },

  deactivated() {
    if (this.contextHandler) {
      this.contextHandler()
    }
    timelinkStoresService.setOrRenewTimeout(
      'timeEntries',
      'clearOld',
      () => {
        useTimeEntryStore().clearOld()
      },
      1000 * 30
    )
    this.wasDeactivated = true
    const divEvents = document.querySelectorAll('.vuecal__event')
    divEvents.forEach((item) => {
      item.removeEventListener('dragstart', this.dragStarted)
      item.removeEventListener('dragend', this.dragEnded)
    })
  },

  computed: {
    selectedTimeEntries() {
      let startDate = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate()
      )
      if (this.activeView == 'week') {
        startDate = date.addDays(startDate, 1 - startDate.getDay())
      }

      let { start, end } = this.calcInterval(startDate, this.activeView)
      let entries = useTimeEntryStore().getInterval(start, end)
      entries = entries.sort((a, b) => {
        return Date.parse(a.started_at) - Date.parse(b.started_at)
      })

      return entries
    }
  },

  watch: {
    activeTimeEntry(newVal, oldVal) {
      const serviceName = 'updateActiveTimeEntry'
      if (newVal) {
        if (timelinkStoresService.isTempId(oldVal)) {
          this.events = this.events.filter((item) => item.id != oldVal)
        }
        this.addOrUpdate(this.timeEntryStore.getActiveTimeEntry)
        timelinkStoresService.setOrRenewInterval(
          'tracking',
          serviceName,
          () => {
            this.addOrUpdate(this.timeEntryStore.getActiveTimeEntry)
          },
          30 * 1000
        )
      } else {
        if (oldVal && !this.timeEntryStore.findId(oldVal)) {
          this.events = this.events.filter((item) => item.id != oldVal)
        }
        timelinkStoresService.removeInterval('tracking', serviceName)
      }
    },
    showDeleteModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.closeDeleteModalButton?.focus()
        })
      }
    }
  },

  methods: {
    zoomIn() {
      if (this.cellHeight < 290) {
        this.cellHeight = this.cellHeight * 1.25
      }
    },
    zoomOut() {
      if (this.cellHeight > 33) {
        this.cellHeight = this.cellHeight / 1.25
      }
    },
    zoomKey(e) {
      if (e.key == '+') {
        this.zoomIn()
      } else if (e.key == '-') {
        this.zoomOut()
      }
    },
    initStart() {
      let now = new Date()
      let start = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      start = date.addDays(start, 1 - start.getDay())
      this.getEvents({ view: 'week', startDate: start })
      timelinkStoresService.setOrRenewTimeout(
        'tracking',
        'fetchStart',
        () => {
          this.fetchStart(false)
        },
        1000
      )
      this.stopLoading()
    },
    fetchStart() {
      let now = new Date()
      let start = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      start = date.addDays(start, 1 - start.getDay())
      this.selectedDate = start
      let end = date.addDays(start, 6)
      end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999)
      this.fetchEvents({ view: 'week', startDate: start, endDate: end }, false)
      this.selectedDate = start
    },
    calcInterval(startDate, view) {
      let start = startDate
      let end =
        view == 'day'
          ? date.addMilliseconds(date.addDays(start, 1), -1)
          : date.addMilliseconds(date.addDays(start, 7), -1)

      let diff = view == 'day' ? 1 : 7
      let previous_start = date.addDays(start, -diff)
      let previous_end = date.addDays(end, -diff)

      let follow_start = date.addDays(start, diff)
      let follow_end = date.addDays(end, diff)
      return {
        start,
        end,
        previous_start,
        previous_end,
        follow_start,
        follow_end
      }
    },
    async switchTo(newDate) {
      let start = newDate
      if (this.activeView == 'week') {
        start = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
        start = date.addDays(start, 1 - start.getDay())
      }
      this.selectedDate = start
      // await this.getEvents({ view: this.activeView, startDate: start })
    },
    async getEvents({ view, startDate }) {
      this.selectedDate = startDate
      this.activeView = view ?? this.activeView
      let { start, end, previous_start, previous_end, follow_start, follow_end } =
        this.calcInterval(startDate, view)
      useTimeEntryStore().selectedTimeInterval.start = previous_start
      useTimeEntryStore().selectedTimeInterval.end = follow_end
      await this.getInterval(start, end, true, false)
      await this.loadInterval(start, end, false)
      this.getInterval(previous_start, previous_end, false, false)
      this.getInterval(follow_start, follow_end, false, false)
      this.loadInterval(previous_start, previous_end, false)
      this.loadInterval(follow_start, follow_end, false)
    },
    async fetchEvents({ view, startDate }, showLoading = true) {
      this.selectedDate = startDate
      this.activeView = view ?? this.activeView
      let { start, end, previous_start, previous_end, follow_start, follow_end } =
        this.calcInterval(startDate, view)
      await this.loadInterval(start, end, showLoading)
      this.loadInterval(previous_start, previous_end, false)
      this.loadInterval(follow_start, follow_end, false)
    },
    async loadInterval(start, end, showLoading = true) {
      try {
        return await this.timeEntryStore.fetchInterval(start, end, () => {
          this.getInterval(start, end, showLoading, false)
        })
      } catch (error) {
        console.log('Error')
        captureException(error)
        await this.getInterval(start, end, showLoading, false)
      }
    },
    async getInterval(start, end, showLoading = false, loadIfNull = true) {
      let entries = this.timeEntryStore.getInterval(start, end)
      if (entries.length == 0) {
        if (showLoading) {
          this.isLoading = true
        }
        if (loadIfNull) {
          try {
            await this.timeEntryStore.fetchInterval(start, end)
          } catch (error) {
            captureException(error)
          }
          entries = this.timeEntryStore.getInterval(start, end)
        }
      }
      entries.forEach((x) => {
        this.addOrUpdate(x)
      })
      if (showLoading) {
        this.stopLoading()
      }
      return entries.length > 0
    },
    addOrUpdate(timeEntry, isActiveTimeEntry = false, ignoreConditions = false) {
      if (this.dragActive) {
        timelinkStoresService.setOrRenewTimeout(
          'tracking',
          'addOrUpdate_id_' + timeEntry?.id,
          () => {
            this.addOrUpdate(timeEntry, isActiveTimeEntry, ignoreConditions)
          },
          Math.floor(Math.random() * 10 + 5)
        )
        return
      }
      if (timeEntry?.started_at) {
        let start = new Date(Date.parse(timeEntry.started_at))
        let end = null
        if (timeEntry.ended_at) {
          end = new Date(Date.parse(timeEntry.ended_at))
        } else {
          isActiveTimeEntry = true
          end = new Date(Date.now())
        }

        let eventData = {
          id: timeEntry.id,
          title: timeEntry.description,
          start: start,
          end: end,
          class: 'ring-1 ring-inset ring-mossgray-200 bg-white',
          color: this.timeEntryStore.getColor(timeEntry).concat('', 'a6'),
          timeEntry: timeEntry,
          paid: timeEntry.paid,
          isActive: isActiveTimeEntry,
          draggable: !isActiveTimeEntry,
          resizable: !isActiveTimeEntry,
          last_update: timeEntry.updated_at
        }
        let foundEvent = null
        if ((foundEvent = this.events.find((item) => item.id == timeEntry.id))) {
          if (
            ignoreConditions ||
            (foundEvent.last_update ?? 0) != eventData.last_update ||
            timeEntry?.tl?.isDirty ||
            timeEntry.id == this.timeEntryStore.activeTimeEntry
          ) {
            Object.entries(eventData).forEach((item) => {
              foundEvent[item[0]] = item[1]
            })
          }
        } else {
          this.events.push(eventData)
        }
      }
      const divEvents = document.querySelectorAll('.vuecal__event')
      divEvents.forEach((item) => {
        item.removeEventListener('dragstart', this.dragStarted)
        item.addEventListener('dragstart', this.dragStarted)
        item.removeEventListener('dragend', this.dragEnded)
        item.addEventListener('dragend', this.dragEnded)
      })
    },

    stopLoading() {
      timelinkStoresService.setOrRenewTimeout(
        'tracking',
        'isLoadingHandler',
        () => {
          this.isLoading = false
        },
        200
      )
    },
    changeEvent({ event }) {
      let foundEvent = this.events.find((item) => item.id == event.id)
      if (foundEvent) {
        foundEvent.start = event.start
        foundEvent.end = event.end
      }
      if (event.timeEntry) {
        event.timeEntry.started_at = event.start.toISOString()
        event.timeEntry.ended_at = event.end.toISOString()

        this.timeEntryStore.update([event.timeEntry.id])
      }
    },
    keypressOnEvent() {},
    dbClick(event) {
      this.dbClickRegistered = true
      // TODO: Second look with martin.
      // if (event.id == this.timeEntryStore.activeTimeEntry) {
      //   this.$router.push({ name: 'Recording' })
      //   return
      // }
      this.openModalWithEntry(event.id)
      timelinkStoresService.setOrRenewTimeout(
        'tracking',
        'dbClickRegistered',
        () => {
          this.dbClickRegistered = false
        },
        50
      )
    },
    dbClickCell(time) {
      if (this.dbClickRegistered) {
        return
      }
      const selDate = new Date(Date.parse(time))
      let minutes = selDate.getMinutes()
      minutes = minutes - (minutes % 5)
      const roundedMinutes = new Date(
        selDate.getFullYear(),
        selDate.getMonth(),
        selDate.getDate(),
        selDate.getHours(),
        minutes,
        0
      )
      this.openModalWithNewEntry(null, roundedMinutes)
    },
    editEvent() {
      this.openModalWithEntry(this.selectedContextEvent.id)
      this.closeContextMenu()
    },
    contextMenu(event, e) {
      this.$refs.contextMenuDiv.style.top = e.pageY + 'px'
      this.$refs.contextMenuDiv.style.left = e.pageX + 'px'
      this.activeContextMenu = true
      this.selectedContextEvent = event
    },
    closeContextMenu() {
      this.activeContextMenu = false
      this.selectedContextEvent = null
    },
    async deleteItem() {
      let id = this.selectedDeleteId
      if (!id) {
        return
      }
      this.isLoadingDelete = true
      useTimeEntryStore().deleteId(id)
      this.selectedDeleteId = null
      this.showDeleteModal = false
      this.isLoadingDelete = false
    },
    showDeleteModalForId(id) {
      this.selectedDeleteId = id
      this.showDeleteModal = true
      this.$nextTick(() => {
        this.$refs.deleteModalButton?.focus()
      })
      this.isLoadingDelete = false
    },
    closeDeleteModal() {
      this.selectedDeleteId = null
      this.showDeleteModal = false
      this.isLoadingDelete = false
    },
    contextMenuDeleteItem() {
      if (this.selectedContextEvent) {
        if (this.selectedContextEvent.timeEntry) {
          this.showDeleteModalForId(this.selectedContextEvent.timeEntry.id)
        } else {
          this.events = this.events.filter((item) => item.id != null)
        }
        this.closeContextMenu()
      }
    },
    eventResize() {
      this.isResizing = true
      timelinkStoresService.setOrRenewTimeout(
        'tracking',
        'resizeTimeout',
        () => {
          this.isResizing = false
        },
        500
      )
    },
    showDiff(start, end) {
      // TODO: Move duration calculation to TimeEntryModel?
      let diff = Date.parse(end) - Date.parse(start)

      return datetime.convertDateNumberToString(diff)
    },
    convertDateNumberToString(dateNumber, withoutSeconds = false) {
      let seconds = Math.floor((dateNumber / 1000) % 60)
        .toString()
        .padStart(2, '0')
      let minutes = Math.floor((dateNumber / (60 * 1000)) % 60)
        .toString()
        .padStart(2, '0')
      let hours = Math.floor((dateNumber / (60 * 60 * 1000)) % 24)
        .toString()
        .padStart(2, '0')
      let days = Math.floor(dateNumber / (24 * 60 * 60 * 1000))
        .toString()
        .padStart(2, '0')
      let output = ''
      if (days != '00') {
        output = `${days} d `
      }
      output = output + `${hours}:${minutes}`
      if (!withoutSeconds) {
        output = output + `:${seconds}`
      }
      return output
    },
    calcDay(day) {
      return datetime.calcDay(day, this.timeEntryStore.timeEntries, this.activeTimeEntry)
    },
    calcWeek(day) {
      return datetime.calcWeek(day, this.timeEntryStore.timeEntries, this.activeTimeEntry)
    },
    dragStarted() {
      this.dragActive = true
    },

    dragEnded() {
      this.dragActive = false
    },
    editActiveTimeEntryEvent() {
      this.$router.push({ name: 'Recording' })
    },
    openModalWithNewEntry(event, startTime = null) {
      this.timeEntryModalIsActiveTimeEntry = false
      this.timeEntryModalModel = this.timeEntryStore.newEntry(startTime)
      this.showTimeEntryModal = true
    },
    openModalWithEntry(id) {
      this.timeEntryModalIsActiveTimeEntry = false
      this.timeEntryModalId = id
      if (id == useTimeEntryStore().activeTimeEntry) {
        console.log('Is Active!')
        this.timeEntryModalIsActiveTimeEntry = true
      }
      this.timeEntryModalModel = this.timeEntryStore.getId(id)
      this.showTimeEntryModal = true
    }
  }
}
</script>
