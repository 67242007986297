<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>

    <div class="py-3 mb-3 flex w-full items-center">
      <h1 class="text-2xl font-bold mr-auto">
        {{ $t('menu.settings_.general') }}
      </h1>

      <BaseButton class="button-mossgray" @click="downloadExport">
        <font-awesome-icon :icon="['fa-kit', 'tl-file-export']"  class="mr-2 text-lg" />
        {{ $t('settings.general.export_time_entries') }}
      </BaseButton>
    </div>

    <div class="divide-y divide-mossgray-200">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-3 pb-6">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('calendar.settings.header') }}
          </h2>
          <p class="mt-1 text-sm leading-5">
            {{ $t('calendar.settings.description') }}
          </p>
        </div>

        <form class="bg-white ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="pb-8">
              <fieldset class="w-full space-y-4">
                <legend class="text-sm font-semibold">
                  {{ $t('calendar.settings.hidden_weekdays') }}
                </legend>
                <div
                  class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-7 w-full"
                >
                  <template v-for="(hideWeekDay, n) in hideWeekDays" :key="n">
                    <div class="relative flex gap-x-3">
                      <div class="flex h-6 items-center">
                        <input
                          :name="'hideWeekdays[' + n + ']'"
                          :id="'hideWeekdays[' + n + ']'"
                          :value="n"
                          :checked="hideWeekDay"
                          @change="updateHideWeekDays(n + 1)"
                          type="checkbox"
                          class="checkbox"
                        />
                      </div>
                      <div class="text-sm">
                        <label :for="'hideWeekdays[' + n + ']'" class="font-medium">{{
                          weekDays[n]
                        }}</label>
                      </div>
                    </div>
                  </template>
                </div>
              </fieldset>
            </div>
            <div class="grid w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-3">
                <span class="block text-sm font-medium">{{
                  $t('calendar.settings.visible_timezone')
                }}</span>
                <div class="mt-2 flex space-x-4">
                  <div>
                    <label
                      for="showTimeIntervalStart"
                      class="block text-sm font-medium leading-6"
                      >{{ $t('calendar.settings.start') }}</label
                    >
                    <input
                      type="number"
                      step="1"
                      min="0"
                      :max="showTimeIntervalEnd - 1"
                      class="input"
                      v-model="showTimeIntervalStart"
                    />
                    <div class="text-xs">{{ $t('default') }}: 6</div>
                  </div>
                  <div>
                    <label for="showTimeIntervalEnd" class="block text-sm font-medium leading-6">{{
                      $t('calendar.settings.end')
                    }}</label>
                    <input
                      type="number"
                      step="1"
                      :min="showTimeIntervalStart + 1"
                      max="24"
                      class="input"
                      v-model="showTimeIntervalEnd"
                    />
                    <div class="text-xs">{{ $t('default') }}: 20</div>
                  </div>
                </div>
              </div>
              <div class="col-span-3 col-start-1">
                <span class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('calendar.settings.automatic_stop_of_recording')
                }}</span>
                <div class="mt-2 flex space-x-4">
                  <div>
                    <label
                      for="showTimeIntervalStart"
                      class="block text-sm font-medium leading-6 text-gray-900 pl-2"
                    ></label>
                    <input
                      type="time"
                      step="60"
                      class="input"
                      name="automatic_stop_of_recording"
                      v-model="automatic_stop_of_recording"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-end gap-x-6 border-t border-mossgray-200 px-4 py-4 sm:px-8"
          >
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <button type="button" @click="save" class="button-mossgray">{{ $t('save') }}</button>
          </div>
        </form>
      </div>
      <div
        class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 py-6"
        v-if="featureFlag.canUseFeature('panel', authUserStore.user)"
      >
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('panel.settings.header') }}
          </h2>
          <p class="mt-1 text-sm leading-5 text-gray-600">
            {{ $t('panel.settings.description') }}
          </p>
        </div>

        <form class="bg-white ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <!-- TODO: Add tooltip for other browsers than electron -->
              <div class="sm:col-span-3">
                <label
                  for="autoLockPanel"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('panel.settings.autoLockPanel') }}</label
                >
                <div class="mt-2">
                  <input
                    type="checkbox"
                    name="autoLockPanel"
                    id="autoLockPanel"
                    autocomplete="Off"
                    v-model="autoLockPanel"
                    class="checkbox"
                  />
                </div>
              </div>
              <div class="sm:col-span-3" v-if="autoLockPanel && lockedStatePermission == true">
                <span class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('panel.settings.autoLockPanel_permissions')
                }}</span>
                <div class="mt-2">
                  <BaseButton @click="requestIdlePermission" class="button-mossgray" type="button">
                    {{ $t('panel.settings.autoLockPanel_permissions_request') }}
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-end gap-x-6 border-t border-mossgray-200 px-4 py-4 sm:px-8"
          >
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <button type="button" @click="save" class="button-mossgray">{{ $t('save') }}</button>
          </div>
        </form>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 py-6">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('settings.general.about_timelink') }}
          </h2>
          <p class="mt-1 text-sm leading-5">
            {{ $t('settings.general.about_timelink_description') }}
          </p>
        </div>

        <div
          class="bg-white ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2 divide-y space-y-2 mb-12"
        >
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <span class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t('version') }}
                </span>
                <div class="mt-2">
                  <span class="font-bold">
                    {{ version ?? 'Development' }}
                  </span>
                </div>
              </div>

              <div class="sm:col-span-3" v-if="this.desktopService.isDesktop()">
                <span class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t('app_version') }}
                </span>
                <div class="mt-2">
                  <span class="font-bold">
                    {{ appVersion }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ExportModal v-model="showExportModal"></ExportModal>
  </TemplateComponent>
</template>

<script>
import { useAuthUserStore } from '@/stores/auth-user'
import DesktopService from '@/services/desktop.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import featureFlagsService from '@/services/feature-flags.service'
import idleService from '@/services/idle.service'
import BaseButton from '@/components/general/BaseButton.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ExportModal from '@/components/modals/ExportModal.vue'
import { useTimeEntryStore } from '@/stores/timeEntry'
import TemplateComponent from './TemplateComponent.vue'
import datetime from '@/lib/datetime'
import { useAlertsStore } from '@/stores/alerts'
import apiService from '@/services/api.service'
import { captureException } from '@sentry/vue'

export default {
  components: {
    BaseButton,
    ExportModal,
    LoadingSpinner,
    TemplateComponent
  },
  setup() {
    const authUserStore = useAuthUserStore()
    const weekDays = datetime.getWeekDays()
    const userAgent = window.navigator.userAgent
    // eslint-disable-next-line no-undef
    const version = APP_VERSION
    let appVersion = null
    let matches = userAgent.match(/(Timelink\/[0-9]+\.[0-9]+\.[0-9]+)/)
    if (matches && matches.length != 0) {
      appVersion = (matches[0] ?? '').substring('Timelink/'.length)
    }
    const desktopService = DesktopService
    const featureFlag = featureFlagsService
    const idle = idleService
    window.idle = idle
    return {
      weekDays,
      authUserStore,
      userAgent,
      version,
      appVersion,
      desktopService,
      featureFlag,
      idle
    }
  },
  data() {
    return {
      hideWeekDaysArray: [],
      showTimeInterval: [6, 20],
      isDesktop: false,
      autoLockPanel: true,
      isLoading: false,
      showExportModal: false,
      automatic_stop_of_recording: null
    }
  },
  mounted() {
    this.isDesktop = this.desktopService.isDesktop()
    this.hideWeekDaysArray = this.authUserStore.user?.settings?.hideWeekDays
    if (this.hideWeekDaysArray == null || this.hideWeekDaysArray == undefined) {
      this.hideWeekDaysArray = []
    }
    this.showTimeInterval = this.authUserStore.user?.settings?.showTimeInterval
    if (this.showTimeInterval == null || this.showTimeInterval == undefined) {
      this.showTimeInterval = [6, 20]
    }
    this.automatic_stop_of_recording =
      this.authUserStore.user?.settings?.automaticStopOfRecording ?? null
    this.autoLockPanel = this.authUserStore.user?.settings?.autoLockPanel ?? true
  },
  computed: {
    hideWeekDays() {
      let arr = []
      for (let i = 1; i < 8; i++) {
        let j = this.hideWeekDaysArray.includes(i)
        arr.push(j)
      }
      return arr
    },
    showTimeIntervalStart: {
      get() {
        return (this.showTimeInterval ?? [6, 20])[0]
      },
      set(value) {
        this.showTimeInterval[0] = value
      }
    },
    showTimeIntervalEnd: {
      get() {
        return (this.showTimeInterval ?? [6, 20])[1]
      },
      set(value) {
        this.showTimeInterval[1] = value
      }
    },
    lockedStatePermission() {
      if (window.IdleDetector == undefined) {
        return null
      }
      return this.idle.needPermission.value
    }
  },
  methods: {
    updateHideWeekDays(n) {
      if (this.hideWeekDaysArray.includes(n)) {
        this.hideWeekDaysArray = this.hideWeekDaysArray.filter((i) => i != n)
      } else {
        this.hideWeekDaysArray.push(n)
      }
    },
    // TODO: Rework this function
    async save() {
      this.isLoading = true
      if (
        this.authUserStore.user.settings == null ||
        this.authUserStore.user.settings == undefined
      ) {
        this.authUserStore.user.settings = {
          hideWeekDays: [],
          showTimeInterval: [6, 20],
          autoLockPanel: true,
          automaticStopOfRecording: null
        }
      }
      this.authUserStore.user.settings.hideWeekDays = this.hideWeekDaysArray
      this.authUserStore.user.settings.showTimeInterval = this.showTimeInterval
      this.authUserStore.user.settings.autoLockPanel = this.autoLockPanel
      this.authUserStore.user.settings.automaticStopOfRecording = this.automatic_stop_of_recording
      try {
        await this.authUserStore.updateUserData()
        useAlertsStore().successfullySaved()
      } catch (error) {
        if (apiService.checkIfServerError()) {
          //
        } else if (apiService.checkIfNotAuthenticated()) {
          return
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
          captureException(error)
        }
      }
      useTimeEntryStore().automaticStop()
      this.isLoading = false
    },
    requestIdlePermission() {
      this.idle.requestPermission()
    },
    async downloadExport() {
      this.showExportModal = true
    }
  }
}
</script>
