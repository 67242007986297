import apiService from '@/services/api.service'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAuthUserStore } from './auth-user'
import { useClientsStore } from './clients'
import { now } from '@vueuse/core'
import timelinkStoresService from '@/services/timelink-stores.service'

export const useProjectsStore = defineStore('projects', {
  /**
   *
   * @returns {{
   * projects: Array<{
   * id: string,
   * name: ?string,
   * info: ?string,
   * color: ?string,
   * image_id: ?string,
   * created_at: ?string,
   * updated_at: ?string,
   * tl: {
   * isDirty: boolean
   * origin: Object
   * }
   * }>,
   * last_fetch: number,
   * activeCount: number,
   * }}
   */
  state: () => ({
    projects: [],
    last_fetch: null,
    activeCount: 0
  }),
  getters: {
    getId: (state) => {
      return (id) => {
        if (id == undefined || id == null) {
          return null
        }
        return state.projects.find((item) => item.id == id)
      }
    },
    getLastUsed: (state) => {
      return (client_id) => {
        const last_used = useAuthUserStore().user?.last_used?.projects
        if (last_used == undefined || last_used == null) {
          return []
        }
        if (client_id == null || client_id == undefined) {
          return state.projects.filter((item) => last_used.includes(item.id))
        }
        return state.projects.filter(
          (item) => item.client_id == client_id && last_used.includes(item.id)
        )
      }
    },
    getDisplayName: (state) => {
      return (id) => {
        let entry = state.getId(id)
        if (!entry) {
          return ''
        }
        return entry.acronym ?? entry.name
      }
    }
  },
  persist: true,
  actions: {
    initFetch() {
      if (this.projects.length == 0) {
        // apiService.fetch(
        //   import.meta.env.VITE_API_URL + '/api/v1/projects',
        //   {
        //     limit: 10
        //   },
        //   (data) => {
        //     data.data.forEach((item) => this.addOrUpdate(item))
        //   },
        //   () => {
        //     this.$reset()
        //   }
        // )
        // this.sortEntries()
      }
      let last_used = useAuthUserStore().user?.last_used?.projects
      if (last_used) {
        this.fetchIds(last_used)
      }
      this.fetchActiveCount()
    },
    fetchAll() {
      apiService.fetchAll(
        import.meta.env.VITE_API_URL + '/api/v1/projects',
        {},
        (data) => {
          data.data.forEach((item) => this.addOrUpdate(item))
        },
        () => {
          this.$reset()
        }
      )
    },
    fetchCount(filter = {}, callback = null) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/projects',
        { ...filter, limit: 1 },
        (data, response) => {
          if (typeof callback == 'function') {
            callback(response.data.meta.total, response)
          }
        },
        () => {}
      )
    },
    fetchAllActiveCount(filter = {}, callback = null) {
      this.fetchCount({ ...filter, active: true }, (total, response) => {
        this.activeCount = total
        if (typeof callback == 'function') {
          callback(total, response)
        }
      })
    },
    fetchActiveCount(client = null, filter = { client_id: null }, callback = null) {
      if (filter.client_id == undefined || filter.client_id == null) {
        if (client) {
          filter.client_id = client.id
        }
      }
      this.fetchCount({ ...filter, active: true }, (total, response) => {
        if (client) {
          client.tl.activeProjects = total
        }
        if (typeof callback == 'function') {
          callback(total, response)
        }
      })
    },
    async fetchIds(ids = []) {
      ids.filter((item) => item != undefined && item != null)

      // create mapIds as a array which holding the ids as chunks
      let mapIds = []
      let ids_chunks = ids.length / 10
      for (let i = 0; i < ids_chunks; i++) {
        mapIds.push(ids.slice(i * 10, (i + 1) * 10))
      }

      try {
        for (let i = 0; i < mapIds.length; i++) {
          await apiService.fetchAll(
            import.meta.env.VITE_API_URL + '/api/v1/projects',
            { ids: mapIds[i] },
            (data) => {
              data.data.forEach((item) => this.addOrUpdate(item))
            },
            () => {}
          )
        }
      } catch (error) {
        console.error(error)
      }
    },
    fetchUpdates() {
      let ids = []
      if (ids.length > 200) {
        return
      }
      this.projects.forEach((item) => {
        ids.push(item.id)
      })

      let mapIds = []
      let ids_chunks = ids.length / 10
      for (let i = 0; i < ids_chunks; i++) {
        mapIds.push(ids.slice(i * 10, (i + 1) * 10))
      }
      // fetch all the clients
      mapIds.map((item) => {
        apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/projects',
          {
            limit: 200,
            ids: item
          },
          (data) => {
            let foundIds = []
            data.data.forEach((item) => {
              foundIds.push(item.id)
              this.addOrUpdate(item, true)
            })
            let notFound = ids.filter((item) => !foundIds.includes(item))
            this.projects = this.projects.filter((item) => !notFound.includes(item.id))
          }
        )
      })
    },
    fetchIfNotExists(id) {
      if (this.getId(id) == null) {
        this.fetchIds([id])
      }
    },
    fetch(filter = {}, addSearch = false, addPanel = false, callback = null) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/projects',
        filter,
        (data, response) => {
          data.data.forEach((item) => this.addOrUpdate(item, addSearch, addPanel))
          if (typeof callback == 'function') {
            callback(data, response)
          }
        },
        () => {}
      )
    },
    search(query, client_id = null, params = {}) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/projects',
        {
          search: query,
          client_id: client_id,
          limit: 10,
          ...params
        },
        (data) => {
          let client_ids = []
          data.data.forEach((item) => {
            client_ids.filter((sItem) => sItem.id != item.client_id)
            client_ids.push(item.client_id)
            this.addOrUpdate(item, true)
          })
          useClientsStore().fetchIds(client_ids, true)
        },
        () => {}
      )
    },
    addOrUpdate(entry, addSearch = false, addPanel = false) {
      let o_entry = null
      if ((o_entry = this.projects.find((item) => entry.id === item.id))) {
        if (entry.updated_at != o_entry.updated_at) {
          o_entry.tl.origin = entry
          o_entry.tl.ignoreKeys = ['name_with_client']
          if (!o_entry.tl.isDirty) {
            let fetchImage = false
            Object.entries(entry).forEach((item) => {
              if (item[0] == 'image_id' && o_entry[item[0]] != item[1]) {
                fetchImage = true
              }
              o_entry[item[0]] = item[1]
            })
            if (fetchImage || o_entry.tl.image == null) {
              timelinkStoresService.getImageFor(this.getId(o_entry.id), null, false)
            }
          }
        }
        let client = useClientsStore().getId(o_entry.client_id)
        // o_entry.name_with_client = o_entry.name + ' (' + client?.name + ')'
        o_entry.tl.clientActive = client?.active ?? true
      } else {
        entry.tl = {
          origin: { ...entry },
          isDirty: false,
          last_fetch: now(),
          ignoreKeys: ['name_with_client'],
          search: false,
          panel: false
        }
        if (addSearch) {
          entry.tl.search = true
        }
        if (addPanel) {
          entry.tl.panel = true
        }
        let client = useClientsStore().getId(entry.client_id)
        // entry.name_with_client = entry.name + ' (' + client?.name + ')'
        entry.tl.clientActive = client?.active ?? true
        this.projects.push(entry)
        if (entry.image_id) {
          setTimeout(() => {
            timelinkStoresService.getImageFor(this.getId(entry.id))
          }, 20)
        }
      }
      this.sortEntries()
    },
    updateIfExists(entry) {
      let o_entry = null
      if ((o_entry = this.projects.find((item) => entry.id === item.id))) {
        o_entry.tl.origin = entry
        o_entry.tl.ignoreKeys = ['name_with_client']
        if (!o_entry.tl.isDirty) {
          if (entry.updated_at != o_entry.updated_at) {
            Object.entries(entry).forEach((item) => {
              o_entry[item[0]] = item[1]
            })
          }
        }
        // o_entry.name_with_client =
        //   o_entry.name + ' (' + useClientsStore().getId(o_entry.client_id)?.name + ')'
        return true
      }
      return false
    },
    sortEntries() {
      timelinkStoresService.setOrRenewTimeout(
        'projects',
        'sortEntries',
        () => {
          let used_projects = []
          let last_used = useAuthUserStore().user?.last_used?.projects
          if (last_used == null || last_used == undefined) {
            last_used = []
          }
          last_used?.forEach((item) => {
            let found = this.getId(item)
            if (found) {
              used_projects.push(found)
            }
          })
          this.projects = this.projects.filter((item) => !last_used.includes(item.id))
          this.projects.sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
          this.projects.unshift(...used_projects)
        },
        20
      )
    },
    removeId(id) {
      this.projects = this.projects.filter((item) => item.id != id)
    },
    removeSearch(foundIds) {
      this.projects = this.projects.filter(
        (item) =>
          (useAuthUserStore().user.last_used ?? { projects: [] })['projects'].includes(item.id) ||
          foundIds.includes(item.id) ||
          !(item.tl.search ?? false) ||
          !(item.tl.panel ?? false)
      )

      this.projects.forEach((item) => {
        item.tl.search = false
      })
    },
    removeOld(foundIds) {
      this.projects = this.projects.filter(
        (item) =>
          foundIds.includes(item.id) ||
          useAuthUserStore().lastUsed('projects').includes(item.id) ||
          (item.tl.panel ?? false)
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProjectsStore, import.meta.hot))
}
