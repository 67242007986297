<!-- TODO: massive code duplication! include calendar component ? -->
<template>
  <div class="w-full site-background min-h-screen">
    <loading-spinner-vue v-model="isLoading" :fullPage="true" :overAll="true"></loading-spinner-vue>
    <div class="flex flex-col px-6 pb-16" @keypress="zoomKey">
      <div class="mb-5 flex items-end w-full border-b border-gray-200 justify-between">
        <h1
          class="font-bold border-b-4  inline-block pb-1"
        >
          {{ $t('menu.calendar') }}
        </h1>

        <div class="flex flex-row py-3 pl-3">
          <BaseButton
            type="button"
            class="button-white"
            @click="
              () => {
                return
              }
            "
            v-tippy="{ content: $t('tracking.button.description.calender_help') }"
          >
            {{ $t('tracking.button.calender_help') }}
          </BaseButton>
        </div>
        <div class="flex flex-row py-3 pl-3">
          <BaseButton class="button-mossgray" @click="() => {}">
            <font-awesome-icon :icon="['fad', 'plus']" size="1x" class="mr-2" />
            {{ $t('calendar.new_entry') }}
          </BaseButton>
        </div>
      </div>
      <div class="flex justify-between mb-4 items-center flex-wrap">
        <div class="flex items-center flex-grow-0">
          <div class="shrink-0">
            <button @click="() => {}">
              <font-awesome-icon :icon="['fad', 'chevron-left']" class="mr-3 text-3xl" />
            </button>
            <button @click="() => {}">
              <font-awesome-icon :icon="['fad', 'chevron-right']" class="mr-2 text-3xl" />
            </button>
          </div>
          <div class="text-3xl mr-auto pb-1">
            <span v-if="activeView === 'month'">{{ selectedDate.format('MMMM YYYY') }}</span>
            <span v-else-if="activeView === 'week'"
              ><b>{{
                selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                  month: 'long',
                  year: 'numeric'
                })
              }}</b
              >&nbsp; \&nbsp; {{ $t('calendar.short.calender_week') }}
              {{ selectedDate.getWeek() }}</span
            >
            <span v-else-if="activeView === 'day'"
              ><b
                >{{
                  selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                  })
                }}
                -
                {{
                  selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                    weekday: 'long'
                  })
                }}</b
              ></span
            >
          </div>
        </div>

        <div class="text-lg font-semibold">
          <span v-if="activeView == 'week'"> {{ calcWeek(selectedDate) }} </span>
          <span v-if="activeView == 'day'"> {{ calcDay(selectedDate) }} </span>
        </div>

        <div class="flex gap-3 items-center flex-grow-0">
          <div class="shrink-0">
            <BaseButton
              type="button"
              class="button-white !rounded-r-none font-bold"
              @click="() => {}"
              v-tippy="$t('tracking.zoom_in')"
            >
              +
            </BaseButton>
            <BaseButton
              type="button"
              class="button-white !rounded-l-none font-bolder"
              @click="() => {}"
              v-tippy="$t('tracking.zoom_out')"
            >
              -
            </BaseButton>
          </div>

          <BaseButton class="button-white font-semibold" @click="() => {}">
            {{ $t('today') }}
          </BaseButton>
          <div class="shrink-0">
            <BaseButton
              class="button-white !rounded-r-none font-semibold disabled:!bg-timelink-blue-800 disabled:text-white"
              :class="[
                activeView == 'day'
                  ? 'bg-timelink-blue text-white'
                  : 'bg-timelink-gray text-gray-700'
              ]"
              :disabled="activeView == 'day'"
              v-tippy="$t('tracking.open_day_view')"
              @click="() => {}"
            >
              {{ $t('calendar.short.day') }}
            </BaseButton>
            <BaseButton
              class="button-white !rounded-l-none font-semibold disabled:!bg-timelink-blue-800 disabled:text-white"
              :class="[
                activeView == 'week'
                  ? 'bg-timelink-blue text-white'
                  : 'bg-timelink-gray text-gray-700'
              ]"
              :disabled="activeView == 'week'"
              v-tippy="$t('tracking.open_week_view')"
              @click="() => {}"
            >
              {{ $t('calendar.short.week') }}
            </BaseButton>
            <!-- <button
            class="hover:text-white hover:bg-timelink-blue py-2 pr-4 pl-3 rounded-r-full"
            :class="[
              activeView == 'month' ? 'bg-timelink-blue text-white' : 'bg-timelink-gray text-gray-700'
            ]"
            @click="activeView = 'month'"
          > 
            M
          </button> -->
          </div>
        </div>
      </div>

      <div class="grow overflow-y-auto">
        <vue-cal
          class="vuecal--blue-theme bg-white shadow-xl rounded-md rounded-t-2xl"
          ref="vuecal"
          v-model:active-view="activeView"
          :events="events"
          :time-from="
            ((authUserStore.user?.settings?.showTimeInterval == undefined ||
            authUserStore.user?.settings?.showTimeInterval == null
              ? null
              : authUserStore.user?.settings?.showTimeInterval) ?? [6, 20])[0] * 60
          "
          :time-to="
            ((authUserStore.user?.settings?.showTimeInterval == undefined ||
            authUserStore.user?.settings?.showTimeInterval == null
              ? null
              : authUserStore.user?.settings?.showTimeInterval) ?? [6, 20])[1] * 60
          "
          :disable-views="['years', 'year', 'month']"
          :time-step="60"
          :time-cell-height="cellHeight"
          watchRealTime
          hide-title-bar
          hide-view-selector
          locale="de"
          :hide-weekdays="[]"
          @view-change="() => {}"
          @event-change="() => {}"
          :dblclickToNavigate="false"
          :clickToNavigate="false"
          :on-event-create="() => {}"
          :onEventDblclick="() => {}"
          :cellContextmenu="true"
          @event-drop="() => {}"
          @cell-dblclick="() => {}"
          @event-resizing="() => {}"
          :editable-events="{
            title: false,
            drag: true,
            resize: true,
            delete: false,
            create: false
          }"
        >
          <!-- <template #cell-content="{}">
            <div
              class="h-full"
              @mouseover="
                () => {
                }
              "
            >
              Test
            </div>
          </template> -->
          <!-- <template v-slot:weekday-heading="{ heading, view }"> -->

          <template v-slot:weekday-heading="{ heading }">
            <div class="text-black">
              <span v-if="heading.date" class="text-2xl block">{{ heading.date.format('D') }}</span>
              <span>{{ heading.label.toUpperCase() }}</span>
              <div class="text-sm">{{ calcDay(heading.date) }}</div>
            </div>
          </template>
          <template v-slot:no-event="{}">
            <span class="">{{ $t('no_entries') }}</span>
          </template>

          <template v-slot:event="{ event, view }">
            <!-- <v-icon>{{ event.icon }}</v-icon> -->
            <div
              @drag="dragStarted"
              class="flex flex-row h-full group relative"
              :class="{
                grow: view == 'week',
                'entry-sync-error':
                  event.timeEntry?.push_state >= 5 ||
                  (event.timeEntry?.tl?.error_code != undefined &&
                    event.timeEntry?.tl?.error_code != null)
              }"
              @keyup="keypressOnEvent"
              @contextmenu="
                (e) => {
                  contextMenu(event, e)
                }
              "
            >
              <!-- <div class="" v-text="view"></div> -->
              <div
                class="color-code min-w-4 group-hover:min-w-5 grow-0"
                :style="{ backgroundColor: event.color ?? '#00a' }"
              ></div>
              <div class="grow pl-3 pr-4 pb-1 text-left leading-4">
                <div
                  class="vuecal__event-title flex justify-between text-sm font-bold"
                  v-show="!isResizing"
                >
                  <!-- <div class="" v-if="isDev">{{ event.id }}</div> -->

                  <div class="" :class="{ 'py-1': event.timeEntry?.client_id }">
                    <span>{{ clientsStore.getId(event.timeEntry?.client_id)?.name }}</span>
                  </div>
                  <div class="py-0">
                    <div
                      v-if="event.isActive"
                      class="text-red-500 pt-3 overflow-visible cursor-pointer"
                      @click="editActiveTimeEntryEvent"
                    >
                      <font-awesome-icon
                        :icon="['fad', 'stopwatch']"
                        size="xl"
                        bounce
                        style="--fa-animation-duration: 2s"
                      />
                    </div>
                    <span
                      class="text-sm font-bold text-red-500 overflow-visible"
                      v-if="
                        timelinkService.isTempId(event.id) &&
                        appStore.selectedTimeEntryId !== event.id
                      "
                    >
                      {{ $t('tracking.warning') }}!
                      <font-awesome-icon
                        :icon="['fad', 'exclamation']"
                        class="text-red-500"
                        v-tippy="$t('errors.no_update_or_create_due_to_connection')"
                      />
                    </span>
                    <font-awesome-icon
                      :icon="['fad', 'cloud-arrow-up']"
                      class="text-timelink-blue-700"
                      v-tippy="$t('tracking.entry_will_be_transferred')"
                      v-show="
                        !timelinkService.isTempId(event.id) &&
                        event.timeEntry?.tl.isDirty &&
                        event.timeEntry?.tl?.isUpdating &&
                        (event.timeEntry?.tl?.error_code == undefined ||
                          event.timeEntry?.tl?.error_code == null)
                      "
                    />
                    <font-awesome-icon
                      :icon="['fad', 'circle-exclamation']"
                      class="text-red-700"
                      v-tippy="$t('errors.no_provider_sync')"
                      v-show="
                        !timelinkService.isTempId(event.id) &&
                        !event.timeEntry?.tl.isDirty &&
                        event.timeEntry?.push_state >= 5
                      "
                    />
                    <!-- :title="event.timeEntry?.tl?.error ?? ''" -->
                    <font-awesome-icon
                      :icon="['fad', 'signal-slash']"
                      v-tippy="$t('errors.no_update_retry')"
                      class="text-red-700"
                      v-show="event.timeEntry?.tl?.error_code == 1"
                    />
                  </div>
                </div>
                <small class="vuecal__event-time space-y-1">
                  <!-- Using Vue Cal injected Date prototypes -->
                  <strong
                    ><span>{{ event.start.formatTime() }}</span> -
                    <span>{{ event.end.formatTime() }}</span></strong
                  >
                  <div v-show="isResizing" class="vuecal__event-title">
                    <span>
                      {{ clientsStore.getId(event.timeEntry?.client_id)?.name }}
                    </span>
                  </div>
                  <div class="flex flex-col space-y-1">
                    <!-- <span v-if="event.timeEntry?.client_id"
                      ><span class="text-gray-500 sr-only">Kunde: </span>
                      <span class="font-semibold">
                        {{ clientsStore.getId(event.timeEntry?.client_id)?.name }}
                      </span>
                    </span>
                    <span v-else>Kein Kunde gewählt</span> -->
                    <span v-if="event.timeEntry?.project_id"
                      ><span class="text-gray-500 sr-only">Projekt: </span>
                      <span class="font-semibold">
                        {{ projectsStore.getId(event.timeEntry?.project_id)?.name }}
                      </span>
                    </span>
                    <span v-else class="px-1">-</span>
                    <span v-if="event.timeEntry?.service_id"
                      ><span class="text-gray-500 sr-only">Service: </span>
                      <span class="font-semibold">
                        {{ servicesStore.getId(event.timeEntry?.service_id)?.name }}
                      </span>
                    </span>
                  </div>
                  <div class="text-gray-900 font-normal w-full border-t border-gray-200 py-1">
                    <span v-text="event.timeEntry?.description"></span>
                  </div>
                </small>
                <!-- TODO: remove? -->
                <div class="break-words">
                  <!-- {{ event.timeEntry.id }} -->
                </div>
              </div>
            </div>
          </template>
        </vue-cal>
      </div>
      <div class="py-2 flex flex-col space-y-2">
        <template v-for="(timeEntry, index) in selectedTimeEntries" :key="timeEntry.id">
          <div>
            <div
              v-if="
                index == 0 ||
                new Date(Date.parse(timeEntry.started_at)).getDate() !=
                  new Date(Date.parse(selectedTimeEntries[index - 1].started_at)).getDate()
              "
              class="my-2 border-b border-gray-400 mx-4"
            >
              <h1
                class="text-lg font-bold text-timelink-blue border-b-4 border-timelink-blue inline-block pb-0 ml-0"
              >
                {{
                  new Date(Date.parse(timeEntry.started_at)).toLocaleDateString(
                    authUserStore?.user?.language ?? 'de',
                    {
                      weekday: 'long'
                    }
                  )
                }}
                {{
                  new Date(Date.parse(timeEntry.started_at)).toLocaleDateString(
                    authUserStore?.user?.language ?? 'de'
                  )
                }}
                <span class="text-sm pl-3">{{
                  calcDay(new Date(Date.parse(timeEntry.started_at)))
                }}</span>
              </h1>
            </div>
            <div
              class="border rounded-2xl shadow hover:shadow-lg flex flex-row relative group bg-white"
              :class="{ 'min-h-16': !isResizing }"
            >
              <div
                :style="{ backgroundColor: this.timeEntryStore.getColor(timeEntry) }"
                class="min-w-6 h-hull relative min-h-16 rounded-l-2xl overflow-visible mr-10 m-0"
              >
                <div class="absolute h-full flex items-center w-full justify-center">
                  <div
                    class="w-10 group-hover:w-11 absolute left-2 m-0 bg-gray-200 text-center overflow-hidden rounded-full"
                  >
                    <img
                      :src="timelinkService.getImageFor(clientsStore.getId(timeEntry.client_id))"
                      class="object-scale-down object-center"
                    />
                  </div>
                </div>
              </div>
              <div
                class="px-4 py-2 grid grid-cols-1 grid-rows-2 lg:grid-rows-1 md:grid-cols-12 w-full gap-4 h-full min-h-16 items-center grid-flow-col"
              >
                <div class="col-span-11 lg:col-span-7">
                  <div class="text-timelink-blue-700 font-bold text-lg">
                    {{
                      servicesStore.getId(timeEntry.service_id)?.name
                        ? servicesStore.getId(timeEntry.service_id).name
                        : '&nbsp;'
                    }}
                  </div>
                  <div class="text-sm text-timelink-gray-800" v-show="timeEntry.client_id">
                    <span>
                      {{
                        clientsStore.getId(timeEntry.client_id)?.name ??
                        $t('tracking.no_client_name')
                      }} </span
                    ><span v-show="timeEntry.project_id">
                      > {{ projectsStore.getId(timeEntry.project_id)?.name }}</span
                    >
                  </div>
                  <div class="text-sm text-timelink-gray-800" v-show="!timeEntry.client_id">
                    {{ $t('tracking.no_client_selected') }}
                  </div>
                </div>
                <div
                  class="flex items-center col-span-4 lg:col-span-2 font-semibold text-timelink-gray-950"
                >
                  {{
                    new Date(Date.parse(timeEntry.started_at ?? null))
                      .toLocaleTimeString()
                      .substring(0, 5)
                  }}
                  -
                  {{
                    new Date(Date.parse(timeEntry.ended_at ?? null))
                      .toLocaleTimeString()
                      .substring(0, 5)
                  }}
                  {{ $t('o_clock') }}
                </div>
                <div
                  class="col-span-4 lg:col-span-2 flex items-center font-semibold text-timelink-gray-950 space-x-2"
                  v-tippy="{ content: $t('duration'), followCursor: 'horizontal' }"
                >
                  <span class="">
                    {{ this.showDiff(timeEntry.started_at, timeEntry.ended_at) }}
                  </span>
                  <font-awesome-icon :icon="['fad', 'clock']" />
                </div>
                <div
                  class="flex items-center pr-2 md:pr-4 lg:pr-6 xl:pr-8 justify-end space-x-4 row-span-2 lg:row-span-1"
                >
                  <button type="button" @click="() => {}">
                    <font-awesome-icon
                      :icon="['fad', 'calendar-pen']"
                      class="text-timelink-blue"
                      size="xl"
                    />
                  </button>
                  <button type="button" @click="() => {}">
                    <font-awesome-icon :icon="['fad', 'trash']" class="text-red-500" size="xl" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      class="!bg-mossgray-200 absolute z-30 shadow-md rounded-md shadow-gray-400"
      v-show="activeContextMenu"
      id="contextMenu"
      ref="contextMenuDiv"
    >
      <div class="flex flex-col divide-y text-left justify-start items-start w-52">
        <button
          class="py-2 px-4 hover:bg-gray-200 basis-full w-full text-left hover:shadow-inner hover:font-semibold"
          type="button"
          @click="() => {}"
        >
          <font-awesome-icon :icon="['fad', 'calendar-pen']" class="pr-2 text-timelink-blue" />
          <span v-if="timeEntryStore.activeTimeEntry != this.selectedContextEvent?.id">{{
            $t('tracking.edit_entry')
          }}</span
          ><span v-else>{{ $t('tracking.edit_active_entry') }}</span>
        </button>
        <button
          class="py-2 px-4 hover:bg-gray-200 basis-full w-full text-left hover:shadow-inner hover:font-semibold"
          type="button"
          @click="() => {}"
        >
          <font-awesome-icon :icon="['fad', 'trash']" class="pr-2 text-red-500" />
          <span v-if="timeEntryStore.activeTimeEntry != this.selectedContextEvent?.id">{{
            $t('tracking.delete_entry')
          }}</span
          ><span v-else>{{ $t('tracking.delete_active_entry') }}</span>
        </button>
      </div>
    </div>
  </div>
  <!-- TODO: Use general modal dialog?? -->
  <TransitionRoot as="template" :show="true">
    <BaseDialog as="div" class="relative z-20" ref="modal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed z-30 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-30 inset-0 w-screen overflow-y-auto">
        <div
          class="flex min-h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-2xl shadow-gray-600 transition-all sm:my-8 sm:w-full sm:max-w-3xl z-30"
            >
              <form @submit.prevent="save" ref="form">
                <div class="px-8 pt-4 flex items-end border-b border-mossgray-200">
                  <h1
                    class="text-lg font-bold inline-block pb-1"
                  >
                    <span
                      ><span v-if="!registration_complete">{{ $t('complete_registration') }}</span
                      ><span v-else>{{ $t('registration_completed') }}</span></span
                    >
                  </h1>
                </div>
                <div class="p-8 flex flex-col gap-4" v-if="!registration_complete">
                  <div>
                    <div class="text-lg font-semibold">
                      {{ $t('personal_data') }}
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                      <div class="flex flex-col">
                        <label for="first_name" class="input-label label-required">{{
                          $t('first_name')
                        }}</label>
                        <input class="input" type="text" v-model="user.first_name" required />
                      </div>
                      <div class="flex flex-col">
                        <label for="last_name" class="input-label label-required">{{
                          $t('last_name')
                        }}</label>
                        <input class="input" type="text" v-model="user.last_name" required />
                      </div>

                      <div class="flex flex-col col-start-1">
                        <label for="password" class="input-label label-required">{{
                          $t('password')
                        }}</label>
                        <input
                          class="input"
                          type="password"
                          v-model="password"
                          required
                          name="password"
                          id="password"
                          min="6"
                          max="64"
                          autocomplete="new-password"
                          :class="{ '!ring-red-500': password != password_confirmation }"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="password_confirmation" class="input-label label-required">{{
                          $t('password_confirmation')
                        }}</label>
                        <input
                          class="input"
                          type="password"
                          v-model="password_confirmation"
                          name="password_confirmation"
                          id="password_confirmation"
                          required
                          min="6"
                          max="64"
                          autocomplete="new-password"
                          :class="{ '!ring-red-500': password != password_confirmation }"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label
                          for="language"
                          class="block text-sm font-medium leading-6 text-gray-900"
                          >{{ $t('language') }}</label
                        >
                        <div class="mt-2">
                          <select
                            id="language"
                            name="language"
                            autocomplete="language"
                            v-model="user.language"
                            class="input w-full"
                          >
                            <option value="de">{{ $t('languages.de') }}</option>
                            <option value="en">{{ $t('languages.en') }}</option>
                          </select>
                        </div>
                      </div>
                      <!-- TODO: Rework validation error 
                      This one will be generated through the server.
                     -->

                      <div
                        class="flex flex-col col-span-2 border-l-2 border-red-700 bg-red-100 p-4"
                        v-if="password_error"
                      >
                        <span class="text-red-800 font-semibold text-sm" v-text="password_error">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="text-lg font-semibold">
                      {{ $t('address_data') }}
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                      <div class="flex flex-col col-span-2">
                        <label for="company_name" class="input-label label-required">{{
                          $t('company')
                        }}</label>
                        <input
                          class="input"
                          type="text"
                          id="company_name"
                          name="company_name"
                          v-model="company.name"
                          required
                          placeholder="Muster GmbH / Privat"
                        />
                      </div>
                      <div class="flex flex-col col-span-2">
                        <label for="company_address" class="input-label">{{ $t('address') }}</label>
                        <input
                          class="input"
                          type="text"
                          name="company_address"
                          id="company_address"
                          v-model="company.address"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="company_zip" class="input-label">{{ $t('zip') }}</label>
                        <input
                          class="input"
                          type="text"
                          name="company_zip"
                          id="company_zip"
                          v-model="company.zip"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="company_city" class="input-label">{{ $t('city') }}</label>
                        <input
                          class="input"
                          type="text"
                          name="company_city"
                          id="company_city"
                          v-model="company.city"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="company_email" class="input-label">{{
                          $t('administrative_email')
                        }}</label>
                        <input
                          class="input"
                          type="text"
                          name="company_email"
                          id="company_email"
                          v-model="company.email"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="company_invoice_email" class="input-label">{{
                          $t('invoice_email')
                        }}</label>
                        <input
                          class="input"
                          type="text"
                          name="company_invoice_email"
                          id="company_invoice_email"
                          v-model="company.invoice_email"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="company_phone" class="input-label">{{ $t('phone') }}</label>
                        <input
                          class="input"
                          type="text"
                          name="company_phone"
                          id="company_phone"
                          v-model="company.phone"
                        />
                      </div>
                      <!-- <div class="flex flex-col">
                        <label for="company_vatid" class="input-label">{{ $t('vatid') }}</label>
                        <input
                          class="input"
                          type="text"
                          name="company_vatid"
                          id="company_vatid"
                          v-model="company.vatid"
                          required
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="p-8 flex flex-col gap-4" v-else>
                  <div class="flex items-center">
                    <div class="basis-1/4 p-4">
                      <font-awesome-icon
                        :icon="['fad', 'badge-check']"
                        class="w-32 h-32"
                      />
                    </div>
                    <div class="basis-3/4 space-y-2">
                      <p>
                        <span class="font-semibold text-lg"
                          >{{ $t('registration_completed') }}.</span
                        ><br />
                        {{ $t('registration_complete.info_1') }}
                      </p>
                      <p>
                        {{ $t('registration_complete.info_2') }}
                      </p>

                      <p class="text-sm text-gray-500"></p>
                    </div>
                  </div>
                </div>
                <div
                  class="py-4 px-8 flex justify-between items-center border-t border-mossgray-200"
                  v-if="!registration_complete"
                >
                  <BaseButton class="button-gray" @click="logout" type="button">{{
                    $t('cancel_and_logout')
                  }}</BaseButton>

                  <BaseButton class="button-mossgray" type="submit"> {{ $t('complete') }} </BaseButton>
                </div>
                <div
                  class="py-4 px-8 flex justify-end items-center border-t border-mossgray-200"
                  v-else
                >
                <!-- TOOD: goto Dashboard? currently no dashboard -->
                  <BaseButton class="button-mossgray" type="button" @click="goToDashboard">
                    {{ $t('next') }}
                  </BaseButton>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </BaseDialog>
  </TransitionRoot>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useAuthUserStore } from '@/stores/auth-user'
import { useClientsStore } from '@/stores/clients'
import { useAppStore } from '@/stores/app'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import LoadingSpinnerVue from '@/components/LoadingSpinner.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import BaseButton from '@/components/general/BaseButton.vue'

import { Dialog as BaseDialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useCompanyStore } from '@/stores/company'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import apiService from '@/services/api.service'

export default {
  components: {
    VueCal,
    LoadingSpinnerVue,
    BaseButton,
    BaseDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot
  },
  setup() {
    const timeEntryStore = useTimeEntryStore()
    const appStore = useAppStore()
    const clientsStore = useClientsStore()
    const projectsStore = useProjectsStore()
    const servicesStore = useServicesStore()
    const authUserStore = useAuthUserStore()
    const timelinkService = timelinkStoresService
    const weekDayNames = [
      'Sontag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag'
    ]
    return {
      timeEntryStore: timeEntryStore,
      appStore,
      clientsStore,
      projectsStore,
      servicesStore,
      authUserStore,
      timelinkService,
      weekDayNames
    }
  },
  data: () => ({
    events: [],
    selectedTimeEntries: [],
    tmp: [],
    activeView: 'week',
    selectedDate: new Date(),
    selectedEndDate: null,
    cellHeight: 50,
    selectedClient: null,
    clients: [],
    newTimeEntry: null,
    activeContextMenu: false,
    contextHandler: null,
    selectedContextEvent: null,
    isLoading: false,
    isLoadingHandler: null,
    dbClickRegistered: false,
    isResizing: false,
    resizeTimeout: null,
    images: {},
    user: {
      first_name: null,
      last_name: null,
      language: 'de'
    },
    company: {
      name: null,
      address: null,
      city: null,
      zip: null,
      phone: null,
      email: null,
      invoice_email: null,
      vatid: null
    },
    password: null,
    password_confirmation: null,
    password_error: null,
    registration_complete: false
  }),
  created() {
    this.company.email = this.authUserStore.user.email
    this.company.invoice_email = this.company.email
  },
  watch: {
    'user.language'(newVal) {
      this.$i18n.locale = newVal ?? 'de'
    }
  },
  methods: {
    logout() {
      this.authUserStore.logout()
    },
    goToDashboard() {
      timelinkStoresService.removeTimeout('registration', 'complete')
      this.$router.push('/')
    },
    async save() {
      if (!this.$refs.form.reportValidity()) {
        return
      }
      if (this.password != this.password_confirmation) {
        return
      }
      let authUser = this.authUserStore.user
      authUser.first_name = this.user.first_name
      authUser.last_name = this.user.last_name
      this.isLoading = true
      await this.authUserStore
        .updateUserData(
          {
            password: this.password,
            password_confirmation: this.password_confirmation
          },
          this.user.language
        )
        .then(() => {
          useCompanyStore().internalUpdate(this.company)
          axios
            .post(import.meta.env.VITE_API_URL + '/api/v1/companies', {
              id: 'temp',
              ...this.company
            })
            .then((response) => {
              if (!response.data.success) {
                // TODO: Add error message!
                return
              }
              useCompanyStore().internalUpdate(response.data.data)
              useAuthUserStore().companyId = response.data.data.id
              useAuthUserStore().user.company_id = response.data.data.id
              this.isLoading = false
              this.registration_complete = true
              // timelinkStoresService.setOrRenewTimeout(
              //   'registration',
              //   'complete',
              //   () => {
              //     this.$router.push('/')
              //   },
              //   5000
              // )
            })
        })
        .catch((response) => {
          // logger.error('completeRegistration', 'save', response)
          this.isLoading = false

          if (response?.response?.status == 422 && response?.response?.data?.errors?.password) {
            this.password_error = response.response.data.errors.password.join(' ')
          } else if (apiService.checkIfServerError(error)) {
            //
          } else {
            captureException(response)
          }
        })
    },
    calcDay() {
      return '~ 07:35 h'
    },
    calcWeek() {
      return '~ 38:45 h'
    }
  }
}
</script>
