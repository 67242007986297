import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'
import deckService from '@/services/deck.service'
import { useServicesStore } from '@/stores/services'

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useCompanyStore } from '@/stores/company'
import { nextTick, toRaw, watch } from 'vue'
import { $t } from '@/config/i18n'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { setActiveClient } from '../helper/clients'
import { setActiveProject } from '../helper/projects'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} page
 */
function viewServices(panel, arr, page = null) {
  const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
  let pagination = null
  let services = []
  const dims = deckService.getDimensions()
  let column = 0
  let row = 1
  let id = column + row * dims.columns
  panel.setId(
    arr,
    0,
    !activeTimeEntry?.service_id
      ? {
          ...panel.getCancelButton(),
          init: (item, panelArr) => {
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(item),
              watch(
                () => activeTimeEntry.service_id,
                async (newVal) => {
                  await nextTick()
                  if (usePanelStore().view != 'selectService') {
                    return
                  }
                  if (newVal) {
                    usePanelStore().updateView('recording')
                  }
                }
              )
            )
          }
        }
      : panel.getBackButton()
  )
  setActiveClient(panel, arr, 1, activeTimeEntry)

  panel.setId(arr, 3, {
    type: 'text',
    text: $t('panel.service_selection'),
    image: null
  })
  if (activeTimeEntry?.project_id) {
    setActiveProject(panel, arr, 2, activeTimeEntry)
  } else {
    panel.setId(arr, 2, {
      type: 'text',
      text: $t('panel.goto_project_selection'),
      image: null,
      callback: () => {
        return () => {
          usePanelStore().updateView('selectProject', null)
        }
      }
    })
  }
  let needToLoadMore = false

  if (page == null) {
    useServicesStore().fetchActiveCount()
    services = useServicesStore().getLastUsed.filter((item) => item.active)
    row = 1
    column = 0
    id = column + row * dims.columns

    panel.setId(arr, dims.columns - 1, {
      type: 'image',
      text: $t('panel.all_services'),
      image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-down-az' })),
      callback: () => {
        return () => {
          usePanelStore().updatePage(0)
        }
      }
    })
    useServicesStore().fetch(
      {
        page: page + 2,
        limit: dims.columns * dims.rows * 2,
        orders: [
          {
            column: 'name',
            direction: 'asc'
          }
        ]
      },
      false,
      true,
      () => {}
    )
  } else {
    useServicesStore().fetchActiveCount()
    const count = useServicesStore().activeCount
      ? useServicesStore().activeCount
      : useCompanyStore().company.active_services_count
    pagination = panel.getPagination(row, column, dims.rows, dims.columns, count, page)

    services = useServicesStore()
      .services.filter((item) => item.active)
      .toSorted((a, b) => a.name.localeCompare(b.name))
    let tempCount = services.length
    if (tempCount - pagination.end < count - pagination.end) {
      needToLoadMore = true
    }
    services = services.slice(pagination.start, pagination.end)
    let lastChange = toRaw(usePanelStore().lastChange)

    useServicesStore().fetch(
      {
        page: page + 2,
        limit: dims.columns * dims.rows * 2,
        orders: [
          {
            column: 'name',
            direction: 'asc'
          }
        ]
      },
      false,
      true,
      () => {
        if (usePanelStore().lastChange != lastChange || !pagination.next || !needToLoadMore) {
          return
        }
        panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
      }
    )
    panel.setId(arr, dims.columns - 1, {
      type: 'image',
      text: $t('panel.last_ten_services'),
      image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clock-rotate-left' })),
      callback: () => {
        return () => {
          usePanelStore().updatePage(null)
        }
      }
    })
  }

  services.forEach((item) => {
    if (pagination) {
      if (pagination.previous && id == 0 + (dims.rows - 1) * dims.columns) {
        id = id + 1
      }
      if (pagination.next && id == dims.rows * dims.columns - 1) {
        id = id + 1
      }
      if (id >= dims.rows * dims.columns) {
        return
      }
    }
    panel.setId(arr, id, {
      type: 'text',
      text: item.acronym ?? item.name ?? '',
      callback: () => {
        return () => {
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
          if (activeTimeEntry) {
            activeTimeEntry.service_id = item.id
            timelinkStoresService.updateTl(activeTimeEntry)
            useTimeEntryStore().delayUpdateId(activeTimeEntry?.id, 1000, true)
            usePanelStore().updateView('recording', null, true)
          }
        }
      },
      timeoutTime: 10
    })
    id = id + 1
  })
  if (pagination?.previous) {
    panel.setId(arr, 0 + (dims.rows - 1) * dims.columns, panel.getPaginationPreviousButton(page))
  }
  if (pagination?.next) {
    if (page == null || !needToLoadMore) {
      panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
    } else {
      panel.setId(arr, dims.rows * dims.columns - 1, {
        type: 'image',
        text: $t('panel.loading_dots'),
        image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-loader' })),
        color: '#555',
        deckColor: '#aaa'
      })
    }
  }
}

export default viewServices
