import apiService from '@/services/api.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAuthUserStore } from './auth-user'
import notificationService from '@/services/notification.service'
import { re } from 'semver'
import { captureException } from '@sentry/vue'
import datetime from '@/lib/datetime'
import { $t } from '@/config/i18n'
// import favIcon from '@/assets/timelink_bookmark_signet_elfenbeinRGB.png'
import desktopService from '@/services/desktop.service'

export const useNotificationsStore = defineStore('notifications', {
  persist: true,
  // TODO: Add close after
  /**
   *
   * @returns {{
   *   notifications: Array<{
   *     id: string,
   *     type: string,
   *     severity: {
   *       level: number,
   *       type: string
   *     },
   *     data: any[],
   *     read_at: string|Date
   *     created_at: string|Date
   *     updated_at: string|Date
   *     popupHidden: boolean, 
   *     user_id: ?string,
   *     company_id: ?string,
   *     options: ?{
   *       store: boolean,
   *       browser: {
   *         requireInteraction: boolean,
   *         silent: boolean
   *       }
   *     },
   *     actions: ?{
   *       i18n: ?string,
   *       route: { name: string }|string|object
   *     },
   *     until: ?number,
   *     tl: {
   *       isDirty: boolean,
   *       origin: {
   *         id: string,
   *         type: string,
   *         severity: {
   *           level: number,
   *           type: string
   *         },
   *         data: any[],
   *         read_at: string|Date,
   *         created_at: string|Date,
   *         updated_at: string|Date,
   *         user_id:  ?string,
   *         company_id:  ?string,
   *       }
   *     }
   *   }>,
   *  newIds: string[]
   * }}
   */
  state: () => ({
    notifications: [],
    newIds: []
  }),
  getters: {
    getId: (state) => {
      return (id) => {
        return state.notifications.find((item) => item.id === id)
      }
    },
    getUnreadCount: (state) => {
      return state.notifications.reduce((res, item) => (res += item.read_at ? 0 : 1), 0)
    }
  },
  actions: {
    /**
     *
     * @param {{
     * id: string,
     * type: string,
     * severity: {
     *   level: number,
     *   type: string
     * },
     * data: ?object,
     * read_at: string|Date,
     * created_at: string|Date,
     * updated_at: string|Date,
     * user_id:  ?string,
     * company_id:  ?string,
     * options: ?object,
     * actions: ?object,
     * until: ?number
     * }} entry
     */
    addOrUpdate(entry) {
      let foundEntry = this.notifications.find((item) => item.id == entry.id)
      if (foundEntry) {
        foundEntry.tl.origin = entry
        if (!foundEntry.tl.isDirty) {
          if (entry.updated_at != foundEntry.updated_at) {
            Object.entries(entry).forEach((item) => {
              foundEntry[item[0]] = item[1]
            })
          }
        }
      } else {
        // TOOD: Add a handling for notify a user about unread notifications.
        entry.tl = {
          origin: { ...entry },
          isDirty: false
        }
        if (entry.created_at == undefined) {
          entry.created_at = datetime.iso(Date.now())
        }
        entry.popupHidden = false

        notificationService.addActionToNotification(entry)
        notificationService.addOptionsToNotification(entry)
        notificationService.addUntilToNotification(entry)

        let couldSend = false
        // Test TEMP
        if (!entry.read_at) {
          couldSend = notificationService.send(
            $t('notifications.type.' + entry.type, entry.data),
            $t('notifications.text.' + entry.type, entry.data),
            {
              requireInteraction:
                entry.severity.level == 9 || entry?.options?.browser?.requireInteraction,
              // icon: desktopService.isDesktop() ? undefined : favIcon
              // image: entry.action.browser.image
              // badge: entry.action.browser.badge
              // Not usable without a service worker
              // actions: [
              //   {
              //     action: 'what',
              //     title: 'Read me',
              //     icon: entry.action.browser.badge
              //   }
              // ]
            }
          )
        }
        if ((entry?.options?.store ?? true) || (!entry.read_at && !couldSend)) {
          this.notifications.push(entry)
        }
        // Test TEMP
      }
      this.sortEntries()
    },
    removeId(id) {
      this.notifications = this.notifications.filter((item) => item.id != id)
    },
    sortEntries() {
      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        'sortEntries',
        () => {
          this.notifications.sort((a, b) => {
            return b.read_at && !a.read_at
              ? -1
              : a.read_at && !b.read_at
                ? 1
                : Date.parse(b.read_at ? b.read_at : b.created_at) -
                  Date.parse(a.read_at ? a.read_at : a.created_at) // desc
            // return Date.parse(a.created_at) - Date.parse(b.created_at) // asc
          })
        },
        20
      )
    },
    async initFetch() {
      await this.fetchUnread()
      await this.fetchRead()
    },
    async fetchUnread() {
      try {
        await apiService.fetchAll(
          import.meta.env.VITE_API_URL + '/api/v1/notifications/unread',
          null,
          (response) => {
            response.data.forEach((item) => {
              this.addOrUpdate(item)
            })
          }
        )
      } catch (error) {
        //
        console.error(error)
      }
    },
    async fetchRead() {
      try {
        await apiService.fetchAll(
          import.meta.env.VITE_API_URL + '/api/v1/notifications/read',
          null,
          (response) => {
            response.data.forEach((item) => {
              this.addOrUpdate(item)
            })
          }
        )
      } catch (error) {
        //
      }
    },
    // async fetchUpdates() {
    //   try {
    //     await apiService.fetchAll(
    //       import.meta.env.VITE_API_URL + '/api/v1/notifications',
    //       null,
    //       (response) => {
    //         console.log(response)
    //         response.data.forEach((item) => {
    //           this.addOrUpdate(item)
    //         })
    //       }
    //     )
    //   } catch (error) {
    //     //
    //   }
    // },

    /**
     * @param {{
     * type: string,
     *  severity: ?{ level: number, type: 'info'|'success'|'warning'|'error' },
     *  data: Any[],
     *  options: ?{
     *    store: boolean,
     *    browser: {
     *      requireInteraction: boolean,
     *      silent: boolean
     *     }
     *    },
     * actions: ?{
     * i18n: ?string,
     *       route: { name: string }|string|object
     * },
     * until: ?number
     *  }} data
     */
    send(
      data = {
        type: '',
        severity: { level: 0, type: 'info' },
        data: {},
        options: null,
        actions: null,
        until: null
      }
    ) {
      if (data.type == '' || data.type == null) {
        throw new Error('Notification type is required')
      }
      let notification = {
        id: timelinkStoresService.getTempId(),
        severity: data.severity ?? { level: 0, type: 'info' },
        data: data.data ?? {},
        type: data.type,
        read_at: null,
        created_at: datetime.iso(Date.now()),
        updated_at: datetime.iso(Date.now()),
        user_id: useAuthUserStore().user.id,
        options: data.options ?? null,
        actions: data.actions ?? null,
        until: data.until ?? null
      }
      notificationService.addOptionsToNotification(notification)
      notificationService.addActionToNotification(notification)
      notificationService.addUntilToNotification(notification)
      this.addOrUpdate(notification)
    },
    test() {
      this.send({
        type: 'dataIntegration.failed',
        severity: {
          level: 9,
          type: 'error'
        },
        data: [],
        options: {
          store: true,
          browser: {
            requireInteraction: true
          }
        }
      })
      // this.addOrUpdate({
      //   id: timelinkStoresService.getTempId(),
      //   severity: {
      //     level: 9,
      //     type: 'error'
      //   },
      //   data: [],
      //   type: 'dataIntegration.failed',
      //   read_at: null,
      //   created_at: datetime.iso(Date.now()),
      //   updated_at: datetime.iso(Date.now()),
      //   user_id: useAuthUserStore().user.id
      // })
    },
    hidePopup(id) {
      let entry = this.getId(id)
      if(entry) {
        entry.popupHidden = true
      }
    },
    async markAsRead(id, isRetry = false) {
      let entry = this.getId(id)
      if (entry.read_at != null && entry?.tl?.origin?.read_at != null) {
        return
      }
      entry.read_at = datetime.iso(Date.now())
      if (timelinkStoresService.isTempId(entry.id)) {
        this.sortEntries()
        return
      }
      try {
        let response = await apiService.updateEntry(
          'notifications',
          import.meta.env.VITE_API_URL + '/api/v1/notifications/' + entry.id + '/markAsRead',
          null,
          {},
          null,
          null,
          isRetry,
          true
        )
        if (response.success) {
          this.addOrUpdate(response.data)
        }
      } catch (error) {
        console.log(error)
        if (error?.response?.status == 404) {
          this.removeId(entry.id)
        } else {
          captureException(error)
        }
      }
    },
    async markAllAsRead() {
      this.notifications.forEach((item) => {
        if (item.read_at == null && item.severity.type != "error") {
          this.delete(item.id)
        } else if(item.read_At == null) {
          item.read_at = datetime.iso(Date.now())
        }
      })
      try {
        let response = await apiService.post(
          import.meta.env.VITE_API_URL + '/api/v1/notifications/all/markAsRead'
        )
        if (response.success) {
          response.data.forEach((item) => {
            this.addOrUpdate(item)
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    async delete(id, isRetry) {
      let entry = this.getId(id)
      if (timelinkStoresService.isTempId(entry.id)) {
        this.removeId(entry.id)
        return
      }

      try {
        let response = await apiService.deleteEntry(
          'notifications',
          import.meta.env.VITE_API_URL + '/api/v1/notifications',
          entry.id,
          {},
          null,
          null,
          isRetry
        )
        if (response.success) {
          this.removeId(entry.id)
        } else {
          console.log(response)
        }
      } catch (error) {
        console.log(error)
        if (error?.response?.status == 404) {
          this.removeId(entry.id)
        } else {
          captureException(error)
        }
      }
    },
    async deleteAll() {
      this.notifications = this.notifications.filter(
        (item) => !timelinkStoresService.isTempId(item.id)
      )
      let left = []
      this.notifications.forEach((item) => left.push(item.id))
      try {
        let response = await apiService.delete(
          import.meta.env.VITE_API_URL + '/api/v1/notifications',
          'all'
        )
        if (response.success) {
          this.notifications = this.notifications.filter((item) => !left.includes(item.id))
        }
      } catch (error) {
        //
        console.error(error)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}
