<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal">
      <template v-slot:header> {{ $t('conflicts.header') }} </template>
      <div
        class="px-4 py-6 sm:p-8 flex flex-col gap-4"
        v-if="this.conflictCount && this.a && this.b"
      >
        <div class="w-full grid grid-cols-4 grid-rows-2 relative">
          <!-- Box 1 -->
          <div
            class="px-4 border rounded-xl bg-blue-100/50 border-blue-500 -mb-6 grid content-between justify-items-center ml-0 mr-2 justify-self-stretch justify-center"
            :class="{
              'bg-blue-100/50 z-0': visibleResolve != 'a',
              'bg-blue-200 z-10': visibleResolve == 'a',
              '-mb-6': visibleResolve != 'b' && handleable,
              'mb-6': visibleResolve == 'b',
              'row-span-2 place-self-center self-stretch': !handleable,
              'content-start': visibleResolve == null,
              'content-between': visibleResolve != null
            }"
          >
            <div class="justify-self-center flex flex-col justify-center">
              <div class="font-semibold items-center">
                {{ new Date(Date.parse(a.started_at)).toLocaleTimeString() }}
              </div>
              <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                {{ new Date(Date.parse(a.started_at)).toLocaleDateString() }}
              </div>
            </div>
            <div
              class="border rounded-xl bg-red-100/50 border-red-500 grid justify-center justify-items-center ml-2 justify-self-stretch min-h-48 w-full px-8 py-0 mr-8"
              v-show="!handleable"
            >
              <div class="flex flex-col justify-center self-start">
                <div class="font-semibold items-cente">
                  {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
                </div>
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
                </div>
              </div>
              <div class="justify-self-center flex flex-col justify-center self-end">
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(b.ended_at)).toLocaleDateString() }}
                </div>
                <div class="font-semibold items-center">
                  {{ new Date(Date.parse(b.ended_at)).toLocaleTimeString() }}
                </div>
              </div>
            </div>
            <div v-show="visibleResolve == 'a' || !handleable" class="flex flex-col justify-center">
              <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                {{ new Date(Date.parse(a.ended_at)).toLocaleDateString() }}
              </div>
              <div class="font-semibold items-center">
                {{ new Date(Date.parse(a.ended_at)).toLocaleTimeString() }}
              </div>
            </div>
            <div v-show="visibleResolve == 'b'" class="flex flex-col justify-center">
              <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
              </div>
              <div class="font-semibold items-center">
                {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
              </div>
            </div>
          </div>

          <div
            class="col-span-3 grid grid-rows-4 grid-cols-1 items-end pb-2"
            :class="{ ' col-start-2': !handleable }"
          >
            <div class="p-4 grid grid-cols-3 gap-2 row-span-3">
              <div>
                <div class="text-sm text-gray-400">Kunde</div>
                <div>{{ clientsStore.getId(a.client_id)?.name }}</div>
              </div>
              <div>
                <div class="text-sm text-gray-400">Projekt</div>
                <div>{{ projectsStore.getId(a.project_id)?.name }}</div>
              </div>
              <div>
                <div class="text-sm text-gray-400">Dienstleistung</div>
                <div>{{ servicesStore.getId(a.service_id)?.name }}</div>
              </div>
              <div class="sm:col-span-3">
                <div class="text-sm text-gray-400">Beschreibung</div>
                <div>{{ a.description }}</div>
              </div>
            </div>

            <div class="flex flex-row justify-start items-center">
              <div
                class="flex-grow border-t border-dotted max-w-24"
                :class="{ 'border-blue-500': !handleable, 'border-red-500': handleable }"
              ></div>
              <div class="flex-shrink mx-4 relative" v-show="handleable">
                <div class="flex gap-1 items-baseline">
                  <div class="font-semibold">
                    {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
                  </div>
                  <div class="text-gray-600 text-sm" v-show="differentDays">
                    {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
                  </div>
                </div>
                <div class="absolute top-5 -left-12">
                  <button
                    class="text-red-600 hover:text-red-800 px-2"
                    @mouseover="hover_one = true"
                    @mouseleave="hover_one = false"
                    @click="resolve(true)"
                  >
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-turn-down']" size="xl" />
                  </button>
                </div>
                <div class="absolute top-5 -right-12">
                  <button
                    class="text-blue-600 hover:text-blue-800 px-2"
                    @mouseover="hover_two = true"
                    @mouseleave="hover_two = false"
                    @click="resolve(false)"
                  >
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-turn-up']" size="xl" />
                  </button>
                </div>
              </div>
              <div
                class="flex-grow border-t border-dotted"
                :class="{ 'border-blue-500': !handleable, 'border-red-500': handleable }"
              ></div>
            </div>
          </div>

          <div
            class="border rounded-xl bg-red-100/50 border-red-500 grid justify-center justify-items-center ml-2 mr-0 justify-self-stretch"
            v-show="handleable"
            :class="{
              'bg-red-100/50 z-0': visibleResolve != 'b',
              'bg-red-200 z-10 content-between': visibleResolve == 'b',
              '-mt-6': visibleResolve != 'a',
              'mt-6': visibleResolve == 'a',
              'content-end': visibleResolve == null,
              'content-between': visibleResolve != null
            }"
          >
            <div v-show="visibleResolve == 'a'" class="flex flex-col justify-center">
              <div class="font-semibold items-center">
                {{ new Date(Date.parse(a.ended_at)).toLocaleTimeString() }}
              </div>
              <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                {{ new Date(Date.parse(a.ended_at)).toLocaleDateString() }}
              </div>
            </div>
            <div v-show="visibleResolve == 'b'" class="flex flex-col justify-center">
              <div class="font-semibold items-center">
                {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
              </div>
              <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
              </div>
            </div>
            <div class="justify-self-center flex flex-col justify-center">
              <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                {{ new Date(Date.parse(b.ended_at)).toLocaleDateString() }}
              </div>
              <div class="font-semibold items-center">
                {{ new Date(Date.parse(b.ended_at)).toLocaleTimeString() }}
              </div>
            </div>
          </div>

          <div class="col-span-3 grid grid-cols-1 grid-rows-4 items-start pt-2">
            <div class="w-full flex flex-row justify-start items-center">
              <div
                class="flex-grow border-t border-dotted max-w-24"
                :class="{ 'border-red-500': !handleable, 'border-blue-500': handleable }"
              ></div>

              <div class="flex-shrink mx-4" v-show="handleable">
                <div class="flex gap-1 items-baseline">
                  <div class="font-semibold">
                    {{ new Date(Date.parse(a.ended_at)).toLocaleTimeString() }}
                  </div>
                  <div class="text-gray-600 text-sm" v-show="differentDays">
                    {{ new Date(Date.parse(a.ended_at)).toLocaleDateString() }}
                  </div>
                </div>
              </div>
              <div
                class="flex-grow border-t border-dotted"
                :class="{ 'border-red-500': !handleable, 'border-blue-500': handleable }"
              ></div>
            </div>

            <div class="p-4 grid grid-cols-3 gap-2 row-span-3">
              <div>
                <div class="text-sm text-gray-400">Kunde</div>
                <div>{{ clientsStore.getId(b.client_id)?.name }}</div>
              </div>
              <div>
                <div class="text-sm text-gray-400">Projekt</div>
                <div>{{ projectsStore.getId(b.project_id)?.name }}</div>
              </div>
              <div>
                <div class="text-sm text-gray-400">Dienstleistung</div>
                <div>{{ servicesStore.getId(b.service_id)?.name }}</div>
              </div>
              <div class="sm:col-span-3">
                <div class="text-sm text-gray-400">Beschreibung</div>
                <div>{{ b.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!handleable" class="alert-danger">
        Bitte löse diesen Konflikt über den Kalender auf.
      </div>
      <template v-slot:footer>
        <div class="w-full flex justify-between gap-4 items-center">
          <div>
            <BaseButton class="button-mossgray" @click="gotoConflict" type="button" ref="goto_button">{{
              $t('conflicts.goto')
            }}</BaseButton>
          </div>
          <div class="flex justify-end gap-4 items-center">
            <BaseButton class="button-gray" @click="closeModal" type="button">{{
              $t('cancel')
            }}</BaseButton>

            <BaseButton class="button-mossgray" type="submit" @click="save" :disabled="!saveable">
              {{ $t('save') }}
            </BaseButton>
          </div>
        </div>
      </template>
      <LoadingSpinner v-model="isLoading" :overAll="true"></LoadingSpinner>
    </BaseModal>
  </form>
</template>

<script>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import { useAuthUserStore } from '@/stores/auth-user'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useClientsStore } from '@/stores/clients'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import timelinkStoresService from '@/services/timelink-stores.service'
import { captureException } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'

export default {
  components: {
    BaseButton,
    BaseModal,
    LoadingSpinner
  },
  props: ['modelValue', 'project_id'],
  emits: ['switchCalendar', 'update:modelValue'],
  setup() {
    let authUserStore = useAuthUserStore()
    let timeEntryStore = useTimeEntryStore()
    let clientsStore = useClientsStore()
    let projectsStore = useProjectsStore()
    let servicesStore = useServicesStore()
    return { authUserStore, timeEntryStore, clientsStore, projectsStore, servicesStore }
  },
  data() {
    return {
      isLoading: false,
      actual: null,
      a: null,
      b: null,
      hover_one: false,
      hover_two: false,
      win: null,
      type: null
    }
  },
  mounted() {
    this.loadNextConflict()
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    conflictCount() {
      return (this.timeEntryStore.conflicts ?? []).length
    },
    differentDays() {
      return true
      // return (
      //   new Date(Date.parse(this.a.started_at)).getDate() !=
      //   new Date(Date.parse(this.b.ended_at)).getDate()
      // )
    },
    saveable() {
      return (
        this.win != undefined &&
        this.win != null &&
        (this.win.id == this.a.id || this.win.id == this.b.id)
      )
    },
    visibleResolve() {
      if (this.hover_one) {
        return 'a'
      }
      if (this.hover_two) {
        return 'b'
      }

      if (this.saveable) {
        if (this.win.id == this.a.id) {
          return 'a'
        } else {
          return 'b'
        }
      }

      return null
    },
    handleable() {
      return this.type == null || this.type == 1
    }
  },
  watch: {
    showModal(newVal) {
      if (!newVal) {
        this.resetModal()
      } else {
        this.$nextTick(() => {
          this.$refs.goto_button.focus()
        })
        this.loadNextConflict()
      }
    }
  },
  methods: {
    async save() {
      this.isLoading = true
      if (!this.saveable) {
        // TODO: Add error message
        return
      }
      if (this.win.id == this.a.id) {
        this.b.started_at = new Date(Date.parse(this.a.ended_at)).toISOString()
        timelinkStoresService.updateTl(this.b)
        await this.timeEntryStore.updateAsync(this.b.id)
      } else {
        this.a.ended_at = new Date(Date.parse(this.b.started_at)).toISOString()
        timelinkStoresService.updateTl(this.a)
        await this.timeEntryStore.updateAsync(this.a.id)
      }
      this.timeEntryStore.resolvedConflict(this.a.id, this.b.id)
      useAlertsStore().success(this.$t('conflicts.success'))
      this.isLoading = false
      this.loadNextConflict()
    },
    loadNextConflict() {
      if (this.conflictCount == 0) {
        this.closeModal()
        return
      }
      let next = this.timeEntryStore.conflicts.slice(0, 1)[0]
      if (!next) {
        this.closeModal()
        return
      }
      this.actual = next

      let first = this.timeEntryStore.getId(next.a)
      let second = this.timeEntryStore.getId(next.b)
      if (!first || !second) {
        this.closeModal()
        return
      }
      try {
        this.a = Date.parse(first.started_at) < Date.parse(second.started_at) ? first : second
        this.b = this.a.id == first.id ? second : first
        this.type = next.type
        this.win = null
      } catch (error) {
        captureException(error)
        this.closeModal()
        return
      }
    },
    closeModal() {
      this.showModal = false
      this.isLoading = false
    },
    resetModal() {
      this.a = null
      this.b = null
      this.win = null
      this.type = null
    },
    gotoConflict() {
      this.$emit('switchCalendar', new Date(Date.parse(this.b.started_at)))
      setTimeout(() => {
        this.closeModal()
      }, 100)
    },
    resolve(aWon) {
      if (aWon) {
        this.win = this.a
      } else {
        this.win = this.b
      }
    }
  }
}
</script>
