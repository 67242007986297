<template>
  <!-- Panel -->
  <div class="flex flex-col w-full h-full site-background px-6">
    <!-- TODO: move to Settings or somewhere else -->
    <div class="flex w-full justify-end items-center">
      <div class="flex flex-row space-x-4 items-center">
        <div class="font-semibold text-lg">
          <span v-if="decks.length != 0" class="text-green-600"> {{ $t('connected') }} </span>
          <span v-else class="text-mossgray">{{ $t('panel.no_device_found') }}</span>
        </div>
        <BaseButton
          v-if="feature.canUseFeature('panel', authUserStore.user)"
          @click="clickMe"
          class="button-apricot"
          type="button"
        >
          <span> {{ $t('panel.connect_device') }}</span>
          <!-- <span v-if="decks.length == 0"> Gerät verbinden </span> -->
          <!-- <span v-else>Weiteres Gerät verbinden</span> -->
        </BaseButton>
      </div> 
    </div>
    <div class="flex flex-col flex-grow items-center justify-center">
      <div class="bg-black rounded-3xl w-full min-w-[600px] max-w-4xl justify-center justify-items-center content-center place-content-center shadow-xl p-10">
        <div class="grid grid-cols-5 gap-6 gap-x-4 bg-zinc-900 p-8 rounded-2xl">
          <template v-for="item in panelPage" v-bind:key="'t_' + item.id">
            <button
              @click="
                () => {
                  const callback = item.callback(item, panelPage, 'panel')
                  if (callback) {
                    callback()
                  }
                }
              "
              :title="item.text"
              type="button"
              class="aspect-square w-full squircle-clip" 
              :style="{
                'background-color': item.bgColor ?? '#4d4d4d',
                color: item.color ?? '#fff'
              }"
            >
              <div v-if="item.image" class="h-full w-full rounded">
                <img
                  v-if="!item.image.startsWith('<svg')"
                  :src="item.image"
                  class="object-fill w-full h-full flex rounded-lg"
                />
                <div v-else class="object-fit h-full w-full">
                  <div
                    v-html="showDataSvg(item.image, item.color, item.bgColor)"
                    class="flex w-full h-full items-center object-fill"
                  ></div>
                </div>
              </div>
              <div v-else class="p-2">
                <span class="text-xl text-white" v-if="!item.text"></span>
                <span
                  v-else
                  class="font-semibold overflow-clip break-all"
                  :class="{
                    'text-4xl': item.isAcronym,
                    'text-base': item.text.length > 20 && item.text.length <= 30,
                    'text-xl': !item.isAcronym && item.text.length < 20,
                    'text-sm': item.text.length > 30
                  }"
                  >{{ item.text }}</span
                >
              </div>
            </button>
          </template>
        </div>
        <div class="m-auto text-center mt-10 w-1/3">
          <img :src="img" />
        </div>
      </div>
    </div>
  </div>
  <SquirclePath></SquirclePath>
</template>


<script>
import { useClientsStore } from '@/stores/clients'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { useAuthUserStore } from '@/stores/auth-user'
import { usePanelStore } from '@/stores/panel'
import PanelService from '@/services/panel.service'
import deckService from '@/services/deck.service'
import featureFlagsService from '@/services/feature-flags.service'
import timelinkStoresService from '@/services/timelink-stores.service'

import BaseButton from '@/components/general/BaseButton.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'
import img from '@/assets/timelink_primary_logo_moosgrauRGB.png'

export default {
  components: {
    BaseButton,
    SquirclePath
  },
  setup() {
    const feature = featureFlagsService
    const deck = deckService
    const authUserStore = useAuthUserStore()
    return { feature, authUserStore, deck, img }
  },

  data() {
    const clientsStore = useClientsStore()
    const projectsStore = useProjectsStore()
    const servicesStore = useServicesStore()
    return {
      selectedClient: 0,
      tabs: [
        { name: 'Kunden', href: '#', count: 0, current: true },
        { name: 'Projekte', href: '#', count: 0, current: false },
        { name: 'Services', href: '#', count: 0, current: false }
      ],
      collapse: {
        clients: false,
        projects: false,
        services: false
      },
      clients: [],
      projects: [],
      services: [],
      clientsStore,
      projectsStore,
      servicesStore,
      panelPage: [],
      decks: []
    }
  },
  mounted() {
    timelinkStoresService.setOrRenewTimeout(
      'virtual',
      'loadStreamDeckAndPanel',
      () => {
        this.decks = this.deck.getAllStreamDecks()
        this.panelPage = PanelService.loadActualPanelView()
      },
      50
    )
    timelinkStoresService.setOrRenewTimeout(
      'virtual',
      'getAllStreamDecks',
      () => {
        this.decks = this.deck.getAllStreamDecks()
      },
      1000
    )
  },
  computed: {
    getClientById() {
      return (id) => useClientsStore().getId(id)
    }
  },
  methods: {
    updateTab(tab) {
      this.tabs.find((item) => item.current).current = false
      tab.current = true
    },
    async clickMe() {
      if (featureFlagsService.canUseFeature('panel', useAuthUserStore().user)) {
        await deckService.request()
        this.decks = this.deck.getAllStreamDecks()

        const view = usePanelStore().view
        const page = usePanelStore().page
        usePanelStore().$patch({
          view: 'connectedDeck',
          page: null
        })
        timelinkStoresService.setOrRenewTimeout(
          'virtual',
          'patchPanel',
          () => {
            usePanelStore().$patch({
              view: view,
              page: page
            })
          },
          100
        )
      }
    },
    showDataSvg(data, color = null, bgColor = null) {
      if (data.startsWith('<svg')) {
        const div = document.createElement('div')
        div.innerHTML = data
        div.firstChild.setAttribute('fill', '#fff')
        if (color) {
          div.firstChild.setAttribute('fill', color)
        }
        if (bgColor) {
          div.firstChild.setAttribute('style', 'background-color: ' + bgColor + ';')
        }
        data = div.innerHTML
      }
      return data
    }
  }
}
</script>
