<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
    
    <div class="py-3 mb-3 flex w-full items-center">
      <h1
        class="text-2xl font-bold mr-auto"
      >
        {{ $t('data_integration', 1) }}
      </h1>
    </div>

    <div v-if="!dataIntegration?.provider">
      <div class="px-2 pb-4 font-semibold">
        {{ $t('data_integrations.choose_a_provider') }}
      </div>

      <div
        class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-6"
      >
        <div
          class="bg-white ring-1 ring-mossgray-200 sm:rounded-xl cursor-pointer text-3xl p-4 hover:ring hover:ring-mossgray hover:ring-2 flex items-center justify-center"
          @click="setProvider('clockodo')"
        >
          <svg id="do-logo-ref" viewBox="0 0 280 42" class="hidden">
            <symbol id="do-logo-dots-animated">
              <path
                d="M196.515 22.55a5.922 5.922 0 0 0 5.922-5.922 5.922 5.922 0 1 0-5.922 5.922z"
              ></path>
              <path
                d="M196.515 41.76a5.922 5.922 0 0 0 5.922-5.924 5.922 5.922 0 1 0-5.922 5.923z"
              ></path>
            </symbol>
            <symbol id="do-logo-dots">
              <path
                d="M196.515 22.55a5.922 5.922 0 0 0 5.922-5.922 5.922 5.922 0 1 0-5.922 5.922z"
              ></path>
              <path
                d="M196.515 41.76a5.922 5.922 0 0 0 5.922-5.924 5.922 5.922 0 1 0-5.922 5.923z"
              ></path>
            </symbol>
            <symbol id="do-logo-text">
              <path
                d="M23.407 12.366c-2.18-.96-4.702-1.421-7.562-1.421-2.921 0-5.522.5-7.803 1.52-2.3 1.001-4.1 2.482-5.421 4.423-.58.74-1.04 1.62-1.4 2.62A27.09 27.09 0 0 0 .34 22.75 19.03 19.03 0 0 0 0 26.372c0 1.941.24 3.682.72 5.242.48 1.561 1.1 2.962 1.84 4.242 1.321 1.901 3.102 3.342 5.322 4.342 2.24.98 4.762 1.481 7.602 1.481 2.801 0 5.282-.46 7.463-1.4 2.18-.941 3.88-2.302 5.161-4.142 1.26-1.821 1.96-4.022 2.06-6.583h-9.422v.06c-.2 1.36-.74 2.38-1.62 3.061-.9.68-2.061 1.02-3.502 1.02-1 0-1.92-.18-2.7-.52-.8-.34-1.42-.94-1.9-1.74-.501-.58-.841-1.301-1.021-2.162-.18-.86-.26-1.8-.26-2.86 0-.781.06-1.462.18-2.062.12-.6.26-1.18.4-1.72.16-.54.4-1.021.76-1.461.46-.78 1.12-1.36 1.96-1.781.841-.4 1.781-.62 2.821-.62 1.521 0 2.741.36 3.662 1.1.94.74 1.54 1.78 1.8 3.141h9.383c-.12-2.48-.82-4.642-2.1-6.462-1.301-1.841-3.022-3.222-5.202-4.182z"
              ></path>
              <path d="M44.53 0h-9.483v40.759h9.483z"></path>
              <path
                d="M79.004 17.348c-1.4-2.021-3.261-3.582-5.582-4.722-2.32-1.12-5.021-1.681-8.102-1.681-2.92 0-5.562.56-7.942 1.68-2.381 1.121-4.262 2.722-5.622 4.783-.58.74-1.04 1.6-1.4 2.561-.34.98-.64 1.981-.84 3.021-.22 1.041-.32 2.162-.32 3.322 0 1.7.24 3.302.72 4.802.48 1.5 1.1 2.882 1.84 4.162 1.36 2.061 3.241 3.642 5.642 4.742 2.4 1.1 5.041 1.661 7.922 1.661 3.06 0 5.762-.56 8.102-1.66 2.34-1.101 4.201-2.682 5.582-4.743.5-.78.96-1.66 1.36-2.641.4-.98.7-1.981.88-3.001.18-1 .26-2.121.26-3.322 0-1.74-.22-3.361-.66-4.842a17.763 17.763 0 0 0-1.84-4.122zm-7.262 10.805c-.08.6-.2 1.14-.38 1.6-.18.46-.42.961-.72 1.461-.66.86-1.401 1.481-2.241 1.901-.84.4-1.86.62-3.061.62-1.04 0-2.02-.2-2.94-.62-.921-.4-1.681-1.04-2.302-1.9a6.764 6.764 0 0 1-.9-2.242c-.18-.84-.26-1.72-.26-2.64 0-.921.08-1.822.26-2.642.18-.84.48-1.58.9-2.241a6.2 6.2 0 0 1 2.301-1.961c.92-.44 1.9-.66 2.94-.66 1.201 0 2.222.24 3.062.72.84.48 1.58 1.14 2.24 1.96.42.621.74 1.342.94 2.182.2.84.3 1.72.3 2.641-.04.62-.08 1.22-.14 1.82z"
              ></path>
              <path
                d="M107.313 12.366c-2.181-.96-4.701-1.421-7.562-1.421-2.921 0-5.522.5-7.802 1.52-2.301 1.001-4.102 2.482-5.422 4.423-.58.74-1.04 1.62-1.4 2.62a27.23 27.23 0 0 0-.88 3.242c-.24 1.14-.34 2.361-.34 3.642 0 1.941.24 3.682.72 5.242a20.41 20.41 0 0 0 1.84 4.242c1.32 1.901 3.1 3.342 5.322 4.342 2.24.98 4.76 1.481 7.602 1.481 2.801 0 5.281-.46 7.462-1.4 2.18-.941 3.881-2.302 5.161-4.142 1.261-1.821 1.961-4.022 2.061-6.583h-9.443v.06c-.2 1.36-.74 2.38-1.62 3.061-.901.68-2.061 1.02-3.501 1.02-1 0-1.92-.18-2.701-.52-.8-.34-1.42-.94-1.9-1.74-.5-.58-.84-1.301-1.02-2.162-.18-.86-.26-1.8-.26-2.86 0-.781.06-1.462.18-2.062.12-.6.26-1.18.4-1.72.16-.54.4-1.021.76-1.461.46-.78 1.12-1.36 1.96-1.781.84-.4 1.78-.62 2.82-.62 1.521 0 2.742.36 3.662 1.1.94.74 1.54 1.78 1.8 3.141h9.383v-.02c-.12-2.48-.82-4.642-2.101-6.462-1.28-1.841-3.02-3.222-5.181-4.182z"
              ></path>
              <path
                d="M150.222 11.885h-10.483l-12.283 13.267h-1.221l2.201-4.182V0h-9.483v40.759h9.543v-6.563l3.441-3.702 7.222 10.265h10.763L138.199 24.89Z"
              ></path>
              <path
                d="M179.152 17.348c-1.4-2.021-3.261-3.582-5.581-4.722-2.321-1.12-5.022-1.681-8.103-1.681-2.92 0-5.561.56-7.942 1.68-2.381 1.121-4.261 2.722-5.622 4.783-.58.74-1.04 1.6-1.4 2.561-.34.98-.64 1.981-.84 3.021-.22 1.041-.32 2.161-.32 3.322 0 1.7.24 3.302.72 4.802.48 1.5 1.1 2.882 1.84 4.162 1.361 2.061 3.241 3.642 5.642 4.742 2.401 1.1 5.042 1.661 7.922 1.661 3.061 0 5.762-.56 8.103-1.66 2.34-1.101 4.201-2.682 5.581-4.743.5-.78.961-1.66 1.361-2.641.4-.98.7-1.981.88-3.001.18-1 .26-2.121.26-3.322 0-1.74-.22-3.362-.66-4.842a17.769 17.769 0 0 0-1.841-4.122zm-7.282 10.805c-.08.6-.2 1.14-.38 1.6-.18.46-.42.961-.72 1.461-.66.86-1.401 1.481-2.241 1.901-.84.4-1.86.62-3.061.62-1.04 0-2.02-.2-2.94-.62-.921-.4-1.681-1.04-2.301-1.9a6.764 6.764 0 0 1-.9-2.242c-.18-.84-.26-1.72-.26-2.64 0-.921.08-1.822.26-2.642.18-.84.48-1.58.9-2.241a6.2 6.2 0 0 1 2.301-1.961c.92-.44 1.9-.66 2.94-.66 1.201 0 2.221.24 3.061.72.84.48 1.581 1.14 2.241 1.96.42.621.74 1.342.94 2.182.2.84.3 1.72.3 2.641-.02.62-.06 1.22-.14 1.82z"
              ></path>
              <path
                d="m233.526 11.885 2.161 7.684h-1.221c-.54-1.86-1.3-3.422-2.3-4.682-.981-1.26-2.161-2.241-3.521-2.921-1.361-.68-2.901-1.021-4.602-1.021-2.22 0-4.161.42-5.841 1.28-1.681.861-3.081 2.142-4.161 3.842-.661.9-1.181 1.841-1.581 2.862-.38 1-.7 2.14-.94 3.381-.24 1.24-.34 2.581-.34 4.022 0 1.4.12 2.721.34 3.962.24 1.24.54 2.381.94 3.401.38 1.02.92 2.001 1.581 2.942a11.193 11.193 0 0 0 4.161 3.762c1.68.88 3.641 1.3 5.841 1.3 1.701 0 3.241-.34 4.602-1.02 1.36-.68 2.54-1.641 3.521-2.882.98-1.24 1.76-2.821 2.3-4.722h1.221l-2.161 7.684h9.483V0h-9.483zm-.1 16.388c-.12.6-.28 1.16-.46 1.66-.2.5-.44.981-.76 1.401a5.339 5.339 0 0 1-2.061 1.841c-.84.4-1.8.62-2.881.62-1.12 0-2.12-.2-3.001-.58-.88-.38-1.58-1-2.12-1.86-.42-.58-.76-1.301-.98-2.182-.241-.88-.341-1.82-.341-2.82 0-.661.06-1.302.201-1.962.14-.64.3-1.22.5-1.72s.4-.961.64-1.341c.54-.82 1.26-1.42 2.12-1.841.881-.4 1.881-.62 3.001-.62 1.121 0 2.101.2 2.921.62.82.4 1.501 1.02 2.041 1.84.42.581.76 1.301 1.02 2.162.26.86.38 1.8.38 2.861-.06.66-.12 1.32-.22 1.921z"
              ></path>
              <path
                d="M279.321 21.49a17.546 17.546 0 0 0-1.841-4.142c-1.4-2.021-3.261-3.582-5.581-4.722-2.321-1.12-5.022-1.681-8.103-1.681-2.92 0-5.561.56-7.942 1.68-2.38 1.121-4.261 2.722-5.621 4.783-.581.74-1.041 1.6-1.401 2.561-.34.98-.64 1.981-.84 3.021-.22 1.041-.32 2.161-.32 3.322 0 1.7.24 3.302.72 4.802.48 1.5 1.1 2.882 1.841 4.162 1.36 2.061 3.241 3.642 5.641 4.742 2.401 1.1 5.042 1.661 7.922 1.661 3.061 0 5.762-.56 8.103-1.66 2.34-1.101 4.201-2.682 5.581-4.743.5-.78.961-1.66 1.361-2.641.4-.98.7-1.981.88-3.001.18-1 .26-2.121.26-3.322.02-1.74-.2-3.342-.66-4.822zm-9.103 6.663c-.08.6-.2 1.14-.38 1.6-.18.46-.42.961-.72 1.461-.66.86-1.401 1.481-2.241 1.901-.84.4-1.86.62-3.061.62-1.04 0-2.02-.2-2.94-.62-.921-.4-1.681-1.04-2.301-1.9a6.764 6.764 0 0 1-.9-2.242c-.18-.84-.26-1.72-.26-2.64 0-.921.08-1.822.26-2.642.18-.84.48-1.58.9-2.241a6.2 6.2 0 0 1 2.301-1.961c.92-.44 1.9-.66 2.94-.66 1.201 0 2.221.24 3.061.72.84.48 1.581 1.14 2.241 1.96.42.621.74 1.342.94 2.182.2.84.3 1.72.3 2.641-.02.62-.06 1.22-.14 1.82z"
              ></path>
            </symbol>
          </svg>
          <svg class="do-logo-animated" height="22" viewBox="0 0 280 42" width="140">
            <use fill="#3657f7" xlink:href="#do-logo-text"></use>
            <use class="do-logo-dots" fill="#3657f7" xlink:href="#do-logo-dots"></use>
            <use
              class="do-logo-dots-animated"
              fill="#3657f7"
              xlink:href="#do-logo-dots-animated"
            ></use>
          </svg>
        </div>

        <div
          class="bg-white ring-1 ring-mossgray-200 sm:rounded-xl cursor-pointer text-3xl p-4 hover:ring hover:ring-mossgray hover:ring-2 flex items-center justify-center"
          @click="setProvider('awork')"
        >
          <img
            src="https://cdn.prod.website-files.com/6418f5bfe5bc0a3438109c1d/641ccf4e9454867f1de9006f_logo.svg"
            class=""
          />
        </div>
      </div>
    </div>

    <div class="space-y-8 divide-y divide-mossgray-200 pb-16" v-else>
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-4 py-4">
          <div v-if="dataIntegration.provider == 'clockodo'">
            <svg id="do-logo-ref" viewBox="0 0 280 42" class="hidden">
              <symbol id="do-logo-dots-animated">
                <path
                  d="M196.515 22.55a5.922 5.922 0 0 0 5.922-5.922 5.922 5.922 0 1 0-5.922 5.922z"
                ></path>
                <path
                  d="M196.515 41.76a5.922 5.922 0 0 0 5.922-5.924 5.922 5.922 0 1 0-5.922 5.923z"
                ></path>
              </symbol>
              <symbol id="do-logo-dots">
                <path
                  d="M196.515 22.55a5.922 5.922 0 0 0 5.922-5.922 5.922 5.922 0 1 0-5.922 5.922z"
                ></path>
                <path
                  d="M196.515 41.76a5.922 5.922 0 0 0 5.922-5.924 5.922 5.922 0 1 0-5.922 5.923z"
                ></path>
              </symbol>
              <symbol id="do-logo-text">
                <path
                  d="M23.407 12.366c-2.18-.96-4.702-1.421-7.562-1.421-2.921 0-5.522.5-7.803 1.52-2.3 1.001-4.1 2.482-5.421 4.423-.58.74-1.04 1.62-1.4 2.62A27.09 27.09 0 0 0 .34 22.75 19.03 19.03 0 0 0 0 26.372c0 1.941.24 3.682.72 5.242.48 1.561 1.1 2.962 1.84 4.242 1.321 1.901 3.102 3.342 5.322 4.342 2.24.98 4.762 1.481 7.602 1.481 2.801 0 5.282-.46 7.463-1.4 2.18-.941 3.88-2.302 5.161-4.142 1.26-1.821 1.96-4.022 2.06-6.583h-9.422v.06c-.2 1.36-.74 2.38-1.62 3.061-.9.68-2.061 1.02-3.502 1.02-1 0-1.92-.18-2.7-.52-.8-.34-1.42-.94-1.9-1.74-.501-.58-.841-1.301-1.021-2.162-.18-.86-.26-1.8-.26-2.86 0-.781.06-1.462.18-2.062.12-.6.26-1.18.4-1.72.16-.54.4-1.021.76-1.461.46-.78 1.12-1.36 1.96-1.781.841-.4 1.781-.62 2.821-.62 1.521 0 2.741.36 3.662 1.1.94.74 1.54 1.78 1.8 3.141h9.383c-.12-2.48-.82-4.642-2.1-6.462-1.301-1.841-3.022-3.222-5.202-4.182z"
                ></path>
                <path d="M44.53 0h-9.483v40.759h9.483z"></path>
                <path
                  d="M79.004 17.348c-1.4-2.021-3.261-3.582-5.582-4.722-2.32-1.12-5.021-1.681-8.102-1.681-2.92 0-5.562.56-7.942 1.68-2.381 1.121-4.262 2.722-5.622 4.783-.58.74-1.04 1.6-1.4 2.561-.34.98-.64 1.981-.84 3.021-.22 1.041-.32 2.162-.32 3.322 0 1.7.24 3.302.72 4.802.48 1.5 1.1 2.882 1.84 4.162 1.36 2.061 3.241 3.642 5.642 4.742 2.4 1.1 5.041 1.661 7.922 1.661 3.06 0 5.762-.56 8.102-1.66 2.34-1.101 4.201-2.682 5.582-4.743.5-.78.96-1.66 1.36-2.641.4-.98.7-1.981.88-3.001.18-1 .26-2.121.26-3.322 0-1.74-.22-3.361-.66-4.842a17.763 17.763 0 0 0-1.84-4.122zm-7.262 10.805c-.08.6-.2 1.14-.38 1.6-.18.46-.42.961-.72 1.461-.66.86-1.401 1.481-2.241 1.901-.84.4-1.86.62-3.061.62-1.04 0-2.02-.2-2.94-.62-.921-.4-1.681-1.04-2.302-1.9a6.764 6.764 0 0 1-.9-2.242c-.18-.84-.26-1.72-.26-2.64 0-.921.08-1.822.26-2.642.18-.84.48-1.58.9-2.241a6.2 6.2 0 0 1 2.301-1.961c.92-.44 1.9-.66 2.94-.66 1.201 0 2.222.24 3.062.72.84.48 1.58 1.14 2.24 1.96.42.621.74 1.342.94 2.182.2.84.3 1.72.3 2.641-.04.62-.08 1.22-.14 1.82z"
                ></path>
                <path
                  d="M107.313 12.366c-2.181-.96-4.701-1.421-7.562-1.421-2.921 0-5.522.5-7.802 1.52-2.301 1.001-4.102 2.482-5.422 4.423-.58.74-1.04 1.62-1.4 2.62a27.23 27.23 0 0 0-.88 3.242c-.24 1.14-.34 2.361-.34 3.642 0 1.941.24 3.682.72 5.242a20.41 20.41 0 0 0 1.84 4.242c1.32 1.901 3.1 3.342 5.322 4.342 2.24.98 4.76 1.481 7.602 1.481 2.801 0 5.281-.46 7.462-1.4 2.18-.941 3.881-2.302 5.161-4.142 1.261-1.821 1.961-4.022 2.061-6.583h-9.443v.06c-.2 1.36-.74 2.38-1.62 3.061-.901.68-2.061 1.02-3.501 1.02-1 0-1.92-.18-2.701-.52-.8-.34-1.42-.94-1.9-1.74-.5-.58-.84-1.301-1.02-2.162-.18-.86-.26-1.8-.26-2.86 0-.781.06-1.462.18-2.062.12-.6.26-1.18.4-1.72.16-.54.4-1.021.76-1.461.46-.78 1.12-1.36 1.96-1.781.84-.4 1.78-.62 2.82-.62 1.521 0 2.742.36 3.662 1.1.94.74 1.54 1.78 1.8 3.141h9.383v-.02c-.12-2.48-.82-4.642-2.101-6.462-1.28-1.841-3.02-3.222-5.181-4.182z"
                ></path>
                <path
                  d="M150.222 11.885h-10.483l-12.283 13.267h-1.221l2.201-4.182V0h-9.483v40.759h9.543v-6.563l3.441-3.702 7.222 10.265h10.763L138.199 24.89Z"
                ></path>
                <path
                  d="M179.152 17.348c-1.4-2.021-3.261-3.582-5.581-4.722-2.321-1.12-5.022-1.681-8.103-1.681-2.92 0-5.561.56-7.942 1.68-2.381 1.121-4.261 2.722-5.622 4.783-.58.74-1.04 1.6-1.4 2.561-.34.98-.64 1.981-.84 3.021-.22 1.041-.32 2.161-.32 3.322 0 1.7.24 3.302.72 4.802.48 1.5 1.1 2.882 1.84 4.162 1.361 2.061 3.241 3.642 5.642 4.742 2.401 1.1 5.042 1.661 7.922 1.661 3.061 0 5.762-.56 8.103-1.66 2.34-1.101 4.201-2.682 5.581-4.743.5-.78.961-1.66 1.361-2.641.4-.98.7-1.981.88-3.001.18-1 .26-2.121.26-3.322 0-1.74-.22-3.362-.66-4.842a17.769 17.769 0 0 0-1.841-4.122zm-7.282 10.805c-.08.6-.2 1.14-.38 1.6-.18.46-.42.961-.72 1.461-.66.86-1.401 1.481-2.241 1.901-.84.4-1.86.62-3.061.62-1.04 0-2.02-.2-2.94-.62-.921-.4-1.681-1.04-2.301-1.9a6.764 6.764 0 0 1-.9-2.242c-.18-.84-.26-1.72-.26-2.64 0-.921.08-1.822.26-2.642.18-.84.48-1.58.9-2.241a6.2 6.2 0 0 1 2.301-1.961c.92-.44 1.9-.66 2.94-.66 1.201 0 2.221.24 3.061.72.84.48 1.581 1.14 2.241 1.96.42.621.74 1.342.94 2.182.2.84.3 1.72.3 2.641-.02.62-.06 1.22-.14 1.82z"
                ></path>
                <path
                  d="m233.526 11.885 2.161 7.684h-1.221c-.54-1.86-1.3-3.422-2.3-4.682-.981-1.26-2.161-2.241-3.521-2.921-1.361-.68-2.901-1.021-4.602-1.021-2.22 0-4.161.42-5.841 1.28-1.681.861-3.081 2.142-4.161 3.842-.661.9-1.181 1.841-1.581 2.862-.38 1-.7 2.14-.94 3.381-.24 1.24-.34 2.581-.34 4.022 0 1.4.12 2.721.34 3.962.24 1.24.54 2.381.94 3.401.38 1.02.92 2.001 1.581 2.942a11.193 11.193 0 0 0 4.161 3.762c1.68.88 3.641 1.3 5.841 1.3 1.701 0 3.241-.34 4.602-1.02 1.36-.68 2.54-1.641 3.521-2.882.98-1.24 1.76-2.821 2.3-4.722h1.221l-2.161 7.684h9.483V0h-9.483zm-.1 16.388c-.12.6-.28 1.16-.46 1.66-.2.5-.44.981-.76 1.401a5.339 5.339 0 0 1-2.061 1.841c-.84.4-1.8.62-2.881.62-1.12 0-2.12-.2-3.001-.58-.88-.38-1.58-1-2.12-1.86-.42-.58-.76-1.301-.98-2.182-.241-.88-.341-1.82-.341-2.82 0-.661.06-1.302.201-1.962.14-.64.3-1.22.5-1.72s.4-.961.64-1.341c.54-.82 1.26-1.42 2.12-1.841.881-.4 1.881-.62 3.001-.62 1.121 0 2.101.2 2.921.62.82.4 1.501 1.02 2.041 1.84.42.581.76 1.301 1.02 2.162.26.86.38 1.8.38 2.861-.06.66-.12 1.32-.22 1.921z"
                ></path>
                <path
                  d="M279.321 21.49a17.546 17.546 0 0 0-1.841-4.142c-1.4-2.021-3.261-3.582-5.581-4.722-2.321-1.12-5.022-1.681-8.103-1.681-2.92 0-5.561.56-7.942 1.68-2.38 1.121-4.261 2.722-5.621 4.783-.581.74-1.041 1.6-1.401 2.561-.34.98-.64 1.981-.84 3.021-.22 1.041-.32 2.161-.32 3.322 0 1.7.24 3.302.72 4.802.48 1.5 1.1 2.882 1.841 4.162 1.36 2.061 3.241 3.642 5.641 4.742 2.401 1.1 5.042 1.661 7.922 1.661 3.061 0 5.762-.56 8.103-1.66 2.34-1.101 4.201-2.682 5.581-4.743.5-.78.961-1.66 1.361-2.641.4-.98.7-1.981.88-3.001.18-1 .26-2.121.26-3.322.02-1.74-.2-3.342-.66-4.822zm-9.103 6.663c-.08.6-.2 1.14-.38 1.6-.18.46-.42.961-.72 1.461-.66.86-1.401 1.481-2.241 1.901-.84.4-1.86.62-3.061.62-1.04 0-2.02-.2-2.94-.62-.921-.4-1.681-1.04-2.301-1.9a6.764 6.764 0 0 1-.9-2.242c-.18-.84-.26-1.72-.26-2.64 0-.921.08-1.822.26-2.642.18-.84.48-1.58.9-2.241a6.2 6.2 0 0 1 2.301-1.961c.92-.44 1.9-.66 2.94-.66 1.201 0 2.221.24 3.061.72.84.48 1.581 1.14 2.241 1.96.42.621.74 1.342.94 2.182.2.84.3 1.72.3 2.641-.02.62-.06 1.22-.14 1.82z"
                ></path>
              </symbol>
            </svg>
            <svg class="do-logo-animated" height="22" viewBox="0 0 280 42" width="140">
              <use fill="#3657f7" xlink:href="#do-logo-text"></use>
              <use class="do-logo-dots" fill="#3657f7" xlink:href="#do-logo-dots"></use>
              <use
                class="do-logo-dots-animated"
                fill="#3657f7"
                xlink:href="#do-logo-dots-animated"
              ></use>
            </svg>
          </div>
          <div v-if="dataIntegration.provider == 'awork'">
            <img
              src="https://cdn.prod.website-files.com/6418f5bfe5bc0a3438109c1d/641ccf4e9454867f1de9006f_logo.svg"
              class=""
            />
          </div>
          <!-- <h2
            class="text-base font-semibold leading-7 text-timelink-blue-800 underline underline-offset-4"
          >
            {{ $t('data_integrations.part_one_header') }}
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            {{ $t('data_integrations.part_one_description') }}
          </p> -->
        </div>

        <form
          class="bg-white ring-1 ring-mossgray-200 sm:rounded-xl md:col-span-2"
          ref="form"
          @submit.prevent="save"
        >
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-start-1 sm:col-span-3">
                <label for="token" class="block text-sm font-medium leading-6">{{
                  $t('token')
                }}</label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="token"
                    id="token"
                    v-model="dataIntegration.token"
                    class="input w-full"
                  />
                </div>
                <div class="text-sm text-red-500" v-if="this.errors.token">
                  <template v-for="message in this.errors.token" :key="message">
                    <div>
                      {{ message }}
                    </div>
                  </template>
                </div>
              </div>

              <div class="sm:col-span-3" v-if="dataIntegration.provider == 'clockodo'">
                <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('username')
                }}</label>
                <div class="mt-2">
                  <input
                    id="username"
                    name="username"
                    type="username"
                    autocomplete="username"
                    v-model="dataIntegration.username"
                    class="input w-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8"
            :class="{ 'justify-end': !setupWizard, 'justify-between': setupWizard }"
          >
            <div v-if="setupWizard">
              <BaseButton class="button button-mossgray" @click="backToInit">{{
                $t('cancel')
              }}</BaseButton>
            </div>
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <BaseButton type="button" class="button-mossgray"
              ><span v-if="dataIntegration?.exists"> {{ $t('save') }} </span
              ><span v-else>{{ $t('add') }}</span></BaseButton
            >
          </div>
        </form>
      </div>
      <div
        class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-6"
        v-if="dataIntegration?.exists ?? false"
      >
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7">
            {{ $t('data_integrations.part_two_header') }}
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            <!-- {{ $t('data_integrations.part_one_description') }} -->
          </p>
        </div>

        <div class="bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <span class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t('data_integrations.last_started') }}
                </span>
                <div class="mt-2">
                  <span class="font-bold">
                    {{
                      dataIntegration.last_queued
                        ? new Date(Date.parse(dataIntegration.last_queued)).toLocaleString()
                        : '-'
                    }}
                  </span>
                </div>
              </div>
              <div class="sm:col-span-3">
                <span class="block text-sm font-medium leading-6">
                  {{ $t('data_integrations.last_sucessful_execution') }}
                </span>
                <div class="mt-2">
                  <span class="font-bold">
                    {{
                      dataIntegration.last_execution
                        ? new Date(Date.parse(dataIntegration.last_execution)).toLocaleString()
                        : '-'
                    }}
                  </span>
                </div>
              </div>
              <div class="sm:col-span-3">
                <span class="block text-sm font-medium leading-6">
                  {{ $t('data_integrations.next_planned_execution') }}
                </span>
                <div class="mt-2">
                  <span class="font-bold">
                    {{
                      dataIntegration.next_execution
                        ? new Date(Date.parse(dataIntegration.next_execution)).toLocaleString()
                        : '-'
                    }}
                  </span>
                </div>
              </div>
              <div class="sm:col-span-3 flex items-end">
                <BaseButton
                  :disabled="!dataIntegration.trigger_url"
                  class="button-mossgray"
                  type="button"
                  @click="startSync"
                >
                  {{ $t('data_integrations.start_synchronization') }}
                </BaseButton>
              </div>

              <div class="sm:col-span-6 flex items-end" v-if="sync.status != null">
                <div
                  class="text-green-700 border-l-4 min-w-96 border-green-700 bg-green-100 p-2 px-4 font-semibold"
                  v-if="sync.status"
                >
                  <span>{{ $t('data_integrations.synchronization_started') }}</span>
                </div>
                <div
                  class="text-red-700 border-l-4 min-w-96 border-red-700 bg-red-100 p-2 px-4 font-semibold"
                  v-else
                >
                  <span v-text="sync.message"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingSpinner
      v-model="isLoading"
      :fullpage="true"
      :overAll="false"
      class="fixed top-0 left-0"
    />
  </TemplateComponent>
</template>

<script>
import apiService from '@/services/api.service'
import { useAuthUserStore } from '@/stores/auth-user'
import featureFlagsService from '@/services/feature-flags.service'
import BaseButton from '@/components/general/BaseButton.vue'
import { useCompanyStore } from '@/stores/company'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'

export default {
  components: {
    BaseButton,
    LoadingSpinner,
    TemplateComponent
  },
  setup() {
    const authUserStore = useAuthUserStore()
    const featureFlags = featureFlagsService
    const companyStore = useCompanyStore()
    return {
      authUserStore,
      featureFlags,
      companyStore
    }
  },
  data() {
    return {
      isLoading: false,
      setupWizard: false,
      errors: {},
      dataIntegration: {
        provider: null,
        token: null,
        username: '',
        last_execution: null,
        last_queued: null,
        next_execution: null,
        trigger_url: null,
        cron: null,
        exists: false
      },
      sync: {
        status: null,
        message: null
      }
    }
  },
  mounted() {
    this.fetch()
    this.$echo
      .private('dataIntegration.' + this.companyStore.company.id)
      .listen('DataIntegrationUpdated', (payload) => {
        this.dataIntegration = { ...payload.data }
        if (!this.dataIntegration.exists) {
          this.setupWizard = true
        }
      })
      .listen('.dataIntegration.updated', (payload) => {
        this.dataIntegration = { ...payload.data }
        if (!this.dataIntegration.exists) {
          this.setupWizard = true
        }
      })
  },
  beforeUnmount() {
    this.$echo.leave('dataIntegration.' + this.companyStore.company.id)
  },
  computed: {},
  watch: {
    'dataIntegration.provider'(newVal, oldVal) {
      // console.log(newVal)
      if (this.isLoading) {
        return
      }
      if (newVal && !oldVal) {
        return
      } else {
        this.dataIntegration.token = null
        this.dataIntegration.username = null
        this.dataIntegration.exists = false
      }
    }
  },
  methods: {
    async fetch() {
      this.isLoading = true
      try {
        let response = await apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/dataIntegration'
        )
        this.dataIntegration = { ...response.data }
        this.setupWizard = !this.dataIntegration.exists
      } catch (error) {
        useAlertsStore().error(this.$t('errors.ups'))
        captureException(error)
      }
      this.isLoading = false
    },
    async save() {
      this.isLoading = true
      try {
        let response = await apiService.update(
          import.meta.env.VITE_API_URL + '/api/v1/dataIntegration',
          null,
          this.dataIntegration,
          {},
          (response) => {
            // console.log(response)
          },
          (response) => {}
        )
        if (response.success) {
          this.dataIntegration = { ...response.data }
          this.setupWizard = false
          useAlertsStore().successfullySaved()
        }
        this.errors = {}
      } catch (error) {
        if (error.response.status == 422) {
          this.errors = apiService.convertValidationErrors(error)
        } else if (apiService.checkIfServerError()) {
          //
        } else {
          useAlertsStore().error(this.$t('errors.ups_save'))
          captureException(error)
        }
      }
      this.isLoading = false
    },
    async setProvider(provider) {
      this.dataIntegration.provider = provider
    },
    async startSync() {
      try {
        let response = await axios.get(this.dataIntegration.trigger_url)
        // console.log(response)
        this.sync.status = response.data.success
        if (!this.sync.status) {
          this.sync.message = response.data.message
        } else {
          this.sync.message = null
        }
      } catch (error) {
        this.sync.status = false
        if (error?.response?.status == 429) {
          useAlertsStore().error(this.$t('data_integrations.rateLimit_reached'))
        } else if (apiService.checkIfServerError(error)) {
          //
        } else {
          useAlertsStore().error(this.$t('errors.general'))
          captureException(error)
        }
        // console.log(error)
      }
    },
    backToInit() {
      this.dataIntegration.provider = null
      this.setupWizard = true
    }
  }
}
</script>
