<template>
  <div class="rounded-md bg-mossgray-100 p-4 mb-5">
      <div class="flex items-center">
        <div class="flex-shrink-0 ">
          <font-awesome-icon :icon="['fa-kit', 'tl-info']" size="lg" />
        </div>
        <div class="ml-3">
          <template v-if="$slots.title">
            <h3 class="text-base font-bold"><slot name="title"></slot></h3>
          </template>

          <p class="text-sm font-medium">
              <slot></slot>
          </p>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        props: {
          title: {
            type: String,
            default: null
          }
        }
    }
</script>
