<template>
  <div class="flex flex-col px-6 pt-6 site-background min-h-screen h-full basis-full">
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>

    <div class="mb-5 flex w-full justify-between items-center">
      <h1 class="text-2xl font-bold">
        {{ $t('edit_service') }}
      </h1>
    </div>
    <!-- TODO: Rework -->
    <div class="space-y-8 divide-y divide-gray-900/10 pb-16">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7">
            {{ $t('service_edit_header') }}
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            <!-- {{ $t('service_edit_description') }} -->
          </p>
        </div>

        <form
          class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          @submit.prevent="save"
        >
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('name')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="text"
                    name="name"
                    id="name"
                    autocomplete="name"
                    v-model="service.name"
                    class="input w-full"
                  />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="info" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('info')
                }}</label>
                <div class="mt-2">
                  <textarea
                    :disabled="!canEdit"
                    type="text"
                    name="info"
                    id="info"
                    autocomplete="Off"
                    v-model="service.info"
                    class="textarea w-full"
                  ></textarea>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('active')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="checkbox"
                    name="active"
                    id="active"
                    autocomplete="Off"
                    v-model="service.active"
                    class="checkbox"
                  />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="color" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('color')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="color"
                    name="color"
                    id="color"
                    autocomplete="Off"
                    v-model="service.color"
                    class="input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-6"
            :class="{
              'justify-end': companyStore.company.pull_provider,
              'justify-between': !companyStore.company.pull_provider
            }"
          >
            <BaseButton
              class="button-red"
              type="button"
              @click="showDeleteModal = true"
              v-if="!companyStore.company.pull_provider"
              :disabled="!canEdit"
            >
              {{ $t('delete') }}
            </BaseButton>

            <button
              v-if="!companyStore.company.pull_provider"
              :disabled="!canEdit"
              type="submit"
              class="button-mossgray"
            >
              {{ $t('save') }}
            </button>
            <div v-else>
              {{ $t('managed_through') }}

              <span class="font-medium">{{ companyStore.company.pull_provider }}</span
              >.
            </div>
          </div>
        </form>
      </div>
    </div>
    <BaseModal v-model="showDeleteModal" @close-modal="showDeleteModal = false">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.service') }}
        </span>
      </div>

      <template #footer>
        <div class="w-full flex justify-end gap-4">
          <BaseButton
            class="button-gray"
            type="button"
            @click="showDeleteModal = false"
            ref="cancelDeleteModalButton"
          >
            {{ $t('cancel') }}</BaseButton
          >
          <BaseButton
            class="button-red"
            :disabled="!timer || timer.seconds > 0"
            @click="this.delete"
            ><span class="w-6" v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span
            >{{ $t('delete') }}</BaseButton
          >
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import LoadingSpinner from '../LoadingSpinner.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useServicesStore } from '@/stores/services'
import BaseModal from '@/components/modals/BaseModal.vue'
import { useTimer } from 'vue-timer-hook'
import { captureException } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
export default {
  components: {
    BaseButton,
    BaseModal,
    LoadingSpinner
  },
  setup() {
    const timelinkService = timelinkStoresService
    const companyStore = useCompanyStore()
    return { timelinkService, companyStore }
  },
  data() {
    return {
      canEdit: false,
      service: {
        name: null,
        info: null
      },
      isLoading: true,
      services: [],
      saveComplete: null,
      showDeleteModal: false,
      timer: null
    }
  },
  mounted() {
    // this.canEdit = useAuthUserStore().user.admin >= 5 && !useCompanyStore().company.pull_provider
    this.canEdit = useAuthUserStore().user.admin >= 9 && !useCompanyStore().company.pull_provider
    this.fetch()
  },
  watch: {
    showDeleteModal(newVal) {
      if (newVal) {
        this.timer = this.timer = useTimer(new Date(Date.now() + 2 * 1000), true)
        this.$nextTick(() => {
          this.$refs.cancelDeleteModalButton?.focus()
        })
      }
    }
  },
  computed: {},
  methods: {
    async fetch() {
      this.isLoading = true
      try {
        let data = await apiService.fetchId(
          import.meta.env.VITE_API_URL + '/api/v1/services',
          this.$route.params.service_id
        )
        this.service = data.data
      } catch (error) {
        if (error?.response?.status == 404) {
          this.$router.push({ name: 'ServiceList' })
          useAlertsStore().error(this.$t('errors.service.not_found'))
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
          captureException(error)
        }
      }
      this.isLoading = false
    },
    async save() {
      if (useCompanyStore().company.pull_provider || useAuthUserStore().user.admin < 5) {
        useAlertsStore().error(this.$t('errors.no_permissions'))
        return
      }

      this.isLoading = true
      try {
        let response = await apiService.update(
          import.meta.env.VITE_API_URL + '/api/v1/services',
          this.$route.params.service_id,
          {
            ...this.service
          }
        )
        if (response.success) {
          useServicesStore().updateIfExists(response.data)
          useAlertsStore().successfullySaved()
        } else {
          console.error(response)
          useAlertsStore().error(this.$t('errors.ups_save'))
        }
      } catch (error) {
        if (
          error?.response?.status == 403 &&
          error.response.data.message == 'You are not subscribed!'
        ) {
          // TODO: Add better error handling!
          useAlertsStore().error(this.$t('errors.no_subscription'))
        } else if (apiService.checkIfServerError(error)) {
          //
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
          captureException(error)
          console.log(error)
        }
      }
      this.isLoading = false
    },

    delete() {
      this.isLoading = true
      apiService.delete(
        import.meta.env.VITE_API_URL + '/api/v1/services',
        this.$route.params.project_id,
        {},
        () => {
          this.$router.push({
            name: 'ServicesList'
          })
        },
        (error) => {
          if (
            error?.response?.status == 403 &&
            error.response.data.message == 'You are not subscribed!'
          ) {
            // TODO: Add better error handling!
            useAlertsStore().error(this.$t('errors.no_subscription'))
          } else {
            useAlertsStore().error(this.$t('errors.ups'))
            captureException(error)
          }
          this.isLoading = false
        }
      )
    }
  }
}
</script>
