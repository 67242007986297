<template>
  <div class="flex flex-col py-3 px-6 site-background min-h-screen h-full basis-full">
    <div class="w-full flex justify-between items-center mb-5">
      <h1 class="text-2xl font-bold">{{ $t('my_account') }}</h1>

      <BaseButton class="button-mossgray flex space-x-4" @click="logout" type="button">
        <font-awesome-icon :icon="['fa-kit', 'tl-log-out']" />
        <div class="text-center">
          {{ $t('menu.logout') }}
        </div>
      </BaseButton>
    </div>

    <div class="space-y-8 divide-y divide-mossgray-200 pb-16">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('personal_data') }}
          </h2>
          <p class="mt-1 text-sm leading-6">
            {{ $t('personal_description') }}
          </p>
        </div>

        <form
          class="bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2"
          ref="form"
          @submit.prevent="save"
        >
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('first_name')
                }}</label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    :disabled="companyStore?.company?.pull_provider"
                    v-model="user.first_name"
                    class="input w-full"
                  />
                </div>
                <div class="text-sm text-red-500" v-if="this.errors.first_name">
                  <template v-for="message in this.errors.first_name" :key="message">
                    <div>
                      {{ message }}
                    </div>
                  </template>
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('last_name')
                }}</label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    :disabled="companyStore?.company?.pull_provider"
                    autocomplete="family-name"
                    v-model="user.last_name"
                    class="input w-full"
                  />
                </div>
                <div class="text-sm text-red-500" v-if="this.errors.last_name">
                  <template v-for="message in this.errors.last_name" :key="message">
                    <div>
                      {{ message }}
                    </div>
                  </template>
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('email')
                }}</label>
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    disabled
                    v-bind:value="user.email"
                    class="input w-full"
                  />
                </div>
              </div>
              <div
                class="sm:col-span-3"
                v-if="featureFlags.canUseFeature('language', authUserStore.user)"
              >
                <label for="language" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('language')
                }}</label>
                <div class="mt-2">
                  <select
                    id="language"
                    name="language"
                    autocomplete="language"
                    v-model="user.language"
                    class="input w-full"
                  >
                    <option value="de">{{ $t('languages.de') }}</option>
                    <option value="en">{{ $t('languages.en') }}</option>
                  </select>
                </div>
              </div>

              <div
                class="sm:col-span-3"
                v-if="featureFlags.canUseFeature('language', authUserStore.user)"
              >
                <label for="timezone" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('timezone')
                }}</label>
                <Combobox
                  as="div"
                  v-model="this.user.timezone"
                  class="w-full"
                  immediate
                  disabled
                  v-slot="{ open }"
                >
                  <div class="relative mt-2">
                    <ComboboxInput
                      ref="comboTimezone"
                      class="w-full rounded-full border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:ring-mossgray sm:text-sm sm:leading-6 disabled:bg-mossgray-100 disabled:text-mossgray-800"
                      @change="queryTimezone = $event.target.value"
                      :display-value="(timezone) => timezone"
                      autocomplete="off"
                      :placeholder="$t('timezone') + '...'"
                      @blur="this.resetQueryTimezone(open)"
                    />

                    <ComboboxButton
                      class="absolute inset-y-0 right-2 flex items-center rounded-r-md px-2 focus:outline-none"
                      ><font-awesome-icon :icon="['fad', 'arrow-down-arrow-up']" />
                    </ComboboxButton>

                    <ComboboxOptions
                      class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ComboboxOption
                        v-for="item in filteredTimezones"
                        :key="item"
                        :value="item"
                        as="template"
                        v-slot="{ active, selected }"
                      >
                        <li
                          :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9 border-b-2 border-gray-200 group',
                            active ? 'bg-mossgray ' : ''
                          ]"
                        >
                          <div class="flex flex-col items-start space-y">
                            <span
                              :class="[
                                'ml-3 truncate   text-sm font-semibold',
                                active ? ' text-white' : 'text-black'
                              ]"
                            >
                              {{ item }}
                            </span>
                          </div>

                          <span
                            v-if="selected"
                            :class="[
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-mossgray'
                            ]"
                          >
                            <font-awesome-icon :icon="['fa-kit', 'tl-check']" />
                          </span>
                        </li>
                      </ComboboxOption>
                      <li
                        class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500 font-semibold"
                      >
                        <div class="flex items-center text-center">
                          <span class="ml-3 truncate"> {{ $t('search_by_typing') }} </span>
                        </div>

                        <!-- TODO: sence of this empty span? can be removed? -->
                        <span class="absolute inset-y-0 right-0 flex items-center pr-4"> </span>
                      </li>
                      <!-- TODO: Placeholder search -->
                    </ComboboxOptions>
                  </div>
                </Combobox>
              </div>

              <div class="sm:col-span-3 sm:col-start-1" v-if="!companyStore?.company?.force_oauth">
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('password')
                }}</label>
                <div class="mt-2">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    ref="password"
                    autocomplete="new-password"
                    v-model="user.password"
                    class="input w-full"
                    :class="{
                      '!ring-red-500':
                        user.password != user.password_confirmation ||
                        (this.errors.password ?? false)
                    }"
                  />
                </div>
                <div class="text-sm text-red-500" v-if="this.errors.password">
                  <template v-for="message in this.errors.password" :key="message">
                    <div>
                      {{ message }}
                    </div>
                  </template>
                </div>
                <div class="text-sm text-red-500" v-if="this.errors.current_password">
                  <template v-for="message in this.errors.current_password" :key="message">
                    <div>
                      {{ message }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="sm:col-span-3" v-if="!companyStore?.company?.force_oauth">
                <label
                  for="password_confirmation"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('password_confirmation') }}</label
                >
                <div class="mt-2">
                  <input
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    ref="password_confirmation"
                    autocomplete="new-password"
                    v-model="user.password_confirmation"
                    class="input w-full"
                    :class="{
                      '!ring-red-500':
                        user.password != user.password_confirmation ||
                        (this.errors.password ?? false)
                    }"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8"
          >
            <BaseButton class="button-mossgray">{{ $t('save') }}</BaseButton>
          </div>
        </form>
      </div>
    </div>
  </div>
  <BaseModal v-model="needCurrentPassword" @close-modal="needCurrentPassword = false">
    <template #header> {{ $t('myaccount.current_password_needed.header') }} </template>

    <div class="flex flex-col gap-4 px-8 py-4">
      <div class="">
        {{ $t('myaccount.current_password_needed.description') }}
      </div>
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <label for="current_password" class="block text-sm font-medium leading-6 text-gray-900">{{
            $t('current_password')
          }}</label>
          <div class="mt-2">
            <input
              type="password"
              name="current_password"
              id="current_password"
              autocomplete="current-password"
              ref="current_password"
              v-model="user.current_password"
              class="input w-full"
              :class="{
                '!ring-red-500': this.errors.current_password ?? false
              }"
            />
          </div>
          <div class="text-sm text-red-500" v-if="this.errors.current_password">
            <template v-for="message in this.errors.current_password" :key="message">
              <div>
                {{ message }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="w-full flex justify-end gap-4">
        <BaseButton class="button-gray" type="button" @click="needCurrentPassword = false">
          {{ $t('cancel') }}</BaseButton
        >
        <BaseButton class="button-mossgray" @click="this.save">{{ $t('confirm') }}</BaseButton>
      </div>
    </template>
  </BaseModal>
  <BaseModal v-model="logoutError" @close-modal="logoutError = false">
    <template #header>
      <span class="text-red-600">{{ $t('errors.error_on_logout_header') }} </span>
    </template>
    <div class="p-6">
      <span>
        {{ $t('errors.error_on_logout') }}
      </span>
    </div>
    <template #footer> </template>
  </BaseModal>
</template>

<script>
import { useAuthUserStore } from '@/stores/auth-user'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useCompanyStore } from '@/stores/company'
import apiService from '@/services/api.service'
import featureFlagsService from '@/services/feature-flags.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import desktopService from '@/services/desktop.service'
import { captureException } from '@sentry/vue'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { useAlertsStore } from '@/stores/alerts'

export default {
  components: {
    BaseButton,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    BaseModal
  },
  setup() {
    const authUserStore = useAuthUserStore()
    const featureFlags = featureFlagsService
    const companyStore = useCompanyStore()
    return {
      authUserStore,
      featureFlags,
      companyStore
    }
  },
  data() {
    return {
      isLoading: false,
      saveComplete: false,
      errors: {},
      user: {
        first_name: '',
        last_name: '',
        email: '',
        language: null,
        timezone: null
      },
      queryTimezone: '',
      timezones: [],
      needCurrentPassword: false,
      logoutError: false
    }
  },
  mounted() {
    this.timezones = Intl.supportedValuesOf('timeZone')
    this.user = { ...this.authUserStore.user }
  },
  computed: {
    filteredTimezones() {
      if (this.queryTimezone != null || this.queryTimezone != '') {
        return this.timezones.filter((item) => item.includes(this.queryTimezone))
      }
      return this.timezones
    }
  },
  methods: {
    async save() {
      this.needCurrentPassword = false
      this.errors = {}
      if (
        this.user.password != undefined &&
        this.user.password != null &&
        this.user.password != '' &&
        !this.companyStore?.company?.force_oauth
      ) {
        if (this.user.password != this.user.password_confirmation) {
          this.errors['password'] = true
          this.$refs.password.focus()
          this.errors = { password: [this.$t('errors.passwords_do_not_match')] }
          return
        } else {
          this.errors = {}
        }
        if (
          !this.authUserStore.user.needsInitialPassword &&
          (this.user.current_password == undefined || this.user.current_password == null)
        ) {
          this.needCurrentPassword = true
          this.$nextTick(() => {
            this.$refs.current_password?.focus()
          })
          return
        }
      }
      if (
        this.user.current_password != null &&
        this.user.current_password != '' &&
        this.user.password == null &&
        this.user.password_confirmation == null
      ) {
        this.errors = { current_password: [this.$t('errors.my_account.current_password')] }

        return
      } else {
        this.errors = {}
      }
      if (this.user.current_password == null || this.user.current_password == '') {
        delete this.user.current_password
      }
      if (this.user.password == null || this.user.password == '') {
        this.user.password = null
      }
      if (this.user.password_confirmation == null || this.user.password_confirmation == '') {
        this.user.password_confirmation = null
      }
      this.isLoading = true
      try {
        let response = await apiService.update(
          import.meta.env.VITE_API_URL + '/api/v1/users',
          this.authUserStore.user.id,
          this.user
        )
        useAlertsStore().successfullySaved()
        this.authUserStore.user.language = this.user.language
        this.$i18n.locale = this.user.language ?? 'de'
        this.user.current_password = null
        this.user.password = null
        this.user.password_confirmation = null
        if (this.user.current_password != undefined) {
          delete this.user.current_password
        }
        useAuthUserStore().internalUpdateUserData(response.data)
      } catch (error) {
        if (error.response.status == 422) {
          if (this.user.current_password != undefined) {
            delete this.user.current_password
          }

          this.errors = apiService.convertValidationErrors(error)
        } else if (apiService.checkIfServerError(error)) {
          //
        } else {
          captureException(error)
          useAlertsStore().error(this.$t('errors.ups'))
        }
      }
      this.isLoading = false
    },
    async logout() {
      if (
        useTimeEntryStore().timeEntries.filter((item) => timelinkStoresService.isTempId(item.id))
          .length > 0
      ) {
        if (!confirm(this.$t('logout_confirm'))) {
          return
        }
      }
      this.isLoading = true
      apiService.abortAll()
      apiService.resetSignal()
      desktopService.setLoggedOut()
      try {
        let result = await useAuthUserStore().logout(true)
        if (!result) {
          // alert('Ups. Sie scheinen sich ohne Verbindung zu Timelink abmelden zu wollen.')22
          try {
            let check = await useAuthUserStore().checkLoginStatus()
            if (check == null) {
              // useAuthUserStore().logout(false)
            }
          } catch (error) {
            if (error?.response?.status != 401) {
              captureException(error)
              this.logoutError = true
            }
            // alert('Fehler beim Abmelden!')
          }
        }
      } catch (error) {
        // captureException(error)
        try {
          useAuthUserStore().checkLoginStatus()
        } catch (err) {
          if (error?.response?.status != 401) {
            captureException(error)
            this.logoutError = true
          }
        }
      }
      this.isLoading = false
    },
    resetQueryTimezone(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'myaccount',
        'queryTimezone',
        () => {
          this.queryTimezone = ''
        },
        120
      )
    }
  }
}
</script>
