<!-- TODO: Remove this component -> no styling! -->
<template>
  <div class="flex flex-col grow px-6 site-background min-h-screen h-full">
    <!-- <div class="mb-2 flex space-x-8"> -->
    <div class="flex w-full justify-between items-center mb-5">
      <h1 class="text-basel text-3xl font-bold text-mossgray">{{ $t('actual_recording') }}</h1>

      <div class="flex flex-row items-center space-x-4 py-3">
        <div class="" v-if="activeTimeEntry === undefined || activeTimeEntry == null">
          <BaseButton
            @click="createNewTimeEntry"
            class="space-x-4 button-apricot"
            type="button"
            :disabled="isCreatingNewTimeEntry"
          >
            <font-awesome-icon :icon="['fad', 'circle-play']" />
            <span> {{ $t('start_recording') }} </span>
          </BaseButton>
        </div>
        <div v-if="activeTimeEntry !== undefined && activeTimeEntry != null">
          <BaseButton @click="stopTime()" class="space-x-4 button-apricot" type="button">
            <font-awesome-icon :icon="['fad', 'circle-stop']" />
            <span>{{ $t('stop_recording') }}</span></BaseButton
          >
        </div>
        <div v-if="activeTimeEntry !== undefined && activeTimeEntry != null">
          <BaseButton
            @click="showCancelModal = true"
            class="space-x-4 button-mossgray"
            type="button"
          >
            <font-awesome-icon :icon="['fad', 'trash']" />
            <span>{{ $t('cancel') }}</span></BaseButton
          >
        </div>
      </div>
    </div>
    <div>
      <span
        class="text-red-500 text-sm"
        v-show="
          this.activeTimeEntry &&
          stopwatch.minutes != 0 &&
          stopwatch.hours != 0 &&
          stopwatch.days != 0 &&
          disabled &&
          timeEntryStore.requiredFields.length > 0
        "
        >* {{ $t('fill_in_all_required_fields') }}</span
      >
    </div>
    <form @submit.prevent="() => {}" ref="recordForm">
      <div
        class="grid grid-cols-2 w-full md:w-full items-center gap-4 rounded-lg bg-white border border-gray-200 shadow p-4 pt-4 min-h-96"
        v-if="activeTimeEntry !== undefined && activeTimeEntry != null"
      >
        <div
          v-if="
            stopwatch != null &&
            stopwatch.minutes < 1 &&
            Date.parse(activeTimeEntry?.started_at) <= Date.now()
          "
          class="bg-apricot-100 border-l-4 border-apricot-600 text-mossgray col-span-2 px-4 space-x-4 py-2 font-semibold gap-4"
        >
          <span class="font-bold">
            {{ $t('notice') }}
          </span>
          <span class="">
            {{ $t('recording.underOneMinute') }}
          </span>
        </div>
        <div
          v-if="Date.parse(activeTimeEntry?.started_at) > Date.now()"
          class="alert-danger col-span-2 space-x-4"
        >
          <span class="font-bold text-red-700">
            {{ $t('notice') }}
          </span>
          <span class="font-semibold">
            {{ $t('recording.inTheFuture') }}
          </span>
        </div>
        <div class="col-span-2 flex flex-col space-y-4" v-if="this.activeTimeEntry?.tl?.error_code">
          <div class="font-bold py-2 px-4 border-l-4 border-red-600 text-red-600 bg-red-100">
            <span class="underline"> Achtung! <br /></span>
            <span class="text-red-500">
              {{ $t(this.activeTimeEntry?.tl?.error) }}
            </span>
          </div>
          <div
            class="py-2 px-4 border-l-4 border-timelink-blue-700 bg-timelink-blue-100/80 text-timelink-blue-600"
          >
            {{ $t('notices.automaticRetry') }}
          </div>
        </div>
        <div class="font-bold text-gray-700">{{ $t('starting_time') }}</div>
        <div>
          <div>
            <DateTimeInput v-model="activeTimeEntry.started_at" :noLabel="true">
              <template #button="vProps">
                <button
                  :class="vProps.classes"
                  type="button"
                  @click="setToPreviousEntry"
                  :disabled="
                    !previousEntry ||
                    Date.parse(previousEntry.ended_at) == Date.parse(activeTimeEntry.started_at)
                  "
                  v-tippy="{
                    allowHTML: true,
                    content: previousEntry
                      ? '<div class=\'text-center\'>' +
                        $t('timeEntry.follow_up_on_previous') +
                        '<br>' +
                        (new Date(Date.parse(activeTimeEntry.started_at)).toLocaleDateString() !=
                        new Date(Date.parse(previousEntry.ended_at)).toLocaleDateString()
                          ? new Date(Date.parse(previousEntry.ended_at)).toLocaleDateString() +
                            ' - '
                          : '') +
                        new Date(Date.parse(previousEntry.ended_at)).toLocaleTimeString() +
                        '</div>'
                      : ''
                  }"
                >
                  <!-- <div>
                          {{ new Date(Date.parse(previousEntry.ended_at)).toLocaleTimeString() }}
                        </div> -->
                  <div class="px-3 pr-4">
                    <font-awesome-icon :icon="['fad', 'arrow-up-to-line']" size="lg" />
                  </div>
                </button>
              </template>
            </DateTimeInput>
          </div>
        </div>
        <div class="font-bold text-gray-700">{{ $t('passed_time') }}</div>

        <div
          class="px-4 py-2 rounded-full border border-gray-200 leading-4"
          v-if="stopwatch != null"
        >
          <span v-if="stopwatch.days > 0"
            ><span v-text="stopwatch.days.toString().padStart(2, '0')"></span>:</span
          >
          <span v-text="stopwatch.hours.toString().padStart(2, '0')"></span>:<span
            v-text="stopwatch.minutes.toString().padStart(2, '0')"
          ></span
          >:<span v-text="stopwatch.seconds.toString().padStart(2, '0')"></span>
        </div>

        <label
          for="description"
          class="text-gray-700 font-bold py-1"
          :class="{
            'label-required': timeEntryStore.requiredFields.includes('description')
          }"
          >{{ $t('description', 1) }}
        </label>
        <textarea
          maxlength="1000"
          v-model="activeTimeEntry.description"
          :required="timeEntryStore.requiredFields.includes('description')"
          class="block w-full rounded-md border-0 pl-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        ></textarea>

        <Combobox
          as="div"
          v-model="this.selectedProject"
          class="col-span-2 grid grid-cols-2 gap-4"
          immediate
          v-slot="{ open }"
        >
          <ComboboxLabel
            class="input-label !pl-0"
            :class="{
              'label-required': timeEntryStore.requiredFields.includes('project_id')
            }"
            >{{ $t('project', 1) }}</ComboboxLabel
          >
          <div class="relative mt-2">
            <!-- queryProject = $event.target.value -->

            <ComboboxInput
              ref="comboProject"
              class="w-full rounded-full border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-timelink-blue-600 sm:text-sm sm:leading-6"
              @change="queryProject = $event.target.value"
              :display-value="(project) => project?.name"
              :required="timeEntryStore.requiredFields.includes('project_id')"
              autocomplete="off"
              :placeholder="$t('search') + '...'"
              @blur="this.resetQueryProject(open)"
            />
            <button
              type="button"
              @click="this.selectedProject = null"
              class="absolute inset-y-0 right-8 flex items-center rounded-r-md px-2 focus:outline-none"
              v-show="this.selectedProject"
            >
              <font-awesome-icon :icon="['fad', 'times']" />
            </button>
            <ComboboxButton
              class="absolute inset-y-0 right-2 flex items-center rounded-r-md px-2 focus:outline-none"
              ><font-awesome-icon :icon="['fad', 'arrow-down-arrow-up']" />
              <!-- <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
            </ComboboxButton>

            <ComboboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ComboboxOption
                v-for="item in filteredProjects"
                :key="item.id"
                :value="item"
                :disabled="!item.active"
                as="template"
                v-slot="{ active, selected }"
              >
                <ComboBoxListItem :active="active" :selected="selected" :item="item" />
              </ComboboxOption>
              <li
                class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500 font-semibold"
              >
                <div class="flex items-center text-center">
                  <!-- <img
                                      :src="project.imageUrl"
                                      alt=""
                                      class="h-6 w-6 flex-shrink-0 rounded-full"
                                    /> -->
                  <span class="ml-3 truncate"> {{ $t('search_by_typing') }} </span>
                </div>

                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-4 text-timelink-blue-600"
                >
                  <!-- <CheckIcon class="h-5 w-5" aria-hidden="true" /> -->
                </span>
              </li>
              <!-- TODO: Placeholder search -->
            </ComboboxOptions>
          </div>
        </Combobox>

        <Combobox
          as="div"
          v-model="this.selectedClient"
          class="col-span-2 grid grid-cols-2 gap-4"
          immediate
          v-slot="{ open }"
        >
          <ComboboxLabel
            class="input-label !pl-0"
            :class="{
              'label-required':
                timeEntryStore.requiredFields.includes('client_id') ||
                (activeTimeEntry?.billable ?? false)
            }"
            >{{ $t('client', 1) }}</ComboboxLabel
          >
          <div class="relative mt-2">
            <ComboboxInput
              class="w-full rounded-full border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-timelink-blue-600 sm:text-sm sm:leading-6"
              @change="queryClient = $event.target.value"
              :display-value="(client) => client?.name"
              autocomplete="off"
              :required="
                timeEntryStore.requiredFields.includes('client_id') ||
                (activeTimeEntry?.billable ?? false)
              "
              :placeholder="$t('search') + '...'"
              @blur="resetQueryClient(open)"
            />
            <button
              type="button"
              @click="this.selectedClient = null"
              class="absolute inset-y-0 right-8 flex items-center rounded-r-md px-2 focus:outline-none"
              v-show="this.selectedClient"
            >
              <font-awesome-icon :icon="['fad', 'times']" />
            </button>
            <ComboboxButton
              class="absolute inset-y-0 right-2 flex items-center rounded-r-md px-2 focus:outline-none"
              ><font-awesome-icon :icon="['fad', 'arrow-down-arrow-up']" />
              <!-- <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
            </ComboboxButton>

            <ComboboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ComboboxOption
                v-for="item in filteredClients"
                :key="item.id"
                :value="item"
                :disabled="!item.active"
                as="template"
                v-slot="{ active, selected }"
              >
                <ComboBoxListItem :active="active" :selected="selected" :item="item" />
              </ComboboxOption>
              <li
                class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500 font-semibold"
              >
                <div class="flex items-center text-center">
                  <!-- <img
                                      :src="project.imageUrl"
                                      alt=""
                                      class="h-6 w-6 flex-shrink-0 rounded-full"
                                    /> -->
                  <span class="ml-3 truncate"> {{ $t('search_by_typing') }} </span>
                </div>

                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-4 text-timelink-blue-600"
                >
                  <!-- <CheckIcon class="h-5 w-5" aria-hidden="true" /> -->
                </span>
              </li>
            </ComboboxOptions>
          </div>
        </Combobox>
        <Combobox
          as="div"
          v-model="this.selectedService"
          class="col-span-2 grid grid-cols-2 gap-4"
          immediate
          v-slot="{ open }"
        >
          <ComboboxLabel
            class="input-label !pl-0"
            :class="{
              'label-required':
                timeEntryStore.requiredFields.includes('service_id') ||
                (activeTimeEntry?.billable ?? false)
            }"
            >{{ $t('service', 1) }}</ComboboxLabel
          >
          <div class="relative mt-2">
            <ComboboxInput
              class="w-full rounded-full border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-timelink-blue-600 sm:text-sm sm:leading-6"
              @change="queryService = $event.target.value"
              :display-value="(service) => service?.name"
              autocomplete="off"
              :required="
                timeEntryStore.requiredFields.includes('service_id') ||
                (activeTimeEntry?.billable ?? false)
              "
              :placeholder="$t('search') + '...'"
              @blur="resetQueryService(open)"
            />
            <button
              type="button"
              @click="this.selectedService = null"
              class="absolute inset-y-0 right-8 flex items-center rounded-r-md px-2 focus:outline-none"
              v-show="this.selectedService"
            >
              <font-awesome-icon :icon="['fad', 'times']" />
            </button>
            <ComboboxButton
              class="absolute inset-y-0 right-2 flex items-center rounded-r-md px-2 focus:outline-none"
              ><font-awesome-icon :icon="['fad', 'arrow-down-arrow-up']" />
              <!-- <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
            </ComboboxButton>

            <ComboboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ComboboxOption
                v-for="item in filteredServices"
                :key="item.id"
                :value="item"
                :disabled="!item.active"
                as="template"
                v-slot="{ active, selected }"
              >
                <ComboBoxListItem :active="active" :selected="selected" :item="item" />
              </ComboboxOption>
              <li
                class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500 font-semibold"
              >
                <div class="flex items-center text-center">
                  <!-- <img
                                      :src="project.imageUrl"
                                      alt=""
                                      class="h-6 w-6 flex-shrink-0 rounded-full"
                                    /> -->
                  <span class="ml-3 truncate"> {{ $t('search_by_typing') }} </span>
                </div>

                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-4 text-timelink-blue-600"
                >
                  <!-- <CheckIcon class="h-5 w-5" aria-hidden="true" /> -->
                </span>
              </li>
            </ComboboxOptions>
          </div>
        </Combobox>
        <label
          for="paid"
          class="text-gray-700 font-bold py-1"
          :class="{ 'label-required': timeEntryStore.requiredFields.includes('billable') }"
          >{{ $t('billable') }}
        </label>
        <BaseSwitch
          v-model="activeTimeEntry.billable"
          :class="[
            activeTimeEntry.billable ? 'bg-apricot' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          ]"
        >
          <span class="sr-only">{{ $t('billable') }}</span>
          <span
            aria-hidden="true"
            class="'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'"
            :class="{
              'translate-x-0': !activeTimeEntry.billable,
              'translate-x-5': activeTimeEntry.billable
            }"
          />
        </BaseSwitch>
      </div>
    </form>
    <!-- <div>Test</div> -->
    <BaseModal v-model="showCancelModal" :loading="isLoadingCancel" @close-modal="closeCancelModal">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.timeEntry') }}
        </span>
      </div>

      <template #footer>
        <div class="w-full flex justify-end gap-4">
          <BaseButton class="button-gray" type="button" @click="closeCancelModal">
            {{ $t('cancel') }}</BaseButton
          >
          <BaseButton class="button-red" @click="deleteRecord">{{ $t('delete') }}</BaseButton>
        </div>
      </template>
    </BaseModal>
  </div>
</template>
<!-- TODO: Rename to record -->
<script>
import { useClientsStore } from '@/stores/clients'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { useTimeEntryStore } from '@/stores/timeEntry'
import BaseButton from '@/components/general/BaseButton.vue'
import { useStopwatch } from 'vue-timer-hook'
import { now } from '@vueuse/core'
import { Switch as BaseSwitch } from '@headlessui/vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { useAuthUserStore } from '@/stores/auth-user'
import ComboBoxListItem from '@/components/general/ComboBoxListItem.vue'
import { usePanelStore } from '@/stores/panel'
import BaseModal from '@/components/modals/BaseModal.vue'
import datetime from '@/lib/datetime'
import DateTimeInput from '@/components/general/DateTimeInput.vue'

export default {
  components: {
    BaseButton,
    BaseModal,
    BaseSwitch,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    ComboBoxListItem,
    DateTimeInput
  },
  setup() {
    const timeEntryStore = useTimeEntryStore()
    return {
      timeEntryStore
    }
  },
  data() {
    return {
      collapse: {
        clients: false,
        projects: false,
        services: false
      },
      clients: [],
      projects: [],
      services: [],
      queryProject: '',
      queryProjectHandler: null,
      queryClient: '',
      queryClientHandler: null,
      queryService: '',
      queryServiceHandler: null,
      activeTimeEntry: null,
      stopwatch: null,
      test: now(),
      disabled: true,
      isLoadingTimeEntry: false,
      updateTimeout: null,
      canUpdateEntry: false,
      showCancelModal: false,
      isLoadingCancel: false,
      isCreatingNewTimeEntry: false
    }
  },
  created() {
    useTimeEntryStore().$subscribe((mutation) => {
      if (
        !Object.hasOwn(mutation, 'payload') ||
        !Object.hasOwn(mutation.payload, 'activeTimeEntry')
      ) {
        return
      }
      // TODO: Change to payload
      if (this.activeTimeEntry?.id != mutation.payload) {
        if (!this.isLoadingTimeEntry) {
          this.fetchActiveTimeEntry()
        }
      }
    })
    this.fetchActiveTimeEntry()
  },
  beforeUnmount() {
    timelinkStoresService.setOrRenewTimeout(
      'timeEntries',
      'clearSearch',
      () => {
        useTimeEntryStore().clearSearch()
      },
      2000
    )
  },
  unmounted() {},
  mounted() {
    timelinkStoresService.removeTimeout('timeEntries', 'clearSearch')

    this.updateStores()
    if (this.activeTimeEntry) {
      timelinkStoresService.updateTl(this.activeTimeEntry)
    } else {
      useTimeEntryStore().loadActive()
    }
    if (this.activeTimeEntry?.tl.isDirty) {
      this.updateActiveTimeEntry()
    }
    this.clients = useClientsStore().clients
    this.services = useServicesStore().services
    this.projects = useProjectsStore().projects
    useClientsStore().$subscribe(() => {
      this.clients = useClientsStore().clients
    })
    useServicesStore().$subscribe(() => {
      this.services = useServicesStore().services
    })
    useProjectsStore().$subscribe(() => {
      this.projects = useProjectsStore().projects
    })
    useAuthUserStore().$subscribe(() => {
      this.clients = useClientsStore().clients
      this.services = useServicesStore().services
      this.projects = useProjectsStore().projects
    })
  },
  computed: {
    selectProjects() {
      // let test = this.activeTimeEntry?.id
      if (this.activeTimeEntry?.client_id ?? null) {
        return this.projects
          .filter((item) => item.client_id == this.activeTimeEntry?.client_id)
          .filter((item) => item.active)
      }
      return this.projects.filter((item) => item.active)
    },
    filteredProjects() {
      this.activeTimeEntry?.id
      let projects = null
      // let selected = null
      if (this.activeTimeEntry?.client_id ?? null) {
        projects = this.projects
          .filter((item) => item.client_id == this.activeTimeEntry?.client_id)
          .filter((item) => item.active)
      } else {
        projects = this.projects.filter((item) => item.active && (item?.tl?.clientActive ?? true))
      }
      // if (this.activeTimeEntry?.project_id) {
      //   selected = projects.find((item) => (item.id = this.activeTimeEntry.project_id))
      //   console.log(selected.id)
      //   projects = projects.filter((item) => item.id != selected.id)
      //   projects.unshift(selected)
      // }
      if (this.queryProject == null || this.queryProject == undefined || this.queryProject == '') {
        return projects.slice(0, 10)
      }
      return projects
        .filter(
          (item) =>
            // (selected && item.id == selected.id) ||
            item.name.toLowerCase().includes(this.queryProject.toLowerCase()) ||
            (item.info != null &&
              item.info != undefined &&
              item.info.toLowerCase().includes(this.queryProject.toLowerCase())) ||
            (item.ext_tool_id != null &&
              item.ext_tool_id != undefined &&
              item.ext_tool_id.toLowerCase().includes(this.queryProject.toLowerCase()))
        )
        .slice(0, 10)
    },
    selectClients() {
      this.activeTimeEntry.id

      return this.clients.filter((item) => item.active)
    },
    filteredClients() {
      this.activeTimeEntry.id

      if (this.queryClient == undefined || this.queryClient == null || this.queryClient == '') {
        return this.selectClients.slice(0, 10)
      }
      return this.selectClients
        .filter(
          (item) =>
            item.name.toLowerCase().includes(this.queryClient.toLowerCase()) ||
            (item.info != null &&
              item.info != undefined &&
              item.info.toLowerCase().includes(this.queryClient.toLowerCase())) ||
            (item.ext_tool_id != null &&
              item.ext_tool_id != undefined &&
              item.ext_tool_id.toLowerCase().includes(this.queryClient.toLowerCase()))
        )
        .slice(0, 10)
    },
    selectServices() {
      this.activeTimeEntry.id

      return this.services.filter((item) => item.active)
    },
    filteredServices() {
      this.activeTimeEntry.id

      if (this.queryService == undefined || this.queryService == null || this.queryService == '') {
        return this.selectServices
        // .slice(0, 10)
      }
      return this.selectServices.filter(
        (item) =>
          item.name.toLowerCase().includes(this.queryService.toLowerCase()) ||
          (item.info != null &&
            item.info != undefined &&
            item.info.toLowerCase().includes(this.queryService.toLowerCase())) ||
          (item.ext_tool_id != null &&
            item.ext_tool_id != undefined &&
            item.ext_tool_id.toLowerCase().includes(this.queryService.toLowerCase()))
      )
      // .slice(0, 10)
    },
    selectedProject: {
      get() {
        return useProjectsStore().getId(this.activeTimeEntry?.project_id) ?? null
      },
      set(newVal) {
        this.activeTimeEntry.project_id = newVal?.id ?? null
        this.resetQueryProject()
        if (this.activeTimeEntry.project_id) {
          this.activeTimeEntry.client_id = newVal.client_id
        }
      }
    },
    selectedClient: {
      get() {
        return useClientsStore().getId(this.activeTimeEntry?.client_id) ?? null
      },
      set(newVal) {
        this.activeTimeEntry.client_id = newVal?.id ?? null
        this.resetQueryClient()
        if (this.activeTimeEntry.project_id) {
          const project = useProjectsStore().getId(this.activeTimeEntry.project_id)
          if (project?.client_id != newVal?.id) {
            this.activeTimeEntry.project_id = null
            this.$forceUpdate()
          }
        }
      }
    },
    selectedService: {
      get() {
        return useServicesStore().getId(this.activeTimeEntry?.service_id) ?? null
      },
      set(newVal) {
        this.resetQueryService()
        this.activeTimeEntry.service_id = newVal?.id ?? null
      }
    },
    previousEntry() {
      if (!this.activeTimeEntry) {
        return null
      }
      // let timeEntries = useTimeEntryStore().timeEntries.filter(
      //   (item) =>
      //     item.id != this.activeTimeEntry.id &&
      //     item.ended_at &&
      //     Date.parse(item.ended_at) <= Date.parse(this.activeTimeEntry.started_at)
      // )
      let timeEntries = useTimeEntryStore().timeEntries.filter(
        (item) =>
          item.id != this.activeTimeEntry.id &&
          item.ended_at &&
          Date.parse(item.ended_at) >=
            Date.parse(this.activeTimeEntry.started_at) - 24 * 60 * 60 * 1000 &&
          Date.parse(item.ended_at) <= Date.parse(this.activeTimeEntry.started_at)
      )
      let max = null
      let maxId = null
      timeEntries.forEach((item) => {
        if (!item.ended_at) {
          return
        }
        let start = Date.parse(item.ended_at)
        if (start > max) {
          max = start
          maxId = item.id
        }
      })
      return useTimeEntryStore().getId(maxId)
    }
  },
  watch: {
    'activeTimeEntry.started_at'() {
      if (this.activeTimeEntry == null || this.activeTimeEntry.started_at == null) {
        return
      }
      try {
        this.stopwatch = useStopwatch(
          (now() - Date.parse(this.activeTimeEntry.started_at)) / 1000,
          true
        )
        this.stopwatch.start()
      } catch (error) {
        //
      }
      timelinkStoresService.updateTl(this.activeTimeEntry)
      this.updateActiveTimeEntry()
    },
    'activeTimeEntry.description'(newVal, oldVal) {
      if (this.activeTimeEntry == null) {
        return
      }
      this.updateDisabled()
      if (newVal != oldVal) {
        timelinkStoresService.updateTl(this.activeTimeEntry)
        this.updateActiveTimeEntry()
      }
    },
    'activeTimeEntry.service_id'(newVal, oldVal) {
      if (this.activeTimeEntry == null) {
        return
      }
      this.updateDisabled()
      if (newVal != oldVal) {
        if (usePanelStore().view == 'selectService') {
          usePanelStore().updateView('recording', null, true)
        }
        timelinkStoresService.updateTl(this.activeTimeEntry)
        this.updateActiveTimeEntry()
      }
    },
    'activeTimeEntry.billable'(newVal, oldVal) {
      if (this.activeTimeEntry == null) {
        return
      }
      this.updateDisabled()
      if (oldVal == null) {
        return
      }

      if (newVal != oldVal) {
        timelinkStoresService.updateTl(this.activeTimeEntry)
        this.updateActiveTimeEntry()
      }
    },
    'activeTimeEntry.client_id'(newVal, oldVal) {
      if (this.activeTimeEntry == null) {
        return
      }
      this.updateDisabled()
      if (newVal != oldVal) {
        this.updateActiveTimeEntry()
        if (usePanelStore().view == 'selectClient') {
          usePanelStore().updateView('recording')
        }
        if (newVal) {
          if (
            this.activeTimeEntry.project_id != null &&
            newVal != useProjectsStore().getId(this.activeTimeEntry.project_id)?.client_id
          ) {
            this.activeTimeEntry.project_id = null
          }
        }
        timelinkStoresService.updateTl(this.activeTimeEntry)
        this.updateActiveTimeEntry()
      }
    },
    'activeTimeEntry.project_id'(newVal, oldVal) {
      if (this.activeTimeEntry == null) {
        return
      }
      this.updateDisabled()
      if (newVal != oldVal) {
        if (newVal) {
          if (
            this.activeTimeEntry.client_id == null ||
            this.activeTimeEntry.client_id != useProjectsStore().getId(newVal).client_id
          ) {
            this.activeTimeEntry.client_id = useProjectsStore().getId(newVal).client_id
          }
        }
        timelinkStoresService.updateTl(this.activeTimeEntry)
        this.updateActiveTimeEntry()
      }
    },
    queryProject(newVal) {
      if (newVal != null && newVal != undefined && newVal != '') {
        this.callProjectSearch()
      }
    },
    queryClient(newVal) {
      if (newVal != null && newVal != undefined && newVal != '') {
        this.callClientSearch()
      }
    },
    queryService(newVal) {
      if (newVal != null && newVal != undefined && newVal != '') {
        this.callServiceSearch()
      }
    }
  },
  methods: {
    getDate(entry) {
      return datetime.getDate(entry)
    },
    getTime(entry, addHours = 0, addMinutes = 0) {
      return datetime.getTime(entry, addHours, addMinutes)
    },
    setDate(entry, value) {
      return datetime.setDate(entry, value)
    },
    setTime(entry, value) {
      return datetime.setTime(entry, value)
    },
    createNewTimeEntry() {
      // TODO: Add error-handling modal
      let hasActiveTimeEntries = false
      useTimeEntryStore().timeEntries.forEach((item) => {
        if (item && !item.ended_at) {
          hasActiveTimeEntries = true
        }
      })
      if (hasActiveTimeEntries) {
        useTimeEntryStore().internalUpdateActiveTimeEntry()
      }
      this.isCreatingNewTimeEntry = true
      if (useTimeEntryStore().createNewActiveTimeEntry()) {
        this.fetchActiveTimeEntry()
        this.isCreatingNewTimeEntry = false
      } else {
        this.isCreatingNewTimeEntry = false
        throw new Error('No active time entry was created!')
      }
    },
    async stopTime() {
      if (!this.$refs.recordForm.reportValidity()) {
        return
      }
      await useTimeEntryStore().stopActiveEntry()
      this.activeTimeEntry = null
      this.fetchActiveTimeEntry()
      timelinkStoresService.setOrRenewTimeout(
        'recording',
        'fetchActiveTimeEntry',
        () => {
          this.fetchActiveTimeEntry()
        },
        200
      )
    },
    closeCancelModal() {
      this.showCancelModal = false
      this.isLoadingCancel = false
    },
    deleteRecord() {
      this.isLoadingCancel = true
      useTimeEntryStore().deleteId(this.activeTimeEntry.id)
      this.activeTimeEntry = null
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout)
      }
      this.fetchActiveTimeEntry()
      this.closeCancelModal()
    },
    initActiveTimeEntry() {
      if (this.activeTimeEntry) {
        useTimeEntryStore().fetchId(this.activeTimeEntry.id)
      } else {
        useTimeEntryStore().loadActive()
      }
    },
    fetchActiveTimeEntry() {
      this.isLoadingTimeEntry = true
      this.activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
      this.isLoadingTimeEntry = false
      if (this.activeTimeEntry) {
        this.stopwatch = useStopwatch(
          (now() - Date.parse(this.activeTimeEntry.started_at)) / 1000,
          true
        )
        this.stopwatch.start()
      } else {
        this.stopwatch = useStopwatch(0, false)
      }
    },
    updateStores() {
      this.initActiveTimeEntry()
      this.clients = useClientsStore().clients
      this.services = useServicesStore().services
      this.projects = useProjectsStore().projects
    },
    updateActiveTimeEntry() {
      timelinkStoresService.removeTimeout('recording', 'updateActive')
      if (this.activeTimeEntry && this.activeTimeEntry?.tl?.isDirty) {
        timelinkStoresService.setOrRenewTimeout(
          'recording',
          'updateActive',
          () => {
            if (
              this.activeTimeEntry?.tl?.isDirty ||
              this.activeTimeEntry?.description != this.activeTimeEntry?.tl?.origin?.description
            ) {
              useTimeEntryStore().update(this.activeTimeEntry.id, true)
            }
          },
          2500
        )
      }
    },
    updateDisabled() {
      let found = false
      useTimeEntryStore()
        .requiredFields.filter((item) => !['started_at', 'ended_at'].includes(item))
        .forEach((item) => {
          if (found) {
            return
          }
          if (
            this.activeTimeEntry[item] == null ||
            this.activeTimeEntry[item] == undefined ||
            (typeof this.activeTimeEntry[item] === 'string' && this.activeTimeEntry[item] == '')
          ) {
            found = true
          }
        })
      this.disabled = found
    },
    resetQueryProject(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'recording',
        'queryProject',
        () => {
          this.queryProject = ''
        },
        120
      )
    },
    resetQueryClient(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'recording',
        'queryClient',
        () => {
          this.queryClient = ''
        },
        120
      )
    },
    resetQueryService(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'recording',
        'queryService',
        () => {
          this.queryService = ''
        },
        120
      )
    },
    callProjectSearch() {
      timelinkStoresService.setOrRenewTimeout(
        'recording',
        'queryProjectHandler',
        () => {
          useProjectsStore().search(this.queryProject, this.activeTimeEntry?.client_id ?? null, {
            active: true
          })
        },
        250
      )
    },
    callClientSearch() {
      timelinkStoresService.setOrRenewTimeout(
        'recording',
        'queryClientHandler',
        () => {
          useClientsStore().search(this.queryClient, {
            active: true
          })
        },
        250
      )
    },
    callServiceSearch() {
      timelinkStoresService.setOrRenewTimeout(
        'recording',
        'queryServiceHandler',
        () => {
          useServicesStore().search(this.queryService, {
            active: true
          })
        },
        250
      )
    },
    setToPreviousEntry() {
      this.activeTimeEntry.started_at = new Date(
        Date.parse(this.previousEntry.ended_at)
      ).toISOString()
    }
  }
}
</script>
