<!-- TODO: Translate -->
<template>
  <div class="min-h-screen w-full flex flex-col flex-grow items-center justify-center">
    <LoadingSpinner v-model="showSpinner" :fullPage="true"></LoadingSpinner>
    <div class="max-w-xl w-full space-y-8 bg-white rounded-2xl border border-mossgray-200">
      <div class="w-full space-y-8 p-12 pt-0">
        <div>
          <div class="flex justify-center mt-6 -mb-2 py-8 px-16">
            <img :src="img" />
          </div>
          <h2 class="mt-6 text-center text-3xl font-extrabold">
            Passwort zurücksetzen
          </h2>
          <p class="mt-2 text-center text-base leading-4">
            <router-link to="/login" class="font-medium text-sm hover:underline">
              Zurück zum Login
            </router-link>
          </p>
        </div>
        <div v-if="!success">
          <form class="space-y-4" @submit.prevent="sendForm">
            <input type="hidden" name="remember" value="true" />
            <div class="rounded-md shadow-sm -space-y-px">
              <div>
                <!-- TODO: Harmonise input -->
                <label for="email-address" class="sr-only">Email address</label>
                <input
                  v-model="email"
                  id="email-address"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="appearance-none relative block w-full px-3 py-2 border border-mossgray-200 placeholder-gray-500 rounded-md focus:outline-none focus:ring-mossgray focus:border-mossgray sm:text-sm"
                  placeholder="E-Mail Adresse"
                />
              </div>
            </div>

            <div class="text-red-500 text-sm" v-text="message"></div>

            <div>
              <button
                type="submit"
                class="group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-mossgray hover:bg-white hover:text-mossgray ring-2 ring-mossgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mossgray"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <!-- TODO: Use FontAwesome Icon -->
                  <!-- Heroicon name: solid/lock-closed -->
                  <svg
                    class="h-5 w-5 text-white group-hover:text-mossgray"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Anfordern
              </button>
            </div>
          </form>
        </div>
        <div v-else>
          <div class="flex items-center">
            <div class="basis-1/4 p-4">
              <font-awesome-icon
                :icon="['fa-kit', 'tl-envelope-check']"
                class="text-apricot w-32 h-32 -ml-6"
              />
            </div>
            <div class="basis-3/4 space-y-2">
              <p>
                <span class="font-semibold text-lg">Deine Anfrage war erfolgreich.</span><br />
                Du erhälst uns eine E-Mail mit einem Link zum Zurücksetzen deines Passworts.
              </p>
              <p class="text-sm text-gray-500">
                Solltest Du innerhalb weniger Minuten keine E-Mail erhalten, so prüfe bitte deinen SPAM-Ordner.
              </p>
            </div>
          </div>
        </div>

        <!-- TODO: Can be removed? -->
        <div class=""></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { captureException } from '@sentry/vue'
import { $t } from '@/config/i18n'
import img from '@/assets/timelink_primary_logo_5f_moosgrauRGB.svg'
const route = useRoute()

watch(
  () => route.params.token,
  (newVal, oldVal) => {
    console.log(newVal, oldVal)
  }
)

console.log(route.params.token)
const email = ref('')
const showSpinner = ref(false)
const success = ref(false)
const message = ref(null)

async function sendForm() {
  showSpinner.value = true
  try {
    let response = await axios.post(import.meta.env.VITE_API_URL + '/api/v1/forgotPassword', {
      email: email.value
    })
    if (response.data.success) {
      success.value = true
    } else {
      if (response.data.error_code) {
        if (response.data.error_code == 1) {
          message.value =
            $t('resetpassword.errors.force_oauth') +
            ' ' +
            (response.data.provider
              ? $t('login.errors.forced_oauth.' + response.data.provider)
              : '')
        } else if (response.data.error_code == 2) {
          message.value = $t('login.errors.user_not_active')
        }
      }
    }
  } catch (error) {
    if (error?.response?.status == 422) {
      errors.value = apiService.convertValidationErrors(error.response.data)
    } else if (error?.response?.status == 429) {
      message.value = $t('errors.429')
    } else {
      console.log(error)
      message.value = 'Es ist ein Fehler aufgetreten.'
    }
  }
  showSpinner.value = false
}
</script>
