<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal" v-model:loading="isLoading">
      <template v-slot:header> {{ $t('create_client') }} </template>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('name')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="text"
                name="name"
                id="name"
                autocomplete="name"
                v-model="client.name"
                class="input w-full"
                required
                ref="name_input"
              />
            </div>
            <div class="text-sm text-red-500" v-if="this.errors.name">
              <template v-for="message in this.errors.name" :key="message">
                <div>
                  {{ message }}
                </div>
              </template>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label for="acronym" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('acronym')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="text"
                name="acronym"
                id="acronym"
                autocomplete="acronym"
                v-model="client.acronym"
                class="input w-full"
              />
            </div>
            <div class="text-sm text-red-500" v-if="this.errors.color">
              <template v-for="message in this.errors.color" :key="message">
                <div>
                  {{ message }}
                </div>
              </template>
            </div>
          </div>

          <div class="sm:col-span-6">
            <label for="info" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('info')
            }}</label>
            <div class="mt-2">
              <textarea
                :disabled="!canEdit"
                type="text"
                name="info"
                id="info"
                autocomplete="Off"
                v-model="client.info"
                class="textarea w-full"
              ></textarea>
            </div>
            <div class="text-sm text-red-500" v-if="this.errors.info">
              <template v-for="message in this.errors.info" :key="message">
                <div>
                  {{ message }}
                </div>
              </template>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('active')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="checkbox"
                name="active"
                id="active"
                autocomplete="Off"
                v-model="client.active"
                class="checkbox"
              />
            </div>
            <div class="text-sm text-red-500" v-if="this.errors.active">
              <template v-for="message in this.errors.active" :key="message">
                <div>
                  {{ message }}
                </div>
              </template>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="billable" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('billable')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="checkbox"
                name="billable"
                id="billable"
                autocomplete="Off"
                v-model="client.billable"
                class="checkbox"
              />
            </div>
            <div class="text-sm text-red-500" v-if="this.errors.billable">
              <template v-for="message in this.errors.billable" :key="message">
                <div>
                  {{ message }}
                </div>
              </template>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="color" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('color')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="color"
                name="color"
                id="color"
                autocomplete="Off"
                v-model="client.color"
                class="input"
              />
            </div>
            <div class="text-sm text-red-500" v-if="this.errors.color">
              <template v-for="message in this.errors.color" :key="message">
                <div>
                  {{ message }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="alert-danger" v-show="errors.length">
        <template> </template>
      </div>

      <template v-slot:footer>
        <div class="w-full flex justify-end space-x-4 items-center">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel')
          }}</BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="save"
            >{{ $t('create') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'
import { captureException } from '@sentry/vue'

export default {
  components: {
    BaseModal,
    BaseButton
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup() {
    return {}
  },
  data: () => ({
    createModal: false,
    client: {
      name: null,
      info: null,
      active: true,
      billable: true,
      color: '#183F6A',
      acronym: null
    },
    isLoading: false,
    canEdit: true,
    errors: [],
    validationErrors: []
  }),
  computed: {
    showModal: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          console.log(this.$refs.name_input)
          this.$refs.name_input?.focus()
        })
      }
    }
  },
  methods: {
    async save() {
      if (useCompanyStore().company.pull_provider || useAuthUserStore().user.admin < 5) {
        useAlertsStore().error(this.$t('errors.no_permissions'))
        return
      }
      this.isLoading = true
      this.errors = []
      this.validationErrors = []
      try {
        let response = await apiService.create(
          import.meta.env.VITE_API_URL + '/api/v1/clients',
          this.client
        )
        if (response.success) {
          this.showModal = false
          useAlertsStore().success(this.$t('clients.created', { clientName: this.client.name }))
          this.client = {
            name: null,
            info: null,
            active: true,
            billable: true,
            color: '#183F6A',
            acronym: null
          }
          this.showModal = false
        } else {
          // Change it with changes done to ClientsController
          // this.errors = [this.$t('errors.ups')]
          useAlertsStore().error(this.$t('errors.save_returned_success_false'))
        }
      } catch (error) {
        if (apiService.checkIfNotAuthenticated(error)) {
          return
        } else if (apiService.checkIfServerError(error)) {
          //
        } else if (
          error.response.status == 403 &&
          error.response.data.message == this.$t('errors.no_subscription')
        ) {
          // TODO: Add better error handling!
          useAlertsStore().error(this.$t('errors.no_subscription'))
        } else if (error.response.status == 422) {
          this.errors = apiService.convertValidationErrors(error.response)
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
          captureException(error)
        }
      }
      this.isLoading = false
    },
    isEditable(field) {
      // let provider = useCompanyStore().company.pull_provider
      if (field == 'acronym') {
        return true
      }
      if (field == 'color') {
        return true
      }
      return false
    },
    closeModal() {
      this.isLoading = false
      this.showModal = false
      this.client = {
        name: null,
        info: null,
        active: true,
        billable: true,
        color: '#183F6A'
      }
    }
  }
}
</script>
