<template>
  <!-- TODO: Move to template component -->
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>

    <div class="py-3 mb-3 flex w-full items-center">
      <h1 class="text-2xl font-bold mr-auto">
        {{ $t('menu.settings_.notifications') }}
      </h1>
    </div>

    <div class="divide-y divide-mossgray-200">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-3 pb-6">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7 flex justify-between items-end">
            <div>{{ $t('settings.notification.general.header') }}</div>
          </h2>
          <p class="mt-1 text-sm leading-5">
            {{ $t('settings.notification.general.description') }}
          </p>
        </div>

        <form
          class="bg-white ring-1 ring-mossgray-300 sm:rounded-xl md:col-span-2"
          @submit.prevent="saveGeneral"
        >
          <div class="px-4 py-6 sm:p-8 relative">
            <div class="grid w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label for="toStartTrackingActive" class="input-label"
                  >{{ $t('settings.notification.timeTracking.onTrackingStartAndStopOverPanel') }}
                </label>
                <div class="mt-1">
                  <BaseSwitch
                    v-model="onTrackingStartAndStopOverPanel.active"
                    :class="[
                      onTrackingStartAndStopOverPanel.active ? 'bg-lime' : 'bg-mossgray-300',
                      'relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-mossgray focus:ring-offset-2'
                    ]"
                  >
                    <span class="sr-only">{{
                      $t('settings.notification.timeTracking.onTrackingStartAndStopOverPanel')
                    }}</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        onTrackingStartAndStopOverPanel.active ? 'translate-x-8' : 'translate-x-0',
                        'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      ]"
                    />
                  </BaseSwitch>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="toStartTrackingActive" class="input-label"
                  >{{ $t('settings.notification.timeTracking.remindActiveTracking_1') }}
                </label>
                <div class="mt-1">
                  <BaseSwitch
                    v-model="remindActiveTracking.active"
                    :class="[
                      remindActiveTracking.active ? 'bg-lime' : 'bg-mossgray-300',
                      'relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-mossgray focus:ring-offset-2'
                    ]"
                  >
                    <span class="sr-only">{{
                      $t('settings.notification.timeTracking.remindActiveTracking_1')
                    }}</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        remindActiveTracking.active ? 'translate-x-8' : 'translate-x-0',
                        'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      ]"
                    />
                  </BaseSwitch>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="remindActiveTracking_notifyAfter"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('settings.notification.timeTracking.notifyAfter') }}</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="remindActiveTracking_notifyAfter"
                    id="remindActiveTracking_notifyAfter"
                    autocomplete="Off"
                    v-model="remindActiveTracking.after"
                    :disabled="!remindActiveTracking.active"
                    class="input w-16 text-center"
                  />
                  <span class="pl-1 text-sm font-medium">{{
                    $t(
                      'settings.notification.timeTracking.notifyAfters.unit',
                      remindActiveTracking.after
                    )
                  }}</span>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="notifyRepeat"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('settings.notification.timeTracking.doBreak') }}</label
                >
                <div class="mt-1">
                  <BaseSwitch
                    v-model="doBreak.active"
                    :class="[
                      doBreak.active ? 'bg-lime' : 'bg-mossgray-300',
                      'relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-mossgray focus:ring-offset-2'
                    ]"
                  >
                    <span class="sr-only">{{
                      $t('settings.notification.timeTracking.doBreak')
                    }}</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        doBreak.active ? 'translate-x-8' : 'translate-x-0',
                        'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      ]"
                    />
                  </BaseSwitch>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="notifyRepeat"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('settings.notification.timeTracking.doBreakAt') }}</label
                >
                <div class="mt-1">
                  <input
                    type="time"
                    step="60"
                    class="input"
                    v-model="doBreak.time"
                    :disabled="!doBreak.active"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-end gap-x-6 border-t border-mossgray-300 px-4 py-4 sm:px-8"
          >
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <BaseButton type="submit" class="button-mossgray">{{ $t('save') }}</BaseButton>
          </div>
        </form>
      </div>
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 py-6">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7 flex justify-between items-end">
            <div>{{ $t('settings.notification.timeTracking.header') }}</div>
          </h2>
          <p class="mt-1 text-sm leading-5">
            {{ $t('settings.notification.timeTracking.description') }}
          </p>
        </div>

        <form
          class="bg-white ring-1 ring-mossgray-300 sm:rounded-xl md:col-span-2"
          @submit.prevent="saveStartOfRecording"
        >
          <div class="px-4 py-6 sm:p-8 relative">
            <div class="grid w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label for="toStartTrackingActive" class="input-label"
                  >{{ $t('settings.notification.timeTracking.active') }}
                </label>
                <div class="mt-1">
                  <BaseSwitch
                    v-model="toStartTracking.active"
                    :class="[
                      toStartTracking.active ? 'bg-lime' : 'bg-mossgray-300',
                      'relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-mossgray focus:ring-offset-2'
                    ]"
                  >
                    <span class="sr-only">{{
                      $t('settings.notification.timeTracking.active')
                    }}</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        toStartTracking.active ? 'translate-x-8' : 'translate-x-0',
                        'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      ]"
                    />
                  </BaseSwitch>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="notifyAfter"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('settings.notification.timeTracking.notifyAfter') }}</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="notifyAfter"
                    id="notifyAfter"
                    autocomplete="Off"
                    v-model="toStartTracking.after"
                    class="input w-16 text-center"
                    :disabled="!toStartTracking.active"
                  />
                  <span class="pl-1 text-sm font-medium">{{
                    $t(
                      'settings.notification.timeTracking.notifyAfters.unit',
                      toStartTracking.after
                    )
                  }}</span>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="notifyRepeat"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('settings.notification.timeTracking.notifyRepeat') }}</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="notifyRepeat"
                    id="notifyRepeat"
                    autocomplete="Off"
                    v-model="toStartTracking.repeatAfter"
                    :disabled="!toStartTracking.active"
                    class="input w-16 text-center"
                  />
                  <span class="pl-1 text-sm font-medium">{{
                    $t('settings.notification.timeTracking.notifyRepeats.unit')
                  }}</span>
                </div>
              </div>
              <div class="col-span-3 mb-2">
                <span class="block text-sm font-medium">{{
                  $t('settings.notification.timeTracking.notifyBetween')
                }}</span>
                <div class="mt-2 flex space-x-4">
                  <div>
                    <label for="notifyBetweenStart" class="block text-sm font-medium leading-6">{{
                      $t('calendar.settings.start')
                    }}</label>
                    <input
                      type="time"
                      step="60"
                      min="0"
                      :max="toStartTracking.between.end - 1"
                      class="input"
                      :disabled="!toStartTracking.active"
                      v-model="toStartTracking.between.start"
                    />
                  </div>
                  <div>
                    <label for="notifyBetweenEnd" class="block text-sm font-medium leading-6">{{
                      $t('calendar.settings.end')
                    }}</label>
                    <input
                      type="time"
                      step="60"
                      :min="toStartTracking.between.start + 1"
                      max="24"
                      class="input"
                      :disabled="!toStartTracking.active"
                      v-model="toStartTracking.between.end"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="py-4">
              <!-- TODO: Add support for couple with settings hideWeekDays -->
              <fieldset class="w-full space-y-4">
                <legend class="text-sm font-semibold">
                  {{ $t('settings.notification.timeTracking.onWeekDays') }}
                </legend>
                <div
                  class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-7 w-full"
                >
                  <template v-for="(item, n) in selectedWeekDays" :key="n">
                    <div class="relative flex gap-x-3">
                      <div class="flex h-6 items-center">
                        <input
                          :name="'notifyOnWeekdays[' + n + ']'"
                          :id="'notifyOnWeekdays[' + n + ']'"
                          :checked="item"
                          @change="updateWeekDays(n + 1)"
                          type="checkbox"
                          :disabled="!toStartTracking.active"
                          class="checkbox"
                        />
                      </div>
                      <div class="text-sm">
                        <label :for="'notifyOnWeekdays[' + n + ']'" class="font-medium">
                          {{ weekDays[n] }}</label
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </fieldset>
            </div>
          </div>
          <div
            class="flex items-center justify-end gap-x-6 border-t border-mossgray-300 px-4 py-4 sm:px-8"
          >
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <BaseButton type="submit" class="button-mossgray">{{ $t('save') }}</BaseButton>
          </div>
        </form>
      </div>
    </div>
  </TemplateComponent>
</template>

<script setup>
import { computed, ref } from 'vue'
import TemplateComponent from './TemplateComponent.vue'
import { Switch as BaseSwitch } from '@headlessui/vue'
import LoadingSpinner from '../LoadingSpinner.vue'
import { useI18n } from 'vue-i18n'
import datetime from '@/lib/datetime'
import BaseButton from '@/components/general/BaseButton.vue'
import apiService from '@/services/api.service'
import { useAuthUserStore } from '@/stores/auth-user'
import timelinkStoresService from '@/services/timelink-stores.service'
import { captureException } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
import { $t } from '@/config/i18n'
const { t } = useI18n()
const isLoading = ref(false)
const errors = ref([])

const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()

const weekDays = ref(datetime.getWeekDays())

const onTrackingStartAndStopOverPanel = ref({ active: true })

const remindActiveTracking = ref({ active: true, after: 60 })
const doBreak = ref({ active: true, time: '12:00' })
const toStartTracking = ref({
  active: true,
  after: 1,
  repeatAfter: 30,
  between: {
    start: '08:00',
    end: '17:00'
  },
  weekDays: [1, 2, 3, 4, 5]
})

function updateCompGeneral() {
  onTrackingStartAndStopOverPanel.value = authUserStore?.user?.settings?.notifications
    ?.onTrackingStartAndStopOverPanel ?? {
    active: true
  }
  remindActiveTracking.value = authUserStore?.user?.settings?.notifications
    ?.remindActiveTracking ?? {
    active: true,
    after: 60
  }
  doBreak.value = authUserStore?.user?.settings?.notifications?.doBreak ?? {
    active: true,
    time: '12:00'
  }
}

function updateComptoStartTracking() {
  toStartTracking.value = authUserStore?.user?.settings?.notifications?.toStartTracking ?? {
    active: true,
    after: 1,
    repeatAfter: 30,
    between: {
      start: '08:00',
      end: '17:00'
    },
    weekDays: [1, 2, 3, 4, 5]
  }
}

updateCompGeneral()
updateComptoStartTracking()

const selectedWeekDays = computed(() => {
  let arr = []
  for (let i = 1; i < 8; i++) {
    let j = toStartTracking.value.weekDays.includes(i)
    arr.push(j)
  }
  return arr
})

function updateWeekDays(n) {
  if (toStartTracking.value.weekDays.includes(n)) {
    toStartTracking.value.weekDays = toStartTracking.value.weekDays.filter((i) => i != n)
  } else {
    toStartTracking.value.weekDays.push(n)
  }
}

async function saveStartOfRecording() {
  isLoading.value = true
  try {
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      authUserStore.user.id,
      {
        settings: {
          ...(authUserStore.user?.settings ?? {}),
          notifications: {
            ...(authUserStore.user?.settings?.notifications ?? {}),
            toStartTracking: toStartTracking.value
          }
        }
      }
    )
    if (response.success) {
      authUserStore.internalUpdateUserData(response.data)
      alertsStore.successfullySaved()
      updateComptoStartTracking()
    } else {
      alertsStore.error($t('errors.save_returned_success_false'))
      console.error(response)
    }
  } catch (error) {
    if (error?.response?.status == 422) {
      apiService.convertValidationErrors(error.response.data)
    } else if (apiService.checkIfNotAuthenticated(error)) {
      return
    } else if (apiService.checkIfServerError(error)) {
      //
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }

  isLoading.value = false
}

async function saveGeneral() {
  isLoading.value = true
  try {
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      authUserStore.user.id,
      {
        settings: {
          ...(authUserStore.user?.settings ?? {}),

          notifications: {
            ...(authUserStore.user?.settings?.notifications ?? {}),
            doBreak: doBreak.value,
            remindActiveTracking: remindActiveTracking.value,
            onTrackingStartAndStopOverPanel: onTrackingStartAndStopOverPanel.value
          }
        }
      }
    )
    if (response.success) {
      authUserStore.internalUpdateUserData(response.data)
      alertsStore.successfullySaved()
      updateCompGeneral()
    } else {
      alertsStore.error($t('errors.save_returned_success_false'))
      console.error(response)
    }
  } catch (error) {
    if (error?.response?.status == 422) {
      apiService.convertValidationErrors(error.response.data)
    } else if (apiService.checkIfNotAuthenticated(error)) {
      return
    } else if (apiService.checkIfServerError(error)) {
      //
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
