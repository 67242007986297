<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal">
      <template v-slot:header> {{ $t('create_user') }} </template>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label
              for="first_name"
              class="block text-sm font-medium leading-6 text-gray-900 label-required"
              >{{ $t('first_name') }}</label
            >
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="text"
                name="first_name"
                id="first_name"
                autocomplete="first_name"
                v-model="user.first_name"
                class="input w-full"
                ref="first_name_input"
                required
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <label
              for="last_name"
              class="block text-sm font-medium leading-6 text-gray-900 label-required"
              >{{ $t('last_name') }}</label
            >
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="text"
                name="last_name"
                id="last_name"
                autocomplete="last_name"
                v-model="user.last_name"
                required
                class="input w-full"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900 label-required"
              >{{ $t('email') }}</label
            >
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="email"
                name="email"
                id="email"
                autocomplete="email"
                v-model="user.email"
                required
                class="input w-full"
              />
            </div>
          </div>

          <div class="sm:col-span-3 sm:col-end-8">
            <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('active')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="checkbox"
                name="active"
                id="active"
                autocomplete="Off"
                v-model="user.active"
                class="checkbox"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-gray-900 label-required"
              >{{ $t('password') }}</label
            >
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="password"
                name="password"
                id="password"
                autocomplete="new-password"
                v-model="user.password"
                class="input w-full"
                required
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <label
              for="password_confirmation"
              class="block text-sm font-medium leading-6 text-gray-900 label-required"
              >{{ $t('password_confirmation') }}</label
            >
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                autocomplete="new-password"
                v-model="user.password_confirmation"
                class="input w-full"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="w-full flex justify-end space-x-4 items-center">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel')
          }}</BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="save">
            {{ $t('create') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'
import { captureException, captureMessage } from '@sentry/vue'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { $t } from '@/config/i18n'

const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const route = useRoute()
const isLoading = ref(false)

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
const first_name_input = ref(null)

watch(
  () => showModal.value,
  async (newVal) => {
    if (newVal) {
      await nextTick()
      first_name_input.value?.focus()
    }
  }
)

function closeModal() {
  showModal.value = false
  user.value = {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
    password: null,
    password_confirmation: null
  }
}

// TODO: Add validation errors
const errors = ref({})

const user = ref({
  first_name: '',
  last_name: '',
  email: '',
  active: true,
  password: null,
  password_confirmation: null
})
const canEdit = ref(true)

//TODO: Update to work with edit + escape key

async function save() {
  if (companyStore.company.pull_provider || authUserStore.user.admin < 9) {
    //TODO: Add notification or message somewhere
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  isLoading.value = true
  try {
    let response = await apiService.create(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      user.value
    )
    if (response.success) {
      showModal.value = false
      alertsStore.success(
        $t('successfully.created_user', {
          firstName: user.value.first_name,
          lastName: user.value.last_name
        })
      )
      user.value = {
        first_name: '',
        last_name: '',
        email: '',
        active: true
      }
    } else {
      alertsStore.error($t('errors.ups_create'))
      captureMessage('Could not create user!')
      // TODO: Add handling
    }
  } catch (error) {
    console.log(error)
    if (
      error?.response?.status == 403 &&
      error?.response?.data?.message == 'You are not subscribed!'
    ) {
      // TODO: Add better error handling!
      alertsStore.error($t('errors.no_subscription'))
    } else if (apiService.checkIfServerError(error)) {
      //
    } else if (error?.response?.status == 422) {
      errors.value = apiService.convertValidationErrors(error)
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
