import apiService from '@/services/api.service'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAuthUserStore } from './auth-user'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} page
 */
export const useServicesStore = defineStore('services', {
  /**
   *
   * @returns {{
   * services: Array<{
   * id: string,
   * name: ?string,
   * info: ?string,
   * color: ?string,
   * image_id: ?string,
   * created_at: ?string,
   * updated_at: ?string,
   * tl: {
   * isDirty: boolean
   * origin: Object
   * }
   * }>,
   * last_fetch: number,
   * activeCount: number,
   * }
   * }
   */
  state: () => ({
    services: [],
    last_fetch: null,
    activeCount: 0
  }),
  persist: true,
  getters: {
    getId: (state) => {
      return (id) => {
        if (id == null || id == undefined) {
          return null
        }
        return state.services.find((item) => item.id === id)
      }
    },
    getLastUsed: (state) => {
      let last_used = useAuthUserStore().user?.last_used?.services
      if (last_used == undefined || last_used == null) {
        return []
      }
      return state.services.filter((item) => last_used.includes(item.id))
    },
    getDisplayName: (state) => {
      return (id) => {
        let entry = state.getId(id)
        if (!entry) {
          return ''
        }
        return entry.acronym ?? entry.name
      }
    }
  },
  actions: {
    initFetch() {
      if (this.services.length == 0) {
        apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/services',
          {
            limit: 50
          },
          (data) => {
            data.data.forEach((item) => this.addOrUpdate(item))
          },
          () => {
            this.$reset()
          }
        )
        // this.fetchAll()
        this.sortEntries()
      }
    },
    fetchAll() {
      this.services = []
      apiService.fetchAll(
        import.meta.env.VITE_API_URL + '/api/v1/services',
        {},
        (data) => {
          data.data.forEach((item) => this.addOrUpdate(item))
        },
        () => {
          this.$reset()
        }
      )
    },
    fetch(filter = {}, addSearch = false, addPanel = false, callback = null) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/services',
        filter,
        (data, response) => {
          data.data.forEach((item) => this.addOrUpdate(item, addSearch, addPanel))
          if (typeof callback == 'function') {
            callback(data, response)
          }
        },
        () => {
          return
        }
      )
    },
    fetchCount(filter = {}, callback = null) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/services',
        { ...filter, limit: 1 },
        (data, response) => {
          if (typeof callback == 'function') {
            callback(response.data.meta.total, response)
          }
        },
        () => {}
      )
    },
    fetchActiveCount(filter = {}, callback = null) {
      this.fetchCount({ ...filter, active: true }, (total, response) => {
        this.activeCount = total
        if (typeof callback == 'function') {
          callback(total, response)
        }
      })
    },
    fetchIds(ids = []) {
      ids.filter((item) => item != undefined && item != null)

      // create mapIds as a array which holding the ids as chunks
      let mapIds = []
      let ids_chunks = ids.length / 10
      for (let i = 0; i < ids_chunks; i++) {
        mapIds.push(ids.slice(i * 10, (i + 1) * 10))
      }
      // fetch all the clients
      mapIds.map((item) => {
        apiService.fetchAll(
          import.meta.env.VITE_API_URL + '/api/v1/services',
          { ids: item },
          (data) => {
            data.data.forEach((item) => this.addOrUpdate(item))
          },
          () => {}
        )
      })
    },
    fetchUpdates() {
      let ids = []
      if (ids.length > 200) {
        return
      }
      this.services.forEach((item) => {
        ids.push(item.id)
      })
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/services',
        {
          limit: 200,
          ids: ids
        },
        (data) => {
          let foundIds = []
          data.data.forEach((item) => {
            foundIds.push(item.id)
            this.addOrUpdate(item, true)
          })
          let notFound = ids.filter((item) => !foundIds.includes(item))
          this.services = this.services.filter((item) => !notFound.includes(item.id))
        }
      )
    },
    fetchIfNotExists(id) {
      if (this.getId(id) == null) {
        this.fetchIds([id])
      }
    },
    search(query, params = {}) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/services',
        {
          search: query,
          limit: 10,
          ...params
        },
        (data) => {
          data.data.forEach((item) => this.addOrUpdate(item, true))
        },
        () => {}
      )
    },
    addOrUpdate(entry, addSearch = false) {
      let o_entry = null
      if ((o_entry = this.services.find((item) => entry.id === item.id))) {
        o_entry.tl.origin = entry
        if (!o_entry.tl.isDirty) {
          if (entry.updated_at != o_entry.updated_at) {
            Object.entries(entry).forEach((item) => {
              o_entry[item[0]] = item[1]
            })
          }
        }
      } else {
        entry.tl = {
          origin: { ...entry },
          search: false,
          isDirty: false
        }
        if (addSearch) {
          entry.tl.search = true
        }
        this.services.push(entry)
      }
      this.sortEntries()
    },
    updateIfExists(entry) {
      let o_entry = null
      if ((o_entry = this.services.find((item) => entry.id === item.id))) {
        o_entry.tl.origin = entry
        if (!o_entry.tl.isDirty) {
          if (entry.updated_at != o_entry.updated_at) {
            Object.entries(entry).forEach((item) => {
              o_entry[item[0]] = item[1]
            })
          }
        }
        return true
      }
      return false
    },
    sortEntries() {
      let used_services = []
      let last_used = useAuthUserStore().user?.last_used?.services
      if (last_used == null || last_used == undefined) {
        last_used = []
      }
      last_used?.forEach((item) => {
        let found = this.getId(item)
        if (found) {
          used_services.push(found)
        }
      })
      this.services = this.services.filter((item) => !last_used.includes(item.id))
      this.services.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      this.services.unshift(...used_services)
    },
    removeId(id) {
      this.services = this.services.filter((item) => item.id != id)
    },

    removeSearch() {
      // removeSearch(foundIds) {
      return
      // this.services = this.services.filter(
      //   (item) =>
      //     (useAuthUserStore().user.last_used ?? { services: [] })['services'].includes(item.id) ||
      //     foundIds.includes(item.id) ||
      //     !(item.tl.search ?? false)
      // )
      // this.services.forEach((item) => {
      //   item.tl.search = false
      // })
    },
    // removeOld(foundIds = []) {
    removeOld() {
      return
      // this.services = this.services.filter(
      //   (item) =>
      //     foundIds.includes(item.id) ||
      //     (useAuthUserStore().user.last_used ?? { services: [] })['services'].includes(item.id)
      // )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useServicesStore, import.meta.hot))
}
